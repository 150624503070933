import axios from 'axios'
import moment from 'moment'
import {flatten} from 'lodash'
import {computeSize} from '@helpers/openlayers/computation'
import {multiLineString} from '@turf/helpers'
import {parse, stringify} from 'wellknown'

import {smarterGet, smarterPost} from '@helpers/vuex/data-loading'
import {format} from 'src/js/i18n/conversion'
import {notifications} from 'src/js/infrastructure'
import {makeFeature, toWkt, splitToPolygons} from 'src/js/helpers/openlayers/features'
import {defaults as fieldPartDefaults} from '../part-edit-buffer'
import {createPlanName} from '../helpers'

export * from './algorithm'

export function loadPrerequisiteDataForPlanning ({dispatch, commit}) {
  return Promise.all([
    dispatch('fieldRecordSystem/loadFieldGeometriesForCurrentOrgUnit', null, {root: true}),
    dispatch('fieldRecordSystem/machines/loadMachines', null, {root: true}),
    commit('resetLanePlansSelection')
  ])
}

export async function loadPrerequisiteDataForOverview ({dispatch}) {
  await Promise.all([
    dispatch('fieldRecordSystem/loadFieldGeometriesForCurrentOrgUnit', null, {root: true}),
    dispatch('getLanePlansForOrgUnit')
  ])
}

export function startPlanning ({rootState, state, commit, rootGetters, dispatch}, savedPlan) {
  return dispatch('loadPrerequisiteDataForPlanning')
    .then(() => {
      // transform savedPlan into correct form for edit buffers
      if (savedPlan) {
        const {settings: {turnRadius: turningCircle, gap, workingWidth}} = savedPlan

        const fieldParts = savedPlan.partialFields.map(partialField => {
          const {geometry: wkt, lane: {settings: {offset}}} = partialField
          const fieldPartTemplate = {
            ...fieldPartDefaults(),
            wkt,
            offset
          }
          if (partialField.headland) {
            fieldPartTemplate.depth = partialField.headland.settings.depth
            fieldPartTemplate.headlandLanes = partialField.headland.settings.headlandLanes
          }
          return fieldPartTemplate
        })

        const templatePlan = {
          machine: savedPlan.vehicle ? rootState.fieldRecordSystem.machines.data.machines.find(x => x.id === savedPlan.vehicle.id) : null,
          turningCircle,
          gap,
          workingWidth,
          fieldParts,
          wkt: savedPlan.fieldGeometry
        }

        commit('laneEditBuffer/reset', templatePlan)
        commit('partEditBuffer/reset')
      } else {
        commit('laneEditBuffer/reset')

        const fieldWkt = rootGetters['fieldRecordSystem/fieldWkt']

        return dispatch('setFieldParts', [fieldWkt])
      }
    })
}

// field was manually segmented into new partial fields
export function setFieldParts ({commit, dispatch}, wkts) {
  // safeguard against multipolygon geometries
  const features = wkts.map(wkt => makeFeature({wkt}))
  wkts = splitToPolygons(features).map(toWkt)

  commit('laneEditBuffer/set', {
    // any previous results are discarded
    fieldParts: wkts.map(wkt => ({...fieldPartDefaults(), wkt})),
    selectedPartIndex: 0
  })
  commit('partEditBuffer/reset')
}

// can restart wizard for an already completed part, keeping previous settings
export function startPlanningPart ({state, commit, dispatch}) {
  if (state.partEditBuffer.wkt) {
    throw new Error('cannot start planning a part if another part is currently in progress')
  }

  const {selectedPartIndex, fieldParts} = state.laneEditBuffer

  commit('partEditBuffer/set', fieldParts[selectedPartIndex])

  return dispatch('getSegmentation')
}

export async function finishPart ({state, commit, dispatch, getters}, skip = false) {
  const fieldParts = [...state.laneEditBuffer.fieldParts]
  fieldParts.splice(state.laneEditBuffer.selectedPartIndex, 1, {...state.partEditBuffer, skip})

  const nextPart = fieldParts.find(x => !x.skip && (getters.isPlanningProfileAB ? !x.abSegments.length : !x.candidateLanes.length))

  commit('laneEditBuffer/set', {
    fieldParts
  })

  if (nextPart) {
    commit('laneEditBuffer/set', {
      selectedPartIndex: nextPart ? fieldParts.indexOf(nextPart) : null
    })
    commit('partEditBuffer/reset')

    return dispatch('startPlanningPart')
  } else {
    return dispatch('finishPlanning')
  }
}

export function cancelPart ({state, dispatch}) {
  // currently it is not possible to just cancel one partial field, all planned parts are cancelled at once to return to the common settings page
  return dispatch('setFieldParts', state.laneEditBuffer.fieldParts.map(x => x.wkt))
}

export async function finishPlanning ({commit, dispatch, getters, rootGetters}) {
  if (!getters.isPlanningProfileAB) {
    const fieldId = rootGetters['fieldRecordSystem/fieldId']
    return dispatch('getLanePlanNamesForField', fieldId)
    .then(() => {
      dispatch('saveLanePlan')
    })
    .then(() => {
      commit('laneEditBuffer/set', {finished: true})
    })
  } else {
    return dispatch('saveLanePlanAb')
    .then(() => {
      commit('laneEditBuffer/set', {finished: true})
    })
  }
}

export function stopPlanning ({commit}) {
  commit('laneEditBuffer/reset')
  commit('partEditBuffer/reset')
  commit('fieldRecordSystem/setRightView', 'default', {root: true})
}

export function startEditingFieldGeometry ({state, commit, dispatch}, {features}) {
  return dispatch('fieldRecordSystem/map/startExpertMode', features, {root: true}).then(() => {
    commit('fieldRecordSystem/map/setMode', 'cut', {root: true})
  })
}

export function commitEditedFieldGeometry ({dispatch}) {
  return dispatch('fieldRecordSystem/map/stopEditing', null, {root: true})
    .then(features => features.map(toWkt))
    .then(wkts => dispatch('setFieldParts', wkts))
}

export function undo () {
  notifications.info('lanePlanning undo not yet implemented')
}

export function saveLanePlanAb ({state, commit, rootState, rootGetters}) {
  const laneEditBuffer = state.laneEditBuffer
  const userSettings = rootState.fieldRecordSystem.userSettings
  const entityId = rootGetters['fieldRecordSystem/fieldId']
  const fieldGeometry = rootGetters['fieldRecordSystem/fieldWkt']

  const generatedAt = moment().toISOString()
  let plans = []

  for (const fieldPart of laneEditBuffer.fieldParts.filter(x => !x.skip)) {
    let segmentGeometries = fieldPart.segmentationResult.reduce((a, b) => a.concat(b))
    segmentGeometries = segmentGeometries.map(s => parse(s.lineString))
    segmentGeometries = segmentGeometries.map(geometry => geometry.coordinates)

    const plan = fieldPart.abSegments.map(segment => ({
      Id: null,
      entityId,
      harvestYear: userSettings.harvestYear,
      fieldGeometry,
      generatedAt,

      partialFieldId: null,
      fieldPartGeometry: fieldPart.wkt,
      segmentGeometries: stringify(multiLineString(segmentGeometries)),

      abLineId: null,
      length: computeSize(segment.geometry),
      type: segment.properties.lineType === 'line' ? 'AbStraight' : 'AbContour',
      abLine: segment.properties.segmentData.lineString,
      name: segment.properties.name
    }))
    plans.push(plan)
  }
  plans = plans.reduce((acc, cur) => acc.concat(cur))
  return smarterPost('/api/LanePlanning/SaveLanePlanAb', plans, {
    id: 'geotrax.savePlanAb'
  })
  .then(plans => {
    commit('addLanePlansOrgUnit', plans)
    state.data.selectedLanePlans = plans.map(x => x.id)
  })
}

export function saveLanePlan ({state, commit, rootState, rootGetters}) {
  const laneEditBuffer = state.laneEditBuffer
  const userSettings = rootState.fieldRecordSystem.userSettings
  const entityId = rootGetters['fieldRecordSystem/fieldId']
  const fieldGeometry = rootGetters['fieldRecordSystem/fieldWkt']
  const field = rootGetters['fieldRecordSystem/navigation/entityLookup'][entityId]
  const lanePlanNamesField = state.data.lanePlanNamesForField

  const fieldName = field.name + '_'
  const workingWidthName = laneEditBuffer.workingWidth ? format(laneEditBuffer.workingWidth, 'f2') + 'm_' : ''
  const laneTypeName = 'Lines'

  const name = createPlanName(lanePlanNamesField, fieldName + workingWidthName + laneTypeName)

  const plan = {
    id: null,
    entityId,

    vehicle: laneEditBuffer.machineId ? {id: laneEditBuffer.machineId} : null,

    harvestYear: userSettings.harvestYear,
    fieldGeometry,
    generatedAt: moment().toISOString(),
    name,

    settings: {
      planId: null,
      workingWidth: laneEditBuffer.workingWidth,
      gap: laneEditBuffer.gap,
      turnRadius: laneEditBuffer.turningCircle,
      targetSystem: null // TODO targetSystem does not exist anymore change backend to planning profile
    },

    partialFields: []
  }

  for (const fieldPart of laneEditBuffer.fieldParts.filter(x => !x.skip)) {
    let headland = null

    if (fieldPart.headlands.length > 0) {
      headland = {
        partialFieldId: null,
        geometries: fieldPart.headlands,
        laneGeometry: fieldPart.generatedHeadlandLane,

        settings: {
          headlandId: null,
          depth: fieldPart.depth,
          workingWidth: laneEditBuffer.workingWidth,
          gap: laneEditBuffer.gap,
          turnRadius: laneEditBuffer.turningCircle,
          minLaneLength: laneEditBuffer.turningCircle * 2, // atm unused: laneEditBuffer.minLaneLength,
          maxExtensionIntoField: fieldPart.depth * 2, // atm unused: laneEditBuffer.maxIntoField,
          maxLanes: null
        }
      }
    }

    const suggestion = fieldPart.candidateLanes[fieldPart.selectedLane]
    const lane = {
      partialFieldId: null,
      lineGeometries: suggestion.lines,
      length: suggestion.totalDistance,
      turnsPerDistance: suggestion.turnsPerDistance,
      type: suggestion.type,
      // todo: atm warnings is just an int, but it'll be a MultiLineString or LineString[] suggestion.warnings,
      warnings: 'MULTILINESTRING EMPTY',
      // todo: algorithm has to deliver the segment
      segment: fieldPart.segmentationResult[suggestion.ringIndex][suggestion.segmentIndex].lineString,

      settings: {
        laneId: null,
        minLaneLength: laneEditBuffer.turningCircle * 2, // atm unused: laneEditBuffer.minLaneLength,
        maxLanes: null, // atm unused: laneEditBuffer.maxLanes,
        offset: suggestion.offset
      }
    }

    const part = {
      id: null,
      planId: null,
      geometry: fieldPart.wkt,
      segmentGeometries: flatten(fieldPart.segmentationResult).map(s => s.lineString),
      headland,
      lane
    }

    plan.partialFields.push(part)
  }

  return smarterPost('/api/LanePlanning/SaveLanePlan', plan, {
    id: 'geotrax.savePlan'
  })
  .then(plans => {
    commit('addLanePlansOrgUnit', [plans])
    state.data.selectedLanePlans = [plans.id]
  })
}

export async function getDataForStepLaneSelection ({state, dispatch, rootGetters, commit}) {
  const isAB = rootGetters['fieldRecordSystem/lanePlanning/isPlanningProfileAB']

  await dispatch('getLanePlansForField') // TODO find out why this is called

  if (isAB) {
    commit('partEditBuffer/setSubStep', 'preview')
  } else {
    return dispatch('getABLines')
  }
}

export function getLanePlanNamesForField ({rootGetters, commit}, fieldId) {
  return smarterGet('/api/LanePlanning/{fieldId}/LanePlanNames', {
    id: 'geotrax.getLanePlanNamesForField',
    expiry: 0,
    inputs: {
      fieldId: () => fieldId
    },
    onResult (lanePlanNames) {
      commit('setLanePlanNamesForField', lanePlanNames)
    }
  })
}

export function getLanePlansForField ({rootGetters, commit}) {
  return smarterGet('/api/LanePlanning/{fieldId}/LanePlans', {
    id: 'geotrax.getLanePlansForField',
    expiry: 5,
    inputs: {
      fieldId: () => rootGetters['fieldRecordSystem/fieldId']
    },
    onResult (lanePlans) {
      commit('setLanePlansForField', lanePlans)
    }
  })
}

export async function getLanePlansForOrgUnit ({rootGetters, commit}) {
  return smarterGet([
    '/api/LanePlanning/{orgUnitId}/LanePlansByOrgUnit',
    '/api/LanePlanning/{orgUnitId}/AbLanePlansByOrgUnit'
  ], {
    id: 'geotrax.LanePlansByOrgUnit',
    expiry: 5,
    inputs: {
      orgUnitId: () => rootGetters['fieldRecordSystem/orgUnitId']
    },
    onResult ([lanePlans, abLanePlans]) {
      commit('setLanePlansOrgUnit', lanePlans)
      commit('addLanePlansOrgUnit', abLanePlans)
    }
  })
}

// wip
export function download ({rootState, state, rootGetters}) {
  const lanePlanIds = state.data.selectedLanePlans.filter(x => x)
  const data = {
    fileName: generateFileName(rootState, state, rootGetters, lanePlanIds),
    planIds: lanePlanIds,
    harvestYear: rootState.fieldRecordSystem.userSettings.harvestYear,
    includeBorder: state.laneEditBuffer.isBorderSelected,
    includeHeadland: state.laneEditBuffer.isHeadlandSelected,
    includeHeadlandLanes: state.laneEditBuffer.isHeadlandLanesSelected,
    includeInfieldLanes: state.laneEditBuffer.includeInfieldLanes,
    includeBorderAsCurve: state.laneEditBuffer.includeBorderAsCurve
  }

  return smarterPost(`/api/DataTransfer/Lane/Prepare/${state.ui.selection.format}`, data, {
    id: 'geotrax.download'
  })
  .then(response => {
    window.location.href = window.location.href.replace(window.location.pathname, `/api/DataTransfer/Lane/Download/${response}`)
  })
}

function generateFileName (rootState, state, rootGetters, planIds) {
  let fileName = ''

  const orgUnitName = rootGetters['fieldRecordSystem/orgUnitName']
  const fieldName = rootGetters['fieldRecordSystem/fieldName']
  const entityLookup = rootGetters['fieldRecordSystem/entityNameLookup']

  if (Object.keys(state.data.lanePlansOrgUnit).length) {
    const plans = Object.values(state.data.lanePlansOrgUnit).reduce((acc, cur) => acc.concat(cur))
    const lanePlans = planIds.map(id => plans.filter(plan => plan.id === id)).reduce((acc, cur) => acc.concat(cur))
    const date = lanePlans.length > 1
      ? moment.utc().add(1, 'hours').format('YYYY-MM-DD_HH-mm-ss')
      : moment.utc(lanePlans[0].generatedAt).format('YYYY-MM-DD_HH-mm-ss')

    if (lanePlans.length > 1) {
      fileName = date + '_' + rootState.i18n.translations['Areas.LanePlanning.SR_LanePlanning']['MultipleExportName']
    } else {
      let formerName = entityLookup[lanePlans[0].entityId] + '_'
      if (lanePlans[0].settings !== undefined) {
        let workingWidth = lanePlans[0].settings.workingWidth !== undefined ? lanePlans[0].settings.workingWidth : 'Lines'
        workingWidth = format(workingWidth, 'f2')
        if (workingWidth !== 'Lines') {
          workingWidth = workingWidth.replace('.', ',')
          workingWidth = workingWidth.concat('m_Lines')
        }
        formerName = formerName.concat(workingWidth)
      } else {
        let abType = lanePlans[0].type !== undefined ? lanePlans[0].type.name : 'AB'
        formerName = formerName.concat(abType)
      }

      fileName = formerName === lanePlans[0].name ? date + '_' + orgUnitName + '_' + lanePlans[0].name : date + '_' + lanePlans[0].name
    }
  } else {
    const date = moment().format('YYYY-MM-DD_HH-mm-ss')
    let workingWidth = state.laneEditBuffer.workingWidth !== undefined ? state.laneEditBuffer.workingWidth : 'Lines'
    workingWidth = format(workingWidth, 'f2')
    if (workingWidth !== 'Lines') {
      workingWidth = workingWidth.replace('.', ',').concat('m_Lines')
    }
    fileName = date + '_' + orgUnitName + '_' + fieldName + '_' + workingWidth
  }

  return fileName
}

export function resetLanePlansSelection ({dispatch, commit}) {
  dispatch('stopPlanning')
  .then(commit('resetLanePlansSelection'))
}
export function deleteLanePlans ({state, commit, dispatch}) {
  const selectedLanePlans = state.data.selectedLanePlans

  return Promise.all(
    selectedLanePlans.map(planId => axios.delete(`/api/LanePlanning/${planId}`))
  )
  .then(() => {
    commit('resetLanePlansSelection')
    dispatch('fieldRecordSystem/lanePlanning/getLanePlansForOrgUnit', null, {root: true})
  })
}

export function updateLanePlanName ({state, commit}, {entityId, planId, name}) {
  const previousName = state.data.lanePlansOrgUnit[entityId].find(plan => plan.id === planId).name
  commit('updateLanePlanName', {entityId, planId, name})
  return axios.put(`/api/LanePlanning/UpdateName/${planId}/${name}`)
  .catch(error => {
    commit('updateLanePlanName', {entityId, planId, name: previousName})
    throw error
  })
}
