import {postProcessingUiParameterDefaults} from './index'

export function setApplicationMapsForPreview (state, maps) {
  state.data = maps
}

export function adjustZoneValueManually (state, {value, zoneId}) {
  state.ui.manuallyAdjustedZoneValues[zoneId] = value
}

export function resetFieldId (state) {
  state.selectedFieldId = null
}

export function resetUi (state) {
  state.ui = postProcessingUiParameterDefaults()
}

export function setPreviousFertilization (state, value) {
  state.ui.previousFertilization = value
}

export function removeMapFromArray (state) {
  const applicationMapId = state.ui.applicationMap.id
  const index = state.data.applicationMaps.map(map => map.id).indexOf(applicationMapId)
  if (index >= 0) {
    state.data.applicationMaps.splice(index, 1)
  }
}

export function removeMapFromLookup (state) {
  const applicationMapId = state.ui.applicationMap.id
  const mapIdsByFieldId = state.data.applicationMapIdsByFieldId
  const fieldIds = Object.keys(mapIdsByFieldId)

  fieldIds.forEach(fieldId => {
    const index = mapIdsByFieldId[fieldId].indexOf(applicationMapId)

    if (index >= 0) {
      mapIdsByFieldId[fieldId].splice(index, 1)
    }
  })
}

export function resetInputs (state) {
  state.ui.productId = null
  state.ui.previousFertilization = null
  state.ui.isApplicationValue = false
  state.ui.applicationMap.zones.forEach(zone => {
    state.ui.manuallyAdjustedZoneValues[zone.id] = zone.value
  })
}
