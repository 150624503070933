import {keyBy} from 'lodash'

// cultivation getters
export function cultivationData (state) {
  if (!state.cultivations.length) return []
  return state.cultivations.map(cultivation => cultivation.properties)
}

export function cultivationDataLookup (state, getters) {
  if (!getters.cultivationData.length) return {}
  return keyBy(getters.cultivationData, 'id')
}

export function cultivationFeatureLookup (state) {
  if (!state.cultivations.length) return {}
  return keyBy(state.cultivations, 'properties.id')
}

export function visibleCultivationFeatures (state) {
  return state.cultivations.filter(feature => state.visible.includes(feature.properties.id))
}

export function visibleCultivationData (state) {
  if (!state.cultivations.length) return []
  return state.cultivations.filter(feature => state.visible.includes(feature.properties.id)).map(cultivation => cultivation.properties)
}

export function selectedCultivationData (state, getters) {
  if (!state.selected.length) return getters.cultivationData
  return state.selected.map(cultivationId => getters.cultivationDataLookup[cultivationId])
}
// field getters
export function fieldDataLookup (state) {
  if (!state.fields.length) return {}

  const properties = state.fields.map(field => field.properties)
  return keyBy(properties, 'id')
}

export function fieldFeatureLookup (state) {
  if (!state.fields.length) return {}
  return keyBy(state.fields, 'properties.id')
}

export function selectedFieldFeatures (state, getters) {
  if (!state.fields.length) return []
  return getters.selectedFieldIds.map(id => getters.fieldFeatureLookup[id])
}

export function selectedFieldIds (state, getters) {
  return state.selected.map(cultivationId => getters.cultivationDataLookup[cultivationId])
    .map(cultivation => cultivation.fieldIds)
    .reduce((acc, curr) => acc.concat(curr), [])
}
