const classificationsPh = [
  {
    soilTypes: ['S'],
    ranges: {
      A: {min: 0.1, max: 4.2},
      B: {min: 4.3, max: 4.7},
      C: {min: 4.8, max: 5.1},
      D: {min: 5.2, max: 5.4},
      E: {min: 5.5, max: 6.2},
      F: {min: 6.3, max: 9999}
    }
  },
  {
    soilTypes: ['lS', 'sU'],
    ranges: {
      A: {min: 0.1, max: 4.3},
      B: {min: 4.4, max: 4.8},
      C: {min: 4.9, max: 5.3},
      D: {min: 5.4, max: 5.7},
      E: {min: 5.8, max: 6.5},
      F: {min: 6.6, max: 9999}
    }
  },
  {
    soilTypes: ['ssL', 'lU'],
    ranges: {
      A: {min: 0.1, max: 4.5},
      B: {min: 4.6, max: 5.0},
      C: {min: 5.1, max: 5.5},
      D: {min: 5.6, max: 6.0},
      E: {min: 6.1, max: 6.8},
      F: {min: 6.9, max: 9999}
    }
  },
  {
    soilTypes: ['sL', 'uL', 'L'],
    ranges: {
      A: {min: 0.1, max: 4.6},
      B: {min: 4.7, max: 5.2},
      C: {min: 5.3, max: 5.7},
      D: {min: 5.8, max: 6.2},
      E: {min: 6.3, max: 7.1},
      F: {min: 7.2, max: 9999}
    }
  },
  {
    soilTypes: ['utL', 'tL', 'T'],
    ranges: {
      A: {min: 0.1, max: 4.7},
      B: {min: 4.8, max: 5.3},
      C: {min: 5.4, max: 5.8},
      D: {min: 5.9, max: 6.3},
      E: {min: 6.4, max: 7.2},
      F: {min: 7.3, max: 9999}
    }
  }
]

const classificationsPKMg = {
  phosphorus: {
    arable: [
      {
        soilTypes: ['S', 'lS', 'sU', 'ssL', 'lU', 'sL', 'uL', 'L'],
        ranges: {
          A: {min: 0, max: 3},
          B: {min: 4, max: 9},
          C: {min: 10, max: 18},
          D: {min: 19, max: 32},
          E: {min: 33, max: 9999}
        }
      },
      {
        soilTypes: ['utL', 'tL', 'T', 'flat S'],
        ranges: {
          A: {min: 0, max: 5},
          B: {min: 6, max: 13},
          C: {min: 14, max: 24},
          D: {min: 25, max: 38},
          E: {min: 39, max: 9999}
        }
      }
    ],
    grass: [
      {
        soilTypes: ['S', 'lS', 'sU', 'ssL', 'lU', 'sL', 'uL', 'L'],
        ranges: {
          A: {min: 0, max: 3},
          B: {min: 4, max: 9},
          C: {min: 10, max: 18},
          D: {min: 19, max: 32},
          E: {min: 33, max: 9999}
        }
      },
      {
        soilTypes: ['utL', 'tL', 'T', 'flat S'],
        ranges: {
          A: {min: 0, max: 5},
          B: {min: 6, max: 13},
          C: {min: 14, max: 24},
          D: {min: 25, max: 38},
          E: {min: 39, max: 9999}
        }
      }
    ]
  },
  potassium: {
    arable: [
      {
        soilTypes: ['S'],
        ranges: {
          A: {min: 0, max: 2},
          B: {min: 3, max: 5},
          C: {min: 6, max: 12},
          D: {min: 13, max: 19},
          E: {min: 20, max: 9999}
        }
      },
      {
        soilTypes: ['lS', 'sU', 'ssL', 'lU', 'sL', 'uL', 'L'],
        ranges: {
          A: {min: 0, max: 3},
          B: {min: 4, max: 9},
          C: {min: 10, max: 18},
          D: {min: 19, max: 32},
          E: {min: 33, max: 9999}
        }
      },
      {
        soilTypes: ['utL', 'tL', 'T'],
        ranges: {
          A: {min: 0, max: 5},
          B: {min: 6, max: 13},
          C: {min: 14, max: 24},
          D: {min: 25, max: 38},
          E: {min: 39, max: 9999}
        }
      }
    ],
    grass: [
      {
        soilTypes: ['S'],
        ranges: {
          A: {min: 0, max: 2},
          B: {min: 3, max: 5},
          C: {min: 6, max: 12},
          D: {min: 13, max: 19},
          E: {min: 20, max: 9999}
        }
      },
      {
        soilTypes: ['lS', 'sU', 'ssL', 'lU', 'sL', 'uL', 'L'],
        ranges: {
          A: {min: 0, max: 3},
          B: {min: 4, max: 9},
          C: {min: 10, max: 18},
          D: {min: 19, max: 32},
          E: {min: 33, max: 9999}
        }
      },
      {
        soilTypes: ['utL', 'tL', 'T'],
        ranges: {
          A: {min: 0, max: 5},
          B: {min: 6, max: 13},
          C: {min: 14, max: 24},
          D: {min: 25, max: 38},
          E: {min: 39, max: 9999}
        }
      }
    ]
  },
  magnesium: {
    arable: [
      {
        soilTypes: ['S', 'lS', 'sU'],
        ranges: {
          A: {min: 0, max: 1},
          B: {min: 2, max: 2},
          C: {min: 3, max: 4},
          D: {min: 5, max: 7},
          E: {min: 8, max: 9999}
        }
      },
      {
        soilTypes: ['ssL', 'lU', 'sL', 'uL', 'L'],
        ranges: {
          A: {min: 0, max: 2},
          B: {min: 3, max: 3},
          C: {min: 4, max: 6},
          D: {min: 7, max: 10},
          E: {min: 11, max: 9999}
        }
      },
      {
        soilTypes: ['utL', 'tL', 'T'],
        ranges: {
          A: {min: 0, max: 3},
          B: {min: 4, max: 5},
          C: {min: 6, max: 9},
          D: {min: 10, max: 14},
          E: {min: 15, max: 9999}
        }
      }
    ],
    grass: [
      {
        soilTypes: ['S', 'lS', 'sU', 'ssL', 'lU', 'sL', 'uL', 'L', 'utL', 'tL', 'T', 'flat S'],
        ranges: {
          A: {min: 0, max: 3},
          B: {min: 4, max: 7},
          C: {min: 8, max: 12},
          D: {min: 13, max: 18},
          E: {min: 19, max: 9999}
        }
      }
    ]
  }
}

const validCalculationSubTypes = ['S', 'lS', 'sU', 'ssL', 'lU', 'sL', 'uL', 'L', 'utL', 'tL', 'T', 'flat S']

export const isCalculationPossible = soilSubTypeId => {
  return validCalculationSubTypes.includes(soilSubTypeMap(soilSubTypeId))
}

const soilSubTypeMap = (soilSubType) => {
  // TODO add correct mapping
  // Sand
  if (soilSubType === 'Ss') return 'S'
  if (soilSubType === 'Su2') return 'S'
  if (soilSubType === 'Sl2') return 'lS'
  if (soilSubType === "l'S") return 'lS'
  if (soilSubType === 'Sl3') return 'lS'
  if (soilSubType === 'St2') return 'lS'
  if (soilSubType === 'Su4') return 'lS'
  if (soilSubType === 'Slu') return 'ssL'
  if (soilSubType === 'Sl4') return 'ssL'
  if (soilSubType === 'llS') return 'ssL'
  if (soilSubType === 'St3') return 'sL'

  // Lehm
  if (soilSubType === 'Ls2') return 'sL'
  if (soilSubType === 'Ls3') return 'sL'
  if (soilSubType === 'Ls4') return 'sL'
  if (soilSubType === 'ssL') return 'sL'
  if (soilSubType === 'Lts') return 'sL'
  if (soilSubType === 'Lu') return 'sL'
  if (soilSubType === 'Lt3') return 'utL'

  // Ton
  if (soilSubType === 'Tt') return 'T'
  if (soilSubType === 'Tl') return 'utL'
  if (soilSubType === 'Tu3') return 'utL'
  if (soilSubType === 'Tu4') return 'utL'
  if (soilSubType === 'uuT') return 'utL'
  if (soilSubType === 'Tu2') return 'utL'
  if (soilSubType === 'Ts2') return 'utL'
  if (soilSubType === 'Ts3') return 'utL'
  if (soilSubType === 'Ts4') return 'sL'

  // Schluff
  if (soilSubType === 'U') return 'lS'
  if (soilSubType === 'UU') return 'lS'
  if (soilSubType === 'sU') return 'lS'
  if (soilSubType === 'Us') return 'lS'
  if (soilSubType === 'Ut2') return 'ssL'
  if (soilSubType === 'Ut3') return 'sL'
  if (soilSubType === 'Uls') return 'ssL'
  if (soilSubType === 'Ut4') return 'sL'
  if (soilSubType === 'ttU') return 'sL'

  return soilSubType
}

const bySoilType = soilSubTypeId => {
  const mappedSoilSubType = soilSubTypeMap(soilSubTypeId)

  return classification => classification.soilTypes.some(x => x === mappedSoilSubType)
}

const isWithinRange = (ranges, key, value) => ranges[key].min <= value && ranges[key].max >= value

const computeClassificationPKMg = ({nutrient, usage, soilSubTypeId, measurement}) => {
  if (!measurement || !usage || !soilSubTypeId) {
    return null
  }

  if (!classificationsPKMg[nutrient][usage]) return null

  const {ranges} = classificationsPKMg[nutrient][usage].find(bySoilType(soilSubTypeId))
  return Object.keys(ranges).find(x => isWithinRange(ranges, x, Math.round(measurement))) // TODO find out why rounded
}

const computeClassificationPh = ({measurement, soilSubTypeId}) => {
  if (!measurement || !soilSubTypeId) {
    return null
  }
  const {ranges} = classificationsPh.find(bySoilType(soilSubTypeId))
  return Object.keys(ranges).find(x => isWithinRange(ranges, x, Math.round(measurement * 10) / 10)) // TODO find out why rounded
}

export {
  computeClassificationPKMg,
  computeClassificationPh
}
