import {parse} from 'wellknown'
import moment from 'moment'

export function csv (state) {
  if (!state.selectedOrder) {
    return null
  }

  const order = state.selectedOrder

  const header = 'NUMBER,NAME,LONGITUDE,LATITUDE'

  const rows = order.geometries.map(({geometry, sampleId}, i) => {
    const [longitude, latitude] = parse(geometry).coordinates

    return `${i + 1},${sampleId},${longitude},${latitude}`
  })

  const name = `${order.name.replace(/\s/g, '_') || 'SamplingOrder'}_${moment(order.created).local().format('YYYY-MM-DD_HH-mm')}`

  return {name, body: [header].concat(rows).join('\n')}
}
