export function fromFields (fields, fieldDataLookup) {
  return fields
    .reduce((features, field) => {
      const data = fieldDataLookup[field.id]

      if (!data || !data.geometry) return features

      const {geometry} = data

      features.push({
        type: 'Feature',
        properties: {
          id: field.id,
          name: field.name
        },
        geometry
      })

      return features
    }, [])
}
