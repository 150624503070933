import {makeSetters} from '@helpers/vuex/mutations'

import * as getters from './getters'
import * as actions from './actions'
import * as mutations from './mutations'

export const postProcessingUiParameterDefaults = () => ({
  productId: null,
  previousFertilization: null,
  isApplicationValue: false,
  applicationMap: null,
  manuallyAdjustedZoneValues: {}
})

const postProcessingParameterDefaults = () => ({
  selectedFieldId: null,
  data: {
    applicationMaps: [],
    applicationMapIdsByFieldId: {}
  },
  ui: postProcessingUiParameterDefaults()
})

export default {
  namespaced: true,
  state: postProcessingParameterDefaults(),
  getters,
  actions,
  mutations: {
    ...makeSetters([
      'selectedFieldId',
      'ui.productId',
      'ui.previousFertilization',
      'ui.selectedApplicationMapId',
      'ui.isApplicationValue',
      'ui.applicationMap',
      'ui.manuallyAdjustedZoneValues'
    ]),
    ...mutations
  }
}
