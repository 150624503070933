import LineString from 'ol/geom/LineString'
import proj4 from 'proj4'
import distance from '@turf/distance'

const threshold = 20

export function processProbingGeometry (feature) {
  const geometry = feature.getGeometry()
  const type = geometry.getType()

  if (type === 'Point') return feature

  if (type !== 'LineString') throw new Error(`invalid geometry type '${type}', only 'Point' and 'LineString' are supported`)

  const coordinates = feature.getGeometry().getCoordinates()

  const filledCoordinates = []

  coordinates.forEach((coordinate, i) => {
    if (i !== 0) {
      const previousCoordinate = coordinates[i - 1]
      const [x1, y1] = proj4('EPSG:3857', 'EPSG:4326', previousCoordinate)
      const [x2, y2] = proj4('EPSG:3857', 'EPSG:4326', coordinate)
      const d = distance([x1, y1], [x2, y2]) * 1000

      if (d > threshold) {
        const line = new LineString([previousCoordinate, coordinate])
        const num = Math.floor(d / threshold)

        filledCoordinates.push(...new Array(num).fill(null).map((x, i) => line.getCoordinateAt((i + 1) / (num + 1))))
      }
    }

    filledCoordinates.push(coordinate)
  })

  feature.getGeometry().setCoordinates(filledCoordinates)

  return feature
}
