import editBuffer from './edit-buffer'
import {makeSetters} from '@helpers/vuex/mutations'

import * as mutations from './mutations'
import * as actions from './actions'
import wizardActions from './wizard-actions'

const state = {
  data: {
    field: {},
    editing: {
      harvestActions: [],
      crops: [],
      catchCrops: [],
      previousCrops: []
    }
  },
  ui: {
    wizardStep: 'stepNitrogen'
  }
}

export default {
  namespaced: true,
  state,
  mutations: {
    ...mutations,
    ...makeSetters([
      'ui.wizardStep'
    ])
  },
  actions: {
    ...actions,
    ...wizardActions
  },
  modules: {
    editBuffer
  }
}
