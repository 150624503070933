export const actionHierarchy = {
  seeding: {
    broad: {
      steps: ['seedingBroadDrillSingle'],
      defaults: () => ({
        plantsPerSqm: 0,
        emergence: 0, // TODO proper name
        coating: null,
        // grain
        thousandSeedWeight: 0, // [g]
        seedsPerSqm: 0,
        germinationCapacity: 0,
        // corn
        rowSeparation: 0, // [cm]
        rowDistance: 0, // [cm]
        // seedProduct: null,
        mixtureComponents: []
      })
    },
    drill: 'broad',
    single: 'broad',
    set: {
      steps: ['seedingSetPlant'],
      defaults: () => ({
        rowSeparation: 0, // [cm]
        rowDistance: 0, // [cm]
        plantsPerSqm: 0,
        totalPlants: 0, // may be calculated from rest
        packageSize: 0, // number of plants per retail package
        mixtureComponents: []
      })
    },
    plant: 'set',
    stripTill: 'broad',
    cornCloseSeed: 'broad',
    mulch: 'broad',
    other: {
      steps: ['seedingOther'],
      defaults: () => ({
        plantsPerSqm: 0,
        rowSeparation: 0, // [cm]
        rowDistance: 0, // [cm]
        emergence: 0, // TODO proper name
        germinationCapacity: 0,
        coating: null,
        mixtureComponents: []
      }) // TODO
    }
  },
  tillage: {
    schlegeln: 'other', // TODO proper name
    plowing: 'other',
    grubbern: 'other', // TODO proper name
    eggen: 'other', // TODO proper name
    basic: 'other',
    stoppelsturz: 'other', // TODO proper name
    seedbed: 'other',
    walzen: 'other', // TODO proper name
    stripTill: 'other',
    scheibeneggen: 'other',
    fraesen: 'other', // TODO proper name
    kreiseleggen: 'other',
    other: {steps: []}
  },
  protection: {
    hacken: {steps: []}, // TODO proper name
    harrow: 'hacken',
    other: 'hacken',
    spray: {
      steps: ['protectionSpray'],
      defaults: () => ({
        bbch: null // NOTE EC-Stadium
      })
    },
  },
  maintenance: {
    mulchenSchlaegeln: 'other',
    schleppen: 'other', // TODO proper name
    walzen: 'other', // TODO proper name
    vertikutieren: 'other', // TODO proper name
    other: {steps: []}
  },
  irrigation: {
    pipe: {
      steps: ['irrigation'],
      defaults: () => ({
        waterAmount: 0,
        waterExtraction: null
      }) // NOTE unit is currently fixed to mm/m²
    },
    circular: 'pipe',
    linear: 'pipe',
    drum: 'pipe',
    jet: 'pipe',
    drip: 'pipe',
    spray: 'pipe',
    underground: 'pipe',
    mobile: 'pipe',
    other: 'pipe',
  },
  transport: {
    steps: ['transport'],
    defaults: () => ({
      cargoType: 'general',
      internal: true,
      amount: {
        value: 0,
        unit: 'kg'
      },
      origin: '', // NOTE geo point at a later stage, text for now
      destination: '', // NOTE geo point at a later stage, text for now,
      distance: 0
    })
  },
  harvest: {
    maehdrusch: {
      steps: ['harvestQuality', 'harvestOther'],
      defaults: () => ({
        // TODO ASK-2813 rename to mainProduct or similar, including migration for existing data
        mainCropYield: {
          amount: null,
          unit: 'dt/ha'
        },
        // TODO ASK-2813 rename to byProduct or similar, including migration for existing data
        catchCropYield: { // NOTE refers only to the by-product that is actually transported off of the field, **not** the part that stays on the field
          amount: null,
          unit: 'dt/ha'
        },
        cropUsageId: null,
        cropHarvestQualityId: null,
        actualQuality: {
          moisture: null,
          dryMatter: null,
          protein: null,
          sugar: null,
          correctedSugar: null,
          starch: null,
          sedimentation: null,
          adhesiveProtein: null,
          lipid: null,
          fiber: null,
          weight: null,
          ash: null,
          brokenGrains: null,
          fallingNumber: null,
          impurity: null,
          wetAdhesiveProtein: null,
          mycotoxins: null,
        }
      })
    }, // TODO proper name
    gruenlandSchnitt: {
      steps: ['harvestQuality', 'harvestGrassLand'],
      defaults: () => ({
        cutNumber: null,
        cuttingTime: 'early',
        grassYield: {
          amount: null,
          unit: 'dt/ha'
        },
        cropUsageId: null,
        cropHarvestQualityId: null
      })
    },
    roden: 'maehdrusch', // TODO proper name
    schneiden: 'maehdrusch', // TODO proper name
    buendeln: 'maehdrusch', // TODO proper name
    haeckseln: 'maehdrusch', // TODO proper name
    pressen: 'maehdrusch', // TODO proper name
    pfluecken: 'maehdrusch', // TODO proper name
    stechen: 'maehdrusch', // TODO proper name
    wenden: 'maehdrusch', // TODO proper name
    schwaden: 'maehdrusch', // TODO proper name
    silieren: 'maehdrusch', // TODO proper name,
    other: 'maehdrusch'
  },
  fertilization: {
    mineral: {
      steps: ['fertilizationType', 'fertilizationAmount'],
      defaults: () => ({
        fertilizerData: {
          density: null
        },
        fertilizerNutrientContents: {
          nitrogen: 0,
          phosphorus: 0,
          potassium: 0,
          magnesium: 0,
          sulphur: 0,
          // parts of N, N >= nitrate + ammonium + amide
          nitrate: 0,
          ammonium: 0,
          nitrogenAmide: 0
        },
        isLiquid: false,
        applicationMethod: 'spreader_1',
        useCustomProduct: false,
        customProductName: null
      })
    },
    organic: 'mineral'
  },
  other: {
    steps: []
  }
}

const getSettings = (type, procedure) => {
  const settingsOrAlias = actionHierarchy[type].steps
    ? actionHierarchy[type]
    : actionHierarchy[type][procedure] || {}

  return typeof settingsOrAlias === 'string'
    ? actionHierarchy[type][settingsOrAlias]
    : settingsOrAlias
}

export function procedureParameterDefaults (type, procedure) {
  const settings = getSettings(type, procedure)

  return settings.defaults ? settings.defaults() : {}
}

export function procedureParameterSteps (type, procedure) {
  if (!type) return []

  const settings = getSettings(type, procedure)

  return settings.steps ? settings.steps : []
}

export function types () {
  return Object.keys(actionHierarchy)
}

export function procedures (type) {
  return actionHierarchy[type].steps
    ? []
    : Object.keys(actionHierarchy[type])
}
