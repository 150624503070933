import {makeSetters} from '@helpers/vuex/mutations'
import {makeSegmentFeatures} from '../helpers'

export const defaults = () => ({
  wkt: null,
  // headland/lane settings, partial field specific
  depth: 36,
  headlandLanes: 1, // NOTE new
  // results from API and subsequent selections
  segmentationResult: [],
  selectedSegments: [], // headland segment selection, 2d array, outer dimension is ring index, inner array contains selected segment indices
  headlands: [],
  isHeadlandGenerated: false,
  generatedHeadlandLane: 'MULTILINESTRING EMPTY',
  segmentLaneTypes: [], // AB or contour per segment, array of lookups {[segmentIndex]: laneType} for each ring
  candidateLanes: [],
  selectedLane: null,
  offset: 0, // NOTE new
  skip: false,
  subStep: null,
  abSegments: [],
  selectedAbSegment: null
})

const checkValidity = (state, partialState) => {
  for (const key in partialState) {
    if (!(key in state)) {
      console.error(`[lane part data editBuffer] set: property '${key}' does not exist in lane part data defaults`)
    }
  }
}

export default {
  namespaced: true,
  state: defaults(),
  mutations: {
    ...makeSetters([
      'selectedSegments',
      'segmentLaneTypes',
      'depth',
      'headlandLanes',
      'isHeadlandGenerated',
      'subStep',
      'abSegments',
      'selectedAbSegment'
    ]),
    selectAllSegments (state) {
      state.selectedSegments = state.segmentationResult.map(ring => {
        let i = 0
        return ring.map(() => i++)
      })
    },
    deselectAllSegments (state) {
      state.selectedSegments = state.segmentationResult.map(() => [])
    },
    set (state, partData) {
      checkValidity(state, partData)
      Object.assign(state, partData)
    },
    reset (state, partData) {
      if (partData) {
        checkValidity(state, partData)
      }
      Object.assign(state, defaults(), partData)
    }
  },
  actions: {
    findAndSetSelectedSegments ({state, commit, rootGetters}, segmentIds) {
      const segmentFeatures = makeSegmentFeatures(state.segmentationResult, state.segmentLaneTypes)
      const features = segmentIds.map(id => segmentFeatures.find(x => x.properties.id === id)).sort((b, a) => b.properties.segmentIndex - a.properties.segmentIndex)

      const selectedSegments = state.segmentationResult.map(() => [])
      features.forEach(feature => {
        const {ringIndex, segmentIndex} = feature.properties
        selectedSegments[ringIndex].push(segmentIndex)
      })

      commit('setSelectedSegments', selectedSegments)
    }
  }
}
