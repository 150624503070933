export const cloneFeatures = features => features.map(function (f) {
  const clone = f.clone()
  // clone.setId(f.getId())
  return clone
})

export function ensureUniqueIds (features) {
  const ids = {}

  return features.map(feature => {
    let id = feature.properties ? feature.properties.id : 'undefined'

    if (id in ids) {
      id += `_${ids[id]++}`
    } else {
      ids[id] = 1
    }

    return {
      ...feature,
      properties: {
        ...feature.properties,
        id
      }
    }
  })
}
