import {smarterGet, smarterPost} from '@helpers/vuex/data-loading'
import {makeSetters} from '@helpers/vuex/mutations'

export default {
  namespaced: true,
  state: {
    defaultYields: [],
    cropUsageId: null,
    yield: null
  },
  getters: {
    selectedEntityId (state, getters, rootState, rootGetters) {
      return rootGetters['fieldRecordSystem/fieldId'] || rootGetters['fieldRecordSystem/orgUnitId']
    }
  },
  mutations: {
    ...makeSetters([
      'cropUsageId',
      'yield'
    ]),
    reset (state, defaultYields) {
      state.defaultYields = defaultYields

      state.yield = null
      state.cropUsageId = null
    },
    add (state) {
      state.defaultYields.push({
        yield: state.yield,
        cropUsageId: state.cropUsageId
      })

      state.yield = null
      state.cropUsageId = null
    },
    remove (state, cropUsageId) {
      const index = state.defaultYields.findIndex(x => x.cropUsageId === cropUsageId)

      if (index !== -1) {
        state.defaultYields.splice(index, 1)
      }
    }
  },
  actions: {
    startEditingDefaultYields ({commit, dispatch}) {
      dispatch('loadDefaultYields')
        .then(() => {
          commit('fieldRecordSystem/setRightView', 'defaultYields', {root: true})
        })
    },
    loadDefaultYields ({commit, getters, rootState}) {
      return smarterGet('/api/v2/entities/{fieldId}/default-yields/{harvestYear}', {
        id: 'defaultYields.editing.load',
        expiry: 0,
        inputs: {
          fieldId: () => getters.selectedEntityId,
          harvestYear: () => rootState.fieldRecordSystem.userSettings.harvestYear
        },
        onResult (defaultYields) {
          commit('reset', defaultYields)
        }
      })
    },
    save ({state, commit, getters, rootState}) {
      const entityId = getters.selectedEntityId
      const harvestYear = rootState.fieldRecordSystem.userSettings.harvestYear
      return smarterPost(`/api/v2/entities/${entityId}/default-yields/${harvestYear}`, state.defaultYields, {
        id: 'defaultYields.editing.save',
        expiry: 0
      })
        .then(() => {
          commit('fieldRecordSystem/setRightView', 'default', {root: true})
        })
    }
  }
}
