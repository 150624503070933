import * as actions from './actions'
import * as mutations from './mutations'
import editBuffer from './edit-buffer'

const state = {
  ui: {
    selection: {
      machine: null
    },
    editing: false
  },
  data: {
    machines: [], // usually for current orgunit
    machineGroups: []
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  modules: {
    editBuffer
  }
}
