import {makeSetters} from '@helpers/vuex/mutations'

const defaults = () => ({
  id: null,
  orgUnitId: null,
  name: '',
  group: null,
  vin: null,
  workingWidth: 0,
  turningCircle: 0,
  isRetracted: false,
  isArchived: false
})

const checkValidity = partialMachine => {
  const defaultMachine = defaults()
  for (const key in partialMachine) {
    if (!(key in defaultMachine)) {
      console.error(`[machine editBuffer] set: property '${key}' does not exist in machine defaults`)
    }
  }
}

export default {
  namespaced: true,
  state: defaults(),
  mutations: {
    ...makeSetters([
      'id',
      'name',
      'vin',
      'workingWidth',
      'turningCircle',
      'group'
    ]),
    set (state, partialMachine) {
      checkValidity(partialMachine)
      Object.assign(state, partialMachine)
    },
    reset (state, machine) {
      if (machine) {
        checkValidity(machine)
      }
      Object.assign(state, defaults(), machine)
    }
  }
}
