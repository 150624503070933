import mutations from './mutations'
import actions from './actions'

import dataImport from './data-import'

const state = {
  data: {
    metaDataAll: null,
    selectedMetaData: null,
    measurements: null
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  modules: {
    dataImport
  }
}
