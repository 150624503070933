export const convertersByMapType = {
  zoneMap: [
    'FmxConverter',
    'TmxConverter',
    'ShapeConverter',
    'TrimbleAgGPSConverter',
    'AgroSenseSmartConverter',
    'KmlConverter',
    'KmzConverter'
  ],
  biomassMap: [
    'FmxConverter',
    'TmxConverter',
    'ShapeConverter',
    'TrimbleAgGPSConverter',
    'AgroSenseSmartConverter',
    'KmlConverter',
    'KmzConverter'
  ],
  applicationMap: [
    'ApplicationMapShapeConverter',
    'FmxConverter',
    'TmxConverter',
    'TrimbleAgGPSConverter',
    'IsoxmlConverter',
    'AgroSenseSmartConverter',
    'IsoxmlAdvancedConverter',
    'ISOxmlTopconConverter',
    'ShapeTopconConverter',
    'IsoXmlIntellview4Converter',
    'JohnDeereConverter',
    'KmlConverter',
    'KmzConverter'
  ],
  nutrientMap: [
    'FmxConverter',
    'TmxConverter',
    'TrimbleAgGPSConverter',
    'AgroSenseSmartConverter'
  ],
  fieldBorder: [
    'FmxConverter',
    'TmxConverter',
    'TrimbleAgGPSConverter',
    'TopConConverter',
    'ShapeConverter',
    'IsoxmlConverter',
    'IsoXmlIntellview4Converter'
  ],
  soilMap: [
    'ApplicationMapShapeConverter',
    'IsoxmlConverter'
  ],
  sensorData: [
    'GeoTiffConverter'
  ]
}

export const validShapeConvertersForMultiDownload = [
  'ShapeConverter',
  'ApplicationMapShapeConverter',
  'JohnDeereConverter',
  'GeoTiffConverter'
]

export const converterWithIncludeFieldBorderOption = [
  'TrimbleAgGPSConverter',
  'TmxConverter',
  'IsoxmlConverter',
  'IsoxmlAdvancedConverter',
  'ISOxmlTopconConverter',
  'IsoXmlIntellview4Converter'
]
