// workflow definition spec
//
// name:
//   OPTIONAL used for automatic translation keys (title and status bar of left sidebar for example)
//   only required if name differs from titlecased workflow name (titlecased for legacy translation reasons)
// component: componentName
//   VARIANT 1
//     OPTIONAL defines which component to use in the right sidebar
//     only required if the component name differs from titlecased workflow name
//     the component still has to be imported/registered in RightSidebar.vue
//   VARIANT 2
//     async component for lazy loading the component
//     no registration in RightSidebar.vue necessary
// wide: true
//   use a 800px right sidebar instead of 400px
// breadcrumbs: true
//   display standard breadcrumbs (WorkflowHeader.vue)
// usePortals: true
//   use the `workflowLeft` portal target for displaying components in the left sidebar
//   when this is used, the left sidebar component can and should be included inside the module root component
// alias: workflowName
//   defer all options to another workflow, providing an alias for navigation reasons
//   ONLY this option should be specified for alias workflows
// left: componentName
//   which component to display in the left sidebar
//   the component still has to be imported/registered in WorkflowLeft.vue
//   DEPRECATED in favor of `usePortals`

const workflows = {
  cultivationsDisplayField: {
    wide: true,
    breadcrumbs: true,
    component: 'field-cultivation-plan'
  },
  actionDisplayField: {
    breadcrumbs: true,
    component: 'actions-for-field'
  },
  cultivationPlanning: {},
  cultivationPlanningOrgUnit: {
    name: 'cultivation-planning',
    component: 'cultivation-planning',
    left: 'CultivationPlanningFieldSelection'
  },
  editCultivationPlanningOrgUnit: {alias: 'cultivationPlanning'},
  cropRotation: {
    wide: true,
    breadcrumbs: true,
    component: 'crop-rotation-management'
  },
  cultivationPlan: {
    wide: true,
    breadcrumbs: true,
    component: 'org-unit-cultivation-plan'
  },
  mixtureManagement: {
    wide: true,
    breadcrumbs: true
  },
  machinesOverview: {
    wide: true,
    breadcrumbs: true
  },
  nutritionStatusForm: {},
  personsOverview: {
    wide: true,
    breadcrumbs: true
  },
  companyMixtureManagement: {
    wide: true
  },
  actionDetailsPage: {
    breadcrumbs: true
  },
  weatherDetails: {
    wide: true,
    breadcrumbs: true
  },
  actionPlanning: {
    wide: true,
    left: 'ActionPlanningFieldSelection',
    fieldSelection: true
  },
  actionPlanningForSingleField: {
    wide: true,
    component: 'action-planning'
  },
  cultivationPlanningFromOrgUnit: {alias: 'cultivationPlanning'},
  actionPlanningForSingleFieldFromList: {alias: 'actionPlanningForSingleField'},
  actionPlanningFromOverview: {alias: 'actionPlanning'},
  actionPlanningFromCollection: {alias: 'actionPlanning'},
  actionPlanningFromDashboard: {alias: 'actionPlanning'},
  lanePlanning: {
    component: () => import('@frs/components/lane-planning/LanePlanningModuleRoot')
  },
  actionOverview: {
    wide: true,
    breadcrumbs: true,
    component: 'ActionsForOrgUnit'
  },
  companyData: {},
  newCompanyData: {alias: 'companyData'},
  companyNumbers: {
    wide: true,
    breadcrumbs: true
  },
  companyAccounts: {
    wide: true,
    breadcrumbs: true
  },
  nutrientRequirement: {},
  basicFertilization: {
    breadcrumbs: true,
    left: 'BasicFertilizationFieldSelection'
  },
  samplingOrderCreation: {
    left: 'SamplingOrderCreationFieldSelection'
  },
  samplingOrderPlanning: {
    left: 'SamplingOrderPlanningFieldSelection'
  },
  probingOrderExport: {
    left: 'BasicFertilizationFieldSelection'
  },
  probingResultManualSettings: {
    left: 'BasicFertilizationFieldSelection'
  },
  probingResultManualValues: {
    wide: true,
    left: 'BasicFertilizationFieldSelection'
  },
  probingResultUpload: {
    wide: true,
    breadcrumbs: true,
    left: 'BasicFertilizationFieldSelection'
  },
  nutrientMapDisplay: {
    left: 'NutrientMapsFieldSelection'
  },
  applicationMapCreation: {
    left: 'BasicFertilizationApplicationMapFieldSelection'
  },
  applicationMapPreview: {
    wide: true,
    left: 'ApplicationMapPreviewFieldSelection'
  },
  lanePlanSelectionOrgUnit: {
    breadcrumbs: true,
    component: () => import('@frs/components/lane-planning/LanePlanningModuleRoot')
  },
  lanePlanExport: {
    component: 'ResultPage',
    breadcrumbs: true
  },
  lanePlanDetail: {
    component: 'DetailView',
    breadcrumbs: true
  },
  entityImportPreview: {
    breadcrumbs: true,
    left: 'EntityImportEntitySelection'
  },
  defaultYields: {
    breadcrumbs: true
  },
  simpleApplicationMaps: {
    breadcrumbs: true
  },
  maizeSowing: {
    breadcrumbs: true
  },
  mapManagement: {
    wide: true,
    breadcrumbs: true
  },
  mapDetails: {
    breadcrumbs: true
  },
  applicationMapCreationResult: {
    breadcrumbs: true
  },
  applicationMapCreationResultMaizeSowing: {alias: 'applicationMapCreationResult'},
  applicationMapCreationResultSimpleApplicationMaps: {alias: 'applicationMapCreationResult'},
  applicationMapCreationResultBasicFertilization: {alias: 'applicationMapCreationResult'},
  applicationMapCreationResultSimon: {alias: 'applicationMapCreationResult'},
  telemetry: {
    breadcrumbs: true
  },
  simonModule: {
    breadcrumbs: true
  },
  applicationDetails: {},
  yieldDetails: {},
  algorithmicApplicationMapCreation: {},
  zoneMapImportSettings: {},
  soilMapImportSettings: {}
}

Object.keys(workflows).forEach(key => {
  const workflow = workflows[key]

  if (workflow.alias) return

  if (!workflow.component) {
    workflow.component = key[0].toUpperCase() + key.substring(1)
  }
  if (!workflow.name) {
    workflow.name = key[0].toUpperCase() + key.substring(1) // titlecased for legacy reasons
  }
})

export default workflows
