import {simplify} from '@turf/turf'

import {config} from 'src/js/infrastructure'

// NOTE use only for display purposes where precision is not important and slight discrepancies will not be noticed, e.g. thumbnails
export function simplifyLowQuality (geojsonGeometry) {
  simplify(geojsonGeometry, 0.0001, false, true)
}

export function simplifyHighQuality (geojsonGeometry) {
  simplify(geojsonGeometry, config.defaultImportSimplificationTolerance, true, true)
}
