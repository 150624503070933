import {makeSetters} from '@helpers/vuex/mutations'

import {spreadByHeterogenity} from '@helpers/map-creation'

import * as flowActions from './flow-actions'
import * as applicationMapActions from './actions'
import * as plannedYieldMutations from './planned-yield-mutations'

const creationParameterDefaults = () => ({
  zoneMapIdByFieldId: {},
  zoneMapId: null,
  heterogenityFactors: {}, // {[zoneId]: normalizedFactor}
  nutrients: {
    phosphorus: {min: null, max: null, selected: false},
    potassium: {min: null, max: null, selected: false},
    magnesium: {min: null, max: null, selected: false},
    ph: {min: null, max: null, selected: false}
  },
  // NOTE zoneYields and zoneWidthdrawals are only calculated for server call, not held in state
  plannedYields: [], // [{cropHarvestQualityId, averageYield, unit, useByProduct}...]
  desiredZones: 5
})

export default {
  namespaced: true,
  state: {
    selectedFieldId: null,
    isApplicationValue: false,
    step: 'basicSettings', // basicSettings, yieldPlanning, heterogenityAdjustment
    creationParameters: creationParameterDefaults(),
    years: null,
    lastInitializedZoneMapId: null
  },
  getters: {
    zoneMap (state, getters, rootState) {
      return rootState.fieldRecordSystem.mapManagement.data.zoneMap.mapLookup[state.creationParameters.zoneMapId]
    },
    mapsToCreate (state) {
      const nutrients = state.creationParameters.nutrients
      const selectedNutrients = Object.keys(nutrients).filter(nutrient => nutrients[nutrient].selected)
      return selectedNutrients
    },
    onlyPhSelected (state, getters) {
      return getters.mapsToCreate.length === 1 && getters.mapsToCreate[0] === 'ph'
    },
    phAndNutrientsSelected (state, getters) {
      return getters.mapsToCreate.length > 1 && getters.mapsToCreate.some(mapType => mapType === 'ph')
    },
    onlyNutrientsSelected (state, getters) {
      return getters.mapsToCreate.length >= 1 && getters.mapsToCreate.every(mapType => mapType !== 'ph')
    }
  },
  actions: {
    ...flowActions,
    ...applicationMapActions,
    setYears ({state, commit}, years) {
      const oldYears = state.years
      commit('setYears', years)
      commit(oldYears > years ? 'removePlannedYields' : 'addPlannedYields')
    },
    initializeHeterogenity ({state, rootState, commit}) {
      const zoneMap = rootState.fieldRecordSystem.mapManagement.data.zoneMap.mapLookup[state.creationParameters.zoneMapId]

      if (!zoneMap || !zoneMap.zones) {
        commit('setLastInitializedZoneMapId', null)
        return
      }

      if (state.lastInitializedZoneMapId === zoneMap.id) return

      const factors = spreadByHeterogenity(1, zoneMap)

      commit('setHeterogenityFactors', factors)
      commit('setLastInitializedZoneMapId', zoneMap.id)
    },
    calculateYieldDataForField ({state, rootState, commit}) {
      const zoneMap = rootState.fieldRecordSystem.mapManagement.data.zoneMap.mapLookup[state.creationParameters.zoneMapId]
      const cropHarvestQualities = rootState.masterData.cropHarvestQualities
      commit('calculateYieldData', {zoneMap, cropHarvestQualities})
    },
    saveHeterogenityFactors ({commit}, heterogenityFactors) {
      commit('setHeterogenityFactors', heterogenityFactors)
      commit('setStep', 'yieldPlanning')
    }
  },
  mutations: {
    ...plannedYieldMutations,
    ...makeSetters([
      'step',
      'years',
      'selectedFieldId',
      'isApplicationValue',
      'creationParameters.nutrients',
      'creationParameters.desiredZones',
      'creationParameters.nutrientLimits',
      'creationParameters.heterogenityFactors',
      'lastInitializedZoneMapId'
    ]),
    setZoneMapId (state, id) {
      state.creationParameters.zoneMapId = id
      state.creationParameters.zoneMapIdByFieldId[state.selectedFieldId] = id
    },
    startCreation (state) {
      state.creationParameters = creationParameterDefaults()
      state.selectedFieldId = null
      state.lastInitializedZoneMapId = null
      state.years = null
    },
    resetAfterCreation (state) {
      state.creationParameters.zoneMapIdByFieldId = {}
      state.creationParameters.zoneMapId = null
      state.creationParameters.heterogenityFactors = {}
      // state.creationParameters.plannedYields = []
      state.lastInitializedZoneMapId = null
    }
  }
}
