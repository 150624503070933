import Vue from 'vue'

import {cloneDeep} from 'lodash'
import {parse} from 'wellknown'

import {simplifyLowQuality} from '@helpers/geojson/simplification'

const mutations = {
  // TODO refactor, see ASK-2138
  setActionsForField(state, actions) {
    state.data.actions.forField = actions
  },
  // TODO refactor, see ASK-2138
  setActionsByField(state, {fieldId, actions}) {
    Vue.set(state.data.editing.actionsByField, fieldId, actions)
  },
  // TODO refactor, see ASK-2138
  setActionsForOrgUnit(state, actions) {
    state.data.actions.forOrgUnit = actions
  },
  addActionsForOrgUnit(state, actions) {
    state.data.actions.forOrgUnit.push(...actions)
  },
  setActionTemplates(state, actions) {
    state.data.actions.templatesForOrgUnit = actions
  },
  setWizardStep(state, step) {
    state.ui.planningWizard.step = step
    if (step === 'combinationStart') {
      state.ui.planningWizard.partEditingIndex = null
      state.editBuffer.productQuantities = []
      state.editBuffer.details = {}
    }
  },
  setParameterStep(state, step) {
    state.ui.planningWizard.parameterStep = step
  },
  replaceCombination(state, index) {
    const {type, procedure} = state.editBuffer
    // const {parameters, productQuantities} = state.editBuffer.details[state.editBuffer.fieldIds.find(x => x !== null)]
    const details = {}
    state.editBuffer.fieldIds.forEach(fieldId => {
      details[fieldId] = JSON.parse(
        JSON.stringify(state.editBuffer.details[fieldId])
      )
    })

    const {id} = state.data.planningWizard.combinationParts[index]

    state.data.planningWizard.combinationParts.splice(index, 1, {
      id,
      type,
      procedure,
      details,
    })
  },
  addCombinationPart(state) {
    const {type, procedure} = state.editBuffer

    const details = {}
    state.editBuffer.fieldIds.forEach(fieldId => {
      details[fieldId] = JSON.parse(
        JSON.stringify(state.editBuffer.details[fieldId])
      )
    })

    state.data.planningWizard.combinationParts.push({type, procedure, details})
  },
  setCombinationParts(state, parts) {
    state.data.planningWizard.combinationParts = parts
  },
  // TODO change signature to use index instead of object
  removeCombinationPart(state, part) {
    state.data.planningWizard.combinationParts = state.data.planningWizard.combinationParts.filter(
      x => x !== part
    )
  },
  updateActualAreaForPart(state, {index, fieldId, actualArea}) {
    state.data.planningWizard.combinationParts[index].details[fieldId] = {
      ...state.data.planningWizard.combinationParts[index].details[fieldId],
      actualArea,
    }
  },
  setActiveActionId(state, actionId) {
    state.ui.selection.actionId = actionId
  },
  setPartEditingIndex(state, index) {
    state.ui.planningWizard.partEditingIndex = index
  },
  // setActionIndex (state, index) {
  //   state.editBuffer.indexFromAction = index
  // },
  // setCombinationActionWasEdited (state) {
  //   state.editBuffer.combinationActionWasEdited = !state.editBuffer.combinationActionWasEdited
  // },
  setActionStateForOrgUnit(state, {action, newState}) {
    const currentAction = state.data.actions.forOrgUnit.find(
      x => x.id === action.id
    )
    currentAction.timestamps.actual.start = newState.start
    currentAction.timestamps.actual.end = newState.end
  },
  setActionStateForField(state, {action, newState}) {
    const currentAction = state.data.actions.forField.find(
      x => x.id === action.id
    )
    currentAction.timestamps.actual.start = newState.start
    currentAction.timestamps.actual.end = newState.end
  },
  setCultivationsForOrgUnit(state, cultivations) {
    state.cultivationsForOrgUnit = {}
    cultivations.forEach(cultivation => {
      const geometry = parse(cultivation.geometry)

      cultivation.geojsonGeometry = geometry

      const simplifiedGeometry = cloneDeep(geometry)
      // TODO find out why we have cultivations with empty geometries
      if (simplifiedGeometry && simplifiedGeometry.coordinates.length) {
        simplifyLowQuality(simplifiedGeometry)
      }

      cultivation.simplifiedGeometry = simplifiedGeometry

      Vue.set(state.cultivationsForOrgUnit, cultivation.id, cultivation)
    })
  },
  setNavigationSource(state, {cancelTarget, saveTarget}) {
    state.navigationSource = {cancelTarget, saveTarget}
  },
  reset (state) {
    state.data.planningWizard.combinationParts = []
  },
}

export default mutations
