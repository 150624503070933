export default {
  field: {
    cultivationPlan: 'cultivationsDisplayField'
  },
  orgUnit: {
    cultivationsDisplayField: 'cultivationPlan'
  },
  back: {
    cultivationPlanning: 'cultivationsDisplayField',
    mixtureManagement: 'cultivationPlanning',
    actionPlanningFromOverview: 'actionOverview',
    actionPlanningFromCollection: 'annualPlan',
    actionPlanningFromDiff: 'annualPlanDiff',
    actionPlanningForSingleFieldFromList: 'actionDisplayField',
    actionPlanningForSingleField: 'actionDisplayField',
    actionDetails: 'actionDisplayField',
    actionDetailsPage: 'actionDisplayField',
    probingOrderExport: 'basicFertilization',
    probingResultManualSettings: 'basicFertilization',
    probingResultManualValues: 'basicFertilization',
    probingResultUpload: 'basicFertilization',
    nutrientMapDisplay: 'basicFertilization',
    cultivationPlanningFromOrgUnit: 'cultivationPlan',
    lanePlanExport: 'lanePlanSelectionOrgUnit',
    lanePlanDetail: 'lanePlanSelectionOrgUnit'
  },
  escape: {
    cultivationPlanning: 'cultivationsDisplayField',
    mixtureManagement: 'cultivationsDisplayField',
    cultivationPlanningFromOrgUnit: 'cultivationPlan',
    companyData: 'default',
    companyNumbers: 'default',
    actionPlanningForSingleField: 'default',
    actionDetailsPage: 'actionDisplayField'
  }
}
