let counter = 0

function reset (state, hard) {
  state.selectedZoneMapId = null
  state.selectedFieldId = null
  state.probingSites = {
    existing: [],
    new: [],
    deleted: []
  }
  state.probingSiteMode = 'probingPoints'

  if (hard) {
    state.selectedOrderId = null
    state.fieldIds = []
  }
}

export function setShowNumbers (state, boolean) {
  state.showNumbers = boolean
}

export function setProbingSitesByFieldId (state, probingSitesByFieldId) {
  const lookup = {}
  for (const fieldId in probingSitesByFieldId) {
    probingSitesByFieldId[fieldId].forEach(probingSite => {
      probingSite.isActive = false
    })

    lookup[fieldId] = {
      existing: probingSitesByFieldId[fieldId],
      new: [],
      deleted: []
    }
  }
  state.probingSitesByFieldId = lookup
}

export function finishField (state) {
  if (state.selectedFieldId) {
    state.probingSitesByFieldId = {
      ...state.probingSitesByFieldId,
      [state.selectedFieldId]: state.probingSites
    }
  }

  reset(state)
}

export function setIncompleteOrders (state, orders) {
  reset(state, true)

  state.incompleteOrders = orders
}

export function setSelectedOrderId (state, orderId) {
  reset(state, true)

  state.selectedOrderId = orderId
}

export function setSelectedFieldId (state, fieldId) {
  finishField(state)

  Object.assign(state.probingSites, state.probingSitesByFieldId[fieldId])

  state.selectedFieldId = fieldId
}

export const addNewProbingSite = (state, wkt) => {
  const labels = state.probingSites.new.concat(state.probingSites.existing).map(x => Number(x.label)).filter(x => x)

  let nextLabel = 1
  while (labels.includes(nextLabel)) {
    nextLabel++
  }

  state.probingSites.new.push({id: counter++, geometry: wkt, label: `${nextLabel}`, isActive: true})
}

export const renameProbingSite = (state, {id, label}) => {
  const probingSite = state.probingSites.new.concat(state.probingSites.existing).find(x => x.id === id)
  probingSite.label = label
}

export const toggleActive = (state, {id}) => {
  Object.values(state.probingSitesByFieldId).map(probingSites => probingSites.existing
    .find(existing => existing.id === id))
    .filter(x => x)
    .map(convertProbingSite => {
      convertProbingSite.isActive = !convertProbingSite.isActive
    })
}

export function setActiveForAll (state, isActive) {
  const geometryType = state.probingSiteMode === 'probingPoints' ? 'POINT' : 'LINESTRING'

  for (const key in state.probingSites) {
    for (const probingSite of state.probingSites[key].filter(x => x.geometry.startsWith(geometryType))) {
      probingSite.isActive = isActive
    }
  }
}

export const toggleDeletion = (state, {id}) => {
  const probingSites = state.probingSites

  if (probingSites.deleted.map(x => x.id).includes(id)) {
    probingSites.deleted = probingSites.deleted.filter(x => x.id !== id)
  } else {
    probingSites.deleted.push(probingSites.existing.concat(probingSites.new).find(x => x.id === id))
  }
}

export const setSelectedZoneMapId = (state, zoneMapId) => {
  state.selectedZoneMapId = zoneMapId
}

export const setProbingSiteMode = (state, mode) => {
  state.probingSiteMode = mode
}

export const resetChanges = (state) => {
  state.probingSites.new = []
  state.probingSites.deleted = []
  state.probingSites.existing.forEach(probingSite => {
    probingSite.isActive = false
  })
}
