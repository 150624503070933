import axios from 'axios'
import router from '@frs/router'

export const startEditing = ({commit, dispatch}, fieldId) => {
  return dispatch('reset', fieldId).then(() => {
    commit('fieldRecordSystem/setRightView', 'basicFieldInfoForm', {root: true})
  })
}

// TODO merge editBuffer into parent module, save usageTypes in parent module, pull initialization into reset mutation
export function reset ({rootState, rootGetters, commit}, fieldId) {
  let field

  if (fieldId) {
    const fieldDetails = rootState.fieldRecordSystem.data.field[fieldId]
    const fieldName = rootGetters['fieldRecordSystem/entityNameLookup'][fieldId]

    field = Object.assign({}, {id: fieldId, name: fieldName}, fieldDetails)
  }

  commit('editBuffer/reset', field)
}

export async function save ({state, rootState, commit, dispatch}) {
  const rootDispatch = (name, payload) => dispatch(name, payload, {root: true})
  const rootCommit = (name, payload) => commit(name, payload, {root: true})
  const {harvestYear} = rootState.fieldRecordSystem.userSettings
  const {orgUnitId} = rootState.fieldRecordSystem.navigation.location

  const {id, name, ...fieldData} = state.editBuffer

  const payload = {
    ...fieldData,
    id,
    orgUnitId,
    name: name.trim()
  }

  const serverContext = rootState.fieldRecordSystem.data.field[id]
    ? rootState.fieldRecordSystem.data.field[id].context
    : {
      mode: 'new'
    }

  // TODO add context to payload instead of wrapping payload in context, needs serverside changes as well
  const clientContext = {
    ...serverContext,
    payload
  }

  const response = await axios.post(`/api/v2/frs/${harvestYear}/fields`, clientContext)
  const field = response.data

  if (!id) {
    await rootDispatch('fieldRecordSystem/navigation/addEntity', {entity: field, orgUnitId})
  } else {
    await rootDispatch('fieldRecordSystem/navigation/renameEntity', {entityId: field.id, name: field.name})
  }

  rootCommit('fieldRecordSystem/updateFieldData', {[field.id]: {...fieldData, entityId: field.id}})

  const cropRotation = rootState.fieldRecordSystem.cropRotations.company.find(cr => cr.id === fieldData.cropRotationId)
  rootCommit('fieldRecordSystem/assignCropRotationToField', cropRotation)

  rootCommit('dataLoading/invalidate', 'harvestYear.fullFieldData')

  return field.id
}

export async function splitField ({state, rootState, commit, dispatch}, geometries) {
  const rootDispatch = (name, payload) => dispatch(name, payload, {root: true})

  const {orgUnitId, fieldId} = rootState.fieldRecordSystem.navigation.location
  const {harvestYear} = rootState.fieldRecordSystem.userSettings

  const context = {
    ...rootState.fieldRecordSystem.data.field[fieldId].context,
    payload: geometries
  }

  const response = await axios.post(`/api/v2/frs/${harvestYear}/fields/${fieldId}/executeSplit`, context)
  const newFields = response.data

  await Promise.all([
    ...newFields.map(field => {
      rootDispatch('fieldRecordSystem/navigation/addEntity', {entity: field, orgUnitId})
    }),
    rootDispatch('fieldRecordSystem/navigation/removeEntity', fieldId)
  ])

  commit('dataLoading/invalidate', 'orgUnit.simplified-geometries', {root: true})

  router.push({name: 'orgDashboard', params: {orgUnitId}})

  await rootDispatch('fieldRecordSystem/map/stopEditing')
}
