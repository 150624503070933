import {mapValues} from 'lodash'

export function selectionSummaryByFieldId (state, getters) {
  return mapValues(getters.relevantCultivationsByFieldId, (cultivations, fieldId) => {
    const selectedCultivationCount = cultivations.filter(cultivation => state.selectedCultivationIds[cultivation.id] === fieldId).length
    const availableCultivationCount = cultivations.length

    const atLeastOneCultivationSelected = selectedCultivationCount > 0
    const allCultivationsSelected = selectedCultivationCount === availableCultivationCount
    const hasRemainingField = !!getters.remainingFieldFeatures[fieldId]
    const remainingFieldSelected = hasRemainingField && !!state.remainingFieldSelected[fieldId]
    const hasCustomGeometry = !!state.customGeometries[fieldId]

    const somethingSelected = atLeastOneCultivationSelected || remainingFieldSelected || hasCustomGeometry
    const everythingSelected = allCultivationsSelected && (remainingFieldSelected || !hasRemainingField)

    return {
      selectedCultivationCount,
      availableCultivationCount,
      hasRemainingField,
      remainingFieldSelected,
      somethingSelected,
      everythingSelected,
      hasCustomGeometry
    }
  })
}
