import axios from 'axios'
import {fromPairs} from 'lodash'

import {makeSetters} from '@helpers/vuex/mutations'

const defaultMeasurements = () => ({
  nitrogen: {
    timestamp: null,
    // NOTE value is only set indirectly via the layer values, not edited manually
    value: null,
    layer30: null,
    layer60: null,
    layer90: null,
    note: ''
  },
  potassium: {
    timestamp: null,
    value: null
  },
  phosphorus: {
    probingMethod: 'CAL',
    timestamp: null,
    value: null
  },
  magnesium: {
    timestamp: null,
    value: null
  },
  humusContent: {
    timestamp: null,
    value: null
  },
  waterLevel: {
    timestamp: null,
    value: null
  },
  ph: {
    timestamp: null,
    value: null
  }
})

function calculateTotalNitrogenValue (state) {
  const nitrogenLayerValues = ['layer30', 'layer60', 'layer90'].map(x => state.measurements.nitrogen[x])

  // NOTE multiple `null` values add up to `0` in JavaScript, so a check is required to handle the case where every layer is `null`; in this case the total value should also be treated as `null` and not as a `0` value measurement
  if (nitrogenLayerValues.some(x => x !== null)) {
    state.measurements.nitrogen.value = nitrogenLayerValues.reduce((total, x) => total + x, 0)
  } else {
    state.measurements.nitrogen.value = null
  }
}

function setUniformTimestamp (state, date) {
  for (const nutrient in state.measurements) {
    state.measurements[nutrient].timestamp = date
  }
}

export default {
  namespaced: true,
  state: {
    measurements: defaultMeasurements(),
    useUniformTimestamp: false
  },
  getters: {
    anyNutrientHasValue (state) {
      return Object.values(state)
        .some(x => x.value !== null)
    },
    uniformTimestamp (state) {
      return state.useUniformTimestamp
        ? state.measurements.nitrogen.timestamp
        : null
    }
  },
  mutations: {
    ...makeSetters([
      'measurements.humusContent',
      'measurements.waterLevel',
      'measurements.ph',
      'measurements.nitrogen',
      'measurements.nitrogen.note',
      'measurements.potassium',
      'measurements.phosphorus',
      'measurements.phosphorus.probingMethod',
      'measurements.magnesium'
    ]),
    setLayer30 (state, value) {
      state.measurements.nitrogen.layer30 = value
      calculateTotalNitrogenValue(state)
    },
    setLayer60 (state, value) {
      state.measurements.nitrogen.layer60 = value
      calculateTotalNitrogenValue(state)
    },
    setLayer90 (state, value) {
      state.measurements.nitrogen.layer90 = value
      calculateTotalNitrogenValue(state)
    },
    setUseUniformTimestamp (state, useUniformTimestamp) {
      state.useUniformTimestamp = useUniformTimestamp

      if (useUniformTimestamp) {
        // ensure consistent uniform timestamp, in case individual timestamps were already set
        // using any existing timestamp from a specific nutrient would be arbitary and hard to understand, so it is set to `null`
        setUniformTimestamp(state, null)
      }
    },
    setUniformTimestamp,
    reset (state, partialNutritionStatus) {
      // NOTE should it become possible to have not-`null` timestamps on reset, the conditional call to `setUniformTimestamp` contained in `setUseUniformTimestamp` needs to be replicated here as well to ensure consistent state
      Object.assign(state.measurements, defaultMeasurements())
    }
  },
  actions: {
    startEditing ({rootState, commit}) {
      commit('reset')
      commit('fieldRecordSystem/setRightView', 'nutritionStatusForm', {root: true})
    },
    stopEditing ({state, commit}) {
      commit('fieldRecordSystem/setRightView', 'default', {root: true})
    },
    async save ({state, rootState, commit}) {
      const measurementLookup = JSON.parse(JSON.stringify(state.measurements))

      const {orgUnitId, fieldId} = rootState.fieldRecordSystem.navigation.location
      const fieldData = rootState.fieldRecordSystem.data.field[fieldId]

      // TODO skip save for unchanged values (compare with current field nutrient values)
      const measurements = Object.keys(measurementLookup)
        .map(type => ({...measurementLookup[type], geometry: fieldData.wkt, type}))
        .filter(x => x.value !== null)

      const dto = {
        nitrogen: measurements.find(x => x.type === 'nitrogen'),
        otherNutrients: measurements.filter(x => x.type !== 'nitrogen')
      }

      await axios.put(`/api/v2/geo-measurements/${orgUnitId}/nutrients`, dto)

      const nutrients = Object.assign(fieldData.nutrients, fromPairs(measurements.map(x => [x.type, x])))

      commit('fieldRecordSystem/updateFieldData', {[fieldId]: {nutrients}}, {root: true})
    }
  }
}
