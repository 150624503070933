import {defaults} from './index'
import {cultivationToColor, cultivationToName, cultivationTypeToShortcut} from '@frs/components/cultivation-overview/helpers'

import {makeSetters} from '@helpers/vuex/mutations'

const mutations = {
  ...makeSetters([
    'cultivations',
    'fields',
    'orgUnitName',
    'selected'
  ]),
  removeUnknownSelected (state, cultivations) {
    const cultivationIds = cultivations.map(x => x.properties.id)
    const ids = state.selected.filter(id => cultivationIds.includes(id))
    state.selected = ids
  },
  initializeCultivations (state, cultivations) {
    cultivations.forEach(cultivation => {
      cultivation.properties.color = cultivationToColor(cultivation.properties)
      cultivation.properties.label = cultivationToName(cultivation.properties)
      cultivation.properties.typeShortcut = cultivationTypeToShortcut(cultivation.properties)
    })
    state.cultivations = cultivations
  },
  toggleCultivation (state, cultivationId) {
    const ids = [...state.selected]
    if (ids.includes(cultivationId)) {
      const index = ids.indexOf(cultivationId)
      ids.splice(index, 1)
    } else {
      ids.push(cultivationId)
    }
    state.selected = ids
  },
  toggleRule (state, rule) {
    const clone = [...state.filterRules]
    const index = clone.findIndex(ruleElement => (ruleElement.property === rule.property && ruleElement.filter === rule.filter))

    if (index !== -1) {
      clone.splice(index, 1)
    } else {
      clone.push(rule)
    }
    state.filterRules = clone
  },
  setVisibleCultivations (state, visibleCultivation) {
    state.visible = visibleCultivation.map(x => x.id)
  },
  clearSelected (state) {
    state.selected = []
  },
  clearFilter (state) {
    state.filterRules = []
  },
  deselectFilterType (state, type) {
    state.filterRules = state.filterRules.filter(x => x.property !== type)
  },
  reset (state) {
    var reset = {...defaults(), selected: state.selected}
    Object.assign(state, reset)
  }
}

export default mutations
