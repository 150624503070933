import {smarterPost} from '@helpers/vuex/data-loading'
import {toObject} from '@helpers/reducers'

export async function createApplicationMap ({state, commit, dispatch, rootState}) {
  await dispatch('calculateYieldDataForField')

  const {nutrients, zoneMapId, ...otherParameters} = state.creationParameters

  const selectedNutrients = Object.keys(nutrients).filter(nutrient => nutrients[nutrient].selected)

  const data = {
    ...otherParameters,
    minMaxValuesByNutrient: selectedNutrients.map(nutrient => [nutrient, {
      min: nutrients[nutrient].min,
      max: nutrients[nutrient].max
    }]).reduce(toObject, {})
  }

  // at the moment the fieldIds of zonemapIdByFieldId are used to create the maps in the backend, so there must be at least an entry for the currently selected field
  if (Object.keys(data.zoneMapIdByFieldId).length === 0) {
    data.zoneMapIdByFieldId[state.selectedFieldId] = null
  }

  const harvestYear = rootState.fieldRecordSystem.userSettings.harvestYear
  commit('setStep', 'basicSettings')
  commit('resetAfterCreation')
  await smarterPost(`/api/v2/base-fertilization/application-maps/${harvestYear}`, data, {
    id: 'bf.applicationMaps.create'
  })
}
