import axios from 'axios'

import {can} from '@helpers/permissions'
import {lookup} from '@helpers/data-transformation'
import {smarterGet, smarterPost} from '@helpers/vuex/data-loading'
import {processFieldWkt} from './field-geometries'

export async function loadBasicFieldData ({state, commit}, fieldIds) {
  const fieldData = await smarterPost('/api/v2/frs/{harvestYear}/fields/data/basic', fieldIds, {
    inputs: {
      harvestYear: () => state.userSettings.harvestYear
    },
    id: 'entity.field.data.basic'
  })

  if (fieldData) {
    const fieldDataLookup = lookup(fieldData, d => d.entityId)

    commit('updateFieldData', fieldDataLookup)
  } else {
    console.error('[loadBasicFieldData] was called multiple times, some calls were ignored')
  }
}

export const loadFullFieldData = ({state, commit}, fieldId) => {
  return smarterGet('/api/v2/frs/{harvestYear}/fields/{fieldId}/data',
    {
      id: 'harvestYear.fullFieldData',
      expiry: 120,
      inputs: {
        harvestYear: () => state.userSettings.harvestYear,
        fieldId: () => fieldId
      },
      // NOTE sharedCache is only necessary because of cropRotation, fieldData itself is not shared
      sharedCache: true,
      async onResult (fieldData) {
        const cropRotation = state.cropRotations.company.find(cr => cr.id === fieldData.cropRotationId)
        commit('assignCropRotationToField', cropRotation)

        const additionalFieldData = await processFieldWkt(fieldData.geometry)
        Object.assign(fieldData, additionalFieldData)

        const fieldDataLookup = lookup([fieldData], d => d.entityId)

        commit('updateFieldData', fieldDataLookup)
      }
    })
}

export async function createNewOrgUnit ({state, commit, dispatch}, {name}) {
  const parentId = state.navigation.location.orgUnitId

  if (!name) {
    throw new Error('empty orgUnit name is invalid')
  }

  const url = parentId
    ? `/api/v2/entities/${parentId}/orgunits?name=${encodeURIComponent(name)}`
    : `/api/v2/entities/orgunits?name=${encodeURIComponent(name)}`

  const entity = (await axios.post(url)).data
  entity.children = [] // TODO find out why server doesn't send this

  if (!parentId) {
    await dispatch('reloadEntities')
  } else {
    await dispatch('navigation/addEntity', {entity, orgUnitId: parentId})
  }
  commit('setRightView', 'default')

  return entity.id // continuation in CompanyData.vue relies on the created orgUnitId from the server
}

export async function deleteOrgUnit ({state, dispatch, getters}) {
  const {orgUnitId} = state.navigation.location

  await axios.delete(`/api/v2/entities/${orgUnitId}`)
  await dispatch('navigation/removeEntity', orgUnitId)
}

export async function updateOrgUnitName ({dispatch, getters}, {orgUnitId, name}) {
  if (!name) {
    throw new Error('empty orgUnit name is invalid')
  }

  const originalName = getters.entityLookup[orgUnitId].name
  await dispatch('navigation/renameEntity', {entityId: orgUnitId, name})

  try {
    await axios.put(`/api/v2/entities/${orgUnitId}/name?name=${encodeURIComponent(name)}`)
  } catch (error) {
    console.error(error)
    await dispatch('navigation/renameEntity', {entityId: orgUnitId, name: originalName})
  }
}

// TODO check if used, and if it works with history
export async function createNewField ({state, dispatch}, {name, wkt}) {
  const {orgUnitId} = state.navigation.location

  const entity = (await axios.post(`/api/v2/entities/${orgUnitId}/fields`, {name, wkt})).data

  await Promise.all([
    dispatch('navigation/addEntity', {entity, orgUnitId}),
    dispatch('updateFieldGeometry', {fieldId: entity.id, wkt}),
    dispatch('loadBasicFieldData', [entity.id])
  ])
}

export async function deleteField ({state, commit, dispatch, rootState}) {
  const {fieldId} = state.navigation.location
  const harvestYear = rootState.fieldRecordSystem.userSettings.harvestYear

  const context = rootState.fieldRecordSystem.data.field[fieldId].context
  context.payload = null

  try {
    await axios.post(`/api/v2/frs/${harvestYear}/fields/delete`, context)
    await dispatch('navigation/removeEntity', fieldId)
    commit('dataLoading/invalidate', 'harvestYear.fullFieldData', {root: true})
  } catch (error) {
    throw error
  }
}
export async function saveCompanyData ({state, commit}, {companyData, orgUnitId}) {
  commit('setDataLoading', true)

  try {
    const orgUnitData = state.data.orgUnits.data
    const existingCompanyData = orgUnitId && orgUnitData[orgUnitId] && orgUnitData[orgUnitId].companyData

    if (!existingCompanyData) {
      await axios.put(`/api/v2/entities/orgunits/${orgUnitId}/data/company`)
      commit('addEmptyMasterData', orgUnitId)
    }

    await axios.put(`/api/v2/entities/orgunits/${orgUnitId}/data/company/info`, companyData)

    commit('updateOrgUnitData', {[orgUnitId]: {companyData}})
    commit('setRightView', 'default')
  } finally {
    commit('setDataLoading', false)
  }
}

export async function loadMasterData ({commit}, orgUnitId) {
  const [{data: companyData}, {data: companyNumbers}] = await Promise.all([
    axios.get(`/api/v2/entities/orgunits/${orgUnitId}/data/company/info`),
    axios.get(`/api/v2/entities/orgunits/${orgUnitId}/data/company/identification-numbers`)
  ])

  commit('updateOrgUnitData', {[orgUnitId]: {companyData: companyData || null, companyNumbers}}) // NOTE server returns 204 with empty string body instead of null if no company data exists
}

export async function updateCompanyNumber ({state, commit}, companyNumber) {
  commit('setDataLoading', true)
  const {orgUnitId} = state.navigation.location
  try {
    const response = await axios.post(`/api/v2/entities/orgunits/${orgUnitId}/data/company/identification-numbers`, companyNumber)

    if (!companyNumber.id) {
      companyNumber = response.data
    }

    commit('updateCompanyNumber', {orgUnitId, companyNumber})
  } finally {
    commit('setDataLoading', false)
  }
}

export async function removeCompanyNumber ({state, commit}, companyNumber) {
  commit('setDataLoading', true)

  const {orgUnitId} = state.navigation.location

  try {
    await axios.delete(`/api/v2/entities/orgunits/${orgUnitId}/data/company/identification-numbers/${companyNumber.id}`)

    commit('removeCompanyNumber', {orgUnitId, companyNumberId: companyNumber.id})
  } finally {
    commit('setDataLoading', false)
  }
}

export async function deleteMasterData ({state, commit}) {
  commit('setDataLoading', true)

  const {orgUnitId} = state.navigation.location

  try {
    await axios.delete(`/api/v2/entities/orgunits/${orgUnitId}/data/company`)

    commit('deleteMasterData', orgUnitId)
    commit('setRightView', 'default')
  } finally {
    commit('setDataLoading', false)
  }
}

export const saveRestructuredOrgTree = ({dispatch}, changeList) => {
  return axios.post('/api/v2/entities/moveEntities', changeList)
    .then(() => dispatch('reloadEntities'))
}

export const loadDataForActions = ({dispatch}) => {
  return Promise.all([
    dispatch('action/loadActionsForOrgUnit'),
    dispatch('machines/loadMachines'),
    dispatch('persons/loadPersons')
  ])
  .then(([actions]) => actions)
}

export function reloadFieldCultivationInfoForOrgUnit ({commit, rootGetters, rootState}) {
  const orgUnit = rootGetters['fieldRecordSystem/orgUnit']
  if (can(rootState.permissions, 'Use.Frs.Cultivations') && !orgUnit.visibleOnly) {
    return smarterGet('/api/v2/{harvestYear}/org/{orgUnitId}/cultivation-info', {
      id: 'orgUnit.fieldCultivationInfo',
      expiry: 10,
      inputs: {
        orgUnitId: () => rootState.fieldRecordSystem.navigation.location.orgUnitId,
        harvestYear: () => rootState.fieldRecordSystem.userSettings.harvestYear
      },
      sharedCache: true, // NOTE only required because of the clearing loop below
      onResult (cultivationInfoByFieldId) {
        for (const id in cultivationInfoByFieldId) {
          cultivationInfoByFieldId[id] = {cultivationInfo: cultivationInfoByFieldId[id]}
        }
        // NOTE this necessitates the `sharedCache` flag
        for (const field of rootGetters['fieldRecordSystem/fieldsForCurrentOrgUnit']) {
          if (!cultivationInfoByFieldId[field.id]) {
            cultivationInfoByFieldId[field.id] = {cultivationInfo: null}
          }
        }
        commit('fieldRecordSystem/updateFieldData', cultivationInfoByFieldId, {root: true})
      }
    })
  } else {
    return Promise.resolve()
  }
}
