import axios from 'axios'

import {smarterGet} from '@helpers/vuex/data-loading'

export default {
  saveYieldDetails ({state, rootState, commit}) {
    if (!state.yieldDetails.id) {
      const harvestYear = rootState.fieldRecordSystem.userSettings.harvestYear
      const year = harvestYear - state.creation.yieldData.length - 1
      commit('setNumber', year)
    }

    const selectedMap = state.data.yieldMetaData.find(x => x.id === state.yieldDetails.selectedYieldMapId)
    if (selectedMap) commit('setYieldName', selectedMap.name)

    commit('updateYieldDataList')
    commit('resetYieldDetails')
    commit('fieldRecordSystem/setRightView', 'simonModule', {root: true})
  },
  saveProductionDetails ({state, commit}) {
    const selectedMap = state.data.applicationMetaData.find(x => x.id === state.applicationDetails.selectedMapId)

    if (selectedMap) commit('setApplicationName', selectedMap.name)
    if (!state.applicationDetails.id) {
      const length = state.creation.applicationData.length
      commit('setApplicationNumber', length + 1)
    }
    commit('setApplicationDetails')
  },
  startSimonModule ({commit, rootGetters, rootState}) {
    const orgUnitId = rootGetters['fieldRecordSystem/orgUnitId']
    const fieldId = rootGetters['fieldRecordSystem/fieldId']
    const {harvestYear} = rootState.fieldRecordSystem.userSettings

    return smarterGet('/api/v2/telemetry/orgunits/{orgUnitId}/{fieldId}/metadata/all/{harvestYear}', {
      id: 'telemetry.metadata',
      expiry: 0,
      inputs: {
        orgUnitId: () => orgUnitId,
        fieldId: () => fieldId,
        harvestYear: () => harvestYear
      },
      onResult (metaData) {
        commit('reset')
        commit('setApplicationMetaData', metaData)
        commit('fieldRecordSystem/setRightView', 'simonModule', {root: true})
      }
    })
  },
  startComputation ({state, rootGetters}) {
    const fieldId = rootGetters['fieldRecordSystem/fieldId']
    return axios.post('/api/v2/mapcreation/simon', Object.assign({fieldId}, state.creation))
      .then(result => result.data)
  },
  startAddApplicationMap ({commit}) {
    commit('fieldRecordSystem/setRightView', 'applicationDetails', {root: true})
  },
  viewResultPage ({commit}) {
    commit('fieldRecordSystem/setRightView', 'applicationMapCreationResultSimon', {root: true})
  },
  goBackToSimon ({commit}) {
    commit('fieldRecordSystem/setRightView', 'simonModule', {root: true})
  }
}
