export function nextPaymentData (state, getters, rootState, rootGetters) {
  const orgUnitId = rootGetters['fieldRecordSystem/orgUnitId']
  const parentLookup = rootGetters['fieldRecordSystem/navigation/parentLookup']

  let parentId = orgUnitId
  while (parentId) {
    const data = rootState.fieldRecordSystem.data.orgUnits.data[parentId]

    if (data && data.companyData) {
      return {orgUnitId: parentId, ...data.companyData}
    }

    parentId = parentLookup[parentId]
  }
  return null
}

export function paymentDataMZK (state, getters, rootState, rootGetters) {
  const orgUnitId = rootGetters['fieldRecordSystem/orgUnitId']

  const data = rootState.fieldRecordSystem.data.orgUnits.data[orgUnitId]

  if (data && data.companyData) {
    return data.companyData
  }
  return null
}

export function missingPaymentData (state, getters) {
  return getMissingCompanyData(getters.nextPaymentData)
}

export function missingPaymentDataMZK (state, getters) {
  return getMissingCompanyData(getters.paymentDataMZK)
}

function getMissingCompanyData (paymentData) {
  const requiredData = [
    'address1',
    'address2',
    'address3',
    'email',
    'name',
    'phoneNumber'
  ]

  if (!paymentData) {
    return requiredData
  }

  let missingData = []

  const companyData = paymentData
  if (companyData) {
    for (const propName of requiredData) {
      if (companyData[propName] === null || companyData[propName] === '') {
        missingData.push(propName)
      }
    }
  }
  return missingData
}
