import Vue from 'vue'

export const mutations = {
  setUserSettings (state, partialSettings) {
    delete partialSettings.ui
    Object.assign(state.userSettings, partialSettings)
  },
  setUserUiSettings (state, {flag, value}) {
    value
      ? Vue.set(state.userSettings.ui, flag, value)
      : Vue.delete(state.userSettings.ui, flag)
  },
  setFieldTags (state, {id, tags}) {
    if (tags.length) {
      Vue.set(state.userSettings.tags.fields, id, tags)
    } else {
      Vue.delete(state.userSettings.tags.fields, id)
    }
  },
  setFavoriteState (state, {id, favorited, type}) {
    if (favorited && !state.userSettings.favorites[type].includes(id)) {
      state.userSettings.favorites[type].push(id)
    } else {
      state.userSettings.favorites[type] = state.userSettings.favorites[type].filter(x => x !== id)
    }
  }
}

export default mutations
