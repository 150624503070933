import {makeSetters} from '@helpers/vuex/mutations'

import * as mutations from './mutations'
import * as actions from './actions'

import orderCreation from './order-creation'
import orderPlanning from './order-planning'
import orderExport from './order-export'
import orderImport from './order-import'
import resultImport from './result-import'
import nutrientMaps from './nutrient-maps'
import applicationMaps from './application-maps'
import applicationMapsPreview from './application-maps-preview'

const state = {
  data: {
    nutrientMaps: {},
    fieldStatisticsLookup: {},
    simplifiedGeometries: {}
    // sampling results
    // nutrient maps
    // application maps
  },
  applicationMapCreation: {

  },
  ui: {
    selectedOrder: null, // the current selected order (used for export)
    selectedNutrient: null,
    showClassifications: false,
    showZentroid: false,
    useCustomIds: false
  },
  testing: {
    contentClassResults: []
  }
}

export default {
  namespaced: true,
  state,
  mutations: {
    ...makeSetters([
      'ui.selectedNutrient',
      'ui.showClassifications',
      'ui.showZentroid',
      'ui.useCustomIds',
      'data.fieldStatisticsLookup',
      'data.simplifiedGeometries'
    ]),
    ...mutations
  },
  actions,
  modules: {
    orderCreation,
    orderPlanning,
    orderExport,
    orderImport,
    resultImport,
    nutrientMaps,
    applicationMaps,
    applicationMapsPreview
  }
}
