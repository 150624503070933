import axios from 'axios'

export const setHarvestYear = ({state, commit, dispatch}, harvestYear) => {
  if (harvestYear !== state.userSettings.harvestYear) {
    // optimistic updating
    const previous = state.userSettings.harvestYear
    commit('setUserSettings', {harvestYear})

    return axios.put(`/api/v2/settings/harvest-year/${harvestYear}`)
      .catch(() => {
        commit('setUserSettings', {harvestYear: previous})
      })
  }
}

export const setFabCollapsed = ({state, commit}, fabCollapsed) => {
  if (fabCollapsed !== state.userSettings.fabCollapsed) {
    // optimistic updating
    // const previous = state.userSettings.fabCollapsed
    commit('setUserSettings', {fabCollapsed})

    // For testing

    // const url = '/api/v2/settings/show-field-codes'
    //
    // const method = fabCollapsed ? axios.put : axios.delete
    //
    // return method(url)
    // .catch(() => {
    //   commit('setUserSettings', {fabCollapsed: previous})
    // })
  }
}

export const setFieldTags = ({state, commit}, {id, tags}) => {
  const previous = state.userSettings.tags.fields[id]

  commit('setFieldTags', {id, tags})

  return axios.put(`/api/v2/settings/tags/fields/${id}`, tags)
  .catch(() => {
    commit('setFieldTags', {id, tags: previous})
  })
}

export const toggleFavorited = ({state, commit}, {id, type}) => {
  // optimistic updating
  const previous = state.userSettings.favorites[type].includes(id)
  const favorited = !previous

  commit('setFavoriteState', {id, favorited, type})

  const method = favorited
    ? axios.put
    : axios.delete

  const routeLookup = {
    cropUsages: 'crop-usages'
  }

  return method(`/api/v2/settings/favorites/${routeLookup[type] || type}/${id}`)
  .catch(() => {
    commit('setFavoriteState', {id, favorited: previous, type})
  })
}

export const loadUserSettings = ({commit}) => {
  return axios.get('/api/v2/settings')
  .then(response => response.data)
  .then(settings => {
    for (const key in settings.ui) {
      commit('setUserUiSettings', {flag: key, value: settings.ui[key]})
    }
    commit('setUserSettings', settings)
  })
}

export function loadUserPermissions ({commit}) {
  return axios.get('/api/v2/permissions')
  .then(response => {
    commit('setUserPermissions', response.data, {root: true})
  })
}

export const setUiFlag = ({commit}, {flag, value}) => {
  commit('setUserUiSettings', {flag, value})

  const url = `/api/v2/settings/ui/${flag}`

  return value
    ? axios.put(url)
    : axios.delete(url)
}
