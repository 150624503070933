import axios from 'axios'
import {smarterGet} from '@helpers/vuex/data-loading'
import {can} from 'src/js/helpers/permissions'

export const loadPersons = ({rootGetters, commit}) => {
  const orgUnitId = rootGetters['fieldRecordSystem/orgUnitId']
  const harvestYear = rootGetters['fieldRecordSystem/harvestYear']

  return smarterGet('/api/v2/entities/{orgUnitId}/staff/{harvestYear}', {
    id: 'data.persons',
    expiry: 120,
    inputs: {
      orgUnitId: () => orgUnitId,
      harvestYear: () => harvestYear
    },
    sharedCache: true,
    onResult (data) {
      commit('setPersons', data)
    }
  })
}

export const savePerson = ({state, rootGetters, commit, rootState}) => {
  const orgUnitId = rootGetters['fieldRecordSystem/orgUnitId']
  const {id, name} = state.editBuffer

  if (can(rootState.permissions, 'Use.Frs.Actions')) {
    return axios.post(`/api/v2/entities/${orgUnitId}/staff`, {id, orgUnitId, name})
    .then(({data: person}) => {
      commit('savePerson', person)
    })
  } else {
    return Promise.resolve()
  }
}

export const removePerson = ({rootGetters, commit}, person) => {
  const orgUnitId = rootGetters['fieldRecordSystem/orgUnitId']

  commit('fieldRecordSystem/setDataLoading', true, {root: true})
  return axios.delete(`/api/v2/entities/${orgUnitId}/staff/${person.id}`)
  .then(() => {
    commit('removePerson', person.id)
    commit('fieldRecordSystem/setDataLoading', false, {root: true})
  })
}

export const sharePersonsToChilds = ({rootGetters}) => {
  const orgUnitId = rootGetters['fieldRecordSystem/orgUnitId']
  return axios.post(`/api/v2/entities/${orgUnitId}/staff/share/all`)
  .then(() => {
  })
}

export const startEditing = ({commit}, person) => {
  commit('setEditing', true)
  commit('editBuffer/reset', {person})
}

export const stopEditing = ({commit}) => {
  commit('setEditing', false)
}
