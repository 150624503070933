import {makeSetters} from '@helpers/vuex/mutations'

export default {
  ...makeSetters([
    'productId',
    'cropUsageId',
    'date',
    'type',
    'name',
    'valueKey',
    'timestampKey',
    'unit'
  ]),
  reset (state) {
    state.name = ''
    state.date = null
    state.productId = null
    state.cropUsageId = null
    state.features = null
    state.valueKey = null
    state.timestampKey = null
    state.unit = 'kg/ha'
  },
  importYieldData (state, features) {
    state.features = features
  }
}
