import axios from 'axios'
import {makeSetters} from '@helpers/vuex/mutations'

export default {
  namespaced: true,
  state: {
    fields: []
  },
  mutations: {
    ...makeSetters([
      'fields'
    ])
  },
  actions: {
    loadMigratedFields ({state, commit, rootState}) {
      const harvestYear = rootState.fieldRecordSystem.userSettings.harvestYear
      axios.get(`/api/v2/frs/${harvestYear}/fields/migration`)
      .then(response => response.data)
      .then((fields) => {
        commit('setFields', fields)
      })
    }
  }
}
