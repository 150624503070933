import axios from 'axios'
import moment from 'moment'
import centroid from '@turf/centroid'
import groupBy from 'lodash/groupBy'
import {smarterGet} from '@helpers/vuex/data-loading'

export const loadWeatherData = ({state, commit, rootState}, fieldId) => {
  const {harvestYear} = rootState.fieldRecordSystem.userSettings
  const promise = state.data.field[fieldId].geometry
    ? Promise.resolve(state.data.field[fieldId].geometry)
    : axios.get(`/api/v2/entities/fields/${fieldId}/geodata/${harvestYear}`).then(response => response.data)

  return promise.then(geometry => {
    if (!geometry) return
    const centroidCoordinates = centroid(geometry)
    return getWeatherData(centroidCoordinates.geometry.coordinates)
    .then(climate => {
      commit('updateFieldData', {[fieldId]: {climate}})
    })
  })
}

/*
Weather API Parameter:
  Temperature = 0,
  TemperatureSurface = 1,
  DewpointTemperature = 2,
  RelativeHumidity = 3,
  TotalPrecipitation = 4,
  U = 5,
  V = 6,
  Pressure = 7,
  DownwardShortWaveRadiation = 8,
  UpwardLongWaveRadiation = 9,
  TotalCloudCover = 10,
  LowCloudCover = 11,
  MediumCloudCover = 12,
  HighCloudCover = 13
*/

export function getWeatherData (centroid) {
  const startDate = moment.utc().add(-3, 'days').startOf('day').toISOString()
  const endDate = moment.utc().add(3, 'days').endOf('day').toISOString()
  const data = retrieveWeatherData(startDate, endDate, centroid)
  return data
}

const retrieveWeatherData = (startDate, endDate, centroid) => {
  const parameterNames = ['temperature', 'humidity', 'precipitation', 'totalCloudCover']
  const validTypes = [0, 3, 4, 10]

  const transformWeatherData = data => {
    const getDateString = x => {
      const date = moment(x.date)
      return `${date.year()}-0${date.month() + 1}-${date.date()}`
    }
    const entriesByDay = groupBy(data, getDateString)
    const weatherData = {}

    for (const day in entriesByDay) {
      const entriesByHour = groupBy(entriesByDay[day], x => x.date)

      weatherData[day] = Object.keys(entriesByHour).map(timestamp => {
        const dataForHour = {timestamp}

        validTypes.forEach(type => {
          const entry = entriesByHour[timestamp].find(x => x.type === type)

          dataForHour[parameterIdToName(type)] = entry ? entry.value : null
        })

        if (dataForHour.temperature) {
          dataForHour.temperature -= 273.15 // conversion from Kelvin to Celsius
        }

        return dataForHour
      })
    }

    return weatherData
  }

  return axios.get(buildUrl(parameterNames, startDate, endDate, centroid))
  .then(res => res.data)
  .then(transformWeatherData)
}

const buildUrl = (parameterNames, startDate, endDate, centroid) => {
  const parametersUrl = parameterNames.map(x => `parameters=${parameterNameToId(x)}`).join('&')
  return `/api/Climate/GetParameterValues?${parametersUrl}&lat=${centroid[1]}&lon=${centroid[0]}&startDateTime=${startDate}&endDateTime=${endDate}`
}

const parameterNameToId = (name) => {
  if (name === 'temperature') return 0
  if (name === 'humidity') return 3
  if (name === 'precipitation') return 4
  if (name === 'totalCloudCover') return 10
  throw Error('weather parameter name not found')
}

const parameterIdToName = (id) => {
  if (id === 0) return 'temperature'
  if (id === 3) return 'humidity'
  if (id === 4) return 'precipitation'
  if (id === 10) return 'totalCloudCover'
  throw Error('weather parameter id not found')
}

export async function getClimateData ({state, rootState, rootGetters, commit}) {
  const {harvestYear} = rootState.fieldRecordSystem.userSettings
  const fieldId = rootGetters['fieldRecordSystem/fieldId']
  const geoJson = await axios.get(`/api/v2/entities/fields/${fieldId}/geodata/${harvestYear}`).then(response => response.data)
  const centroidCoordinates = centroid(geoJson)

  return smarterGet('/api/v2/weather/data/{lat}/{lon}', {
    id: 'climate.data.get',
    expiry: 120,
    inputs: {
      fieldId: () => rootGetters['fieldRecordSystem/fieldId'],
      lat: () => centroidCoordinates.geometry.coordinates[1],
      lon: () => centroidCoordinates.geometry.coordinates[0]
    },
    onResult (monthlyClimateData) {
      commit('updateFieldData', {[fieldId]: {monthlyClimateData}})
    }
  })
}
