import {makeSetters} from '@helpers/vuex/mutations'

const defaults = () => ({
  id: null,
  name: '',
  code: '',
  fieldUsage: null,
  area: {
    official: 0,
    cultivated: 0,
    geometrical: 0
  },
  cropRotationId: null,
  waterProtectionZone: 0,
  natura2000: false,
  waterErosion: 0,
  windErosion: false
})

const checkValidity = partialfield => {
  const defaultField = defaults()
  for (const key in partialfield) {
    if (!(key in defaultField)) {
      console.error(`[field editBuffer] set: property '${key}' does not exist in field-details defaults`)
    }
  }
}

export default {
  namespaced: true,
  state: defaults(),
  mutations: {
    ...makeSetters([
      'name',
      'code',
      'fieldUsage',
      'cropRotationId',
      'area.cultivated',
      'area.official',
      'waterProtectionZone',
      'natura2000',
      'waterErosion',
      'windErosion'
    ]),
    reset (state, field) {
      const mappedField = {}
      for (const key in field) {
        if (key in defaults()) {
          mappedField[key] = field[key]
        }
      }

      if (mappedField) {
        checkValidity(mappedField)
      }

      Object.assign(state, defaults(), mappedField)
    }
  }
}
