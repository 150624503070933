import axios from 'axios'

export function reset ({rootState, commit}, fieldId) {
  const soil = rootState.fieldRecordSystem.data.field[fieldId].soil

  const soilDetail = {
    id: fieldId,
    soil: {
      soilQuality: soil.soilQuality,
      soilSubType: soil.soilSubType,
      soilType: soil.soilType,
      usableFieldCapacity: soil.usableFieldCapacity
    }
  }

  commit('editBuffer/reset', {soilDetail})
}

export async function saveSoilData ({state, dispatch, commit, rootState}) {
  const rootCommit = (name, payload) => commit(name, payload, {root: true})

  const {fieldId} = rootState.fieldRecordSystem.navigation.location
  const {harvestYear} = rootState.fieldRecordSystem.userSettings

  const oldSoil = rootState.fieldRecordSystem.data.field[fieldId].soil

  const soil = Object.assign({}, state.editBuffer.soil)
  commit('fieldRecordSystem/updateFieldData', {[fieldId]: {soil}}, {root: true})

  soil.id = fieldId
  const serverContext = rootState.fieldRecordSystem.data.field[fieldId].context

  const context = {
    ...serverContext,
    payload: soil
  }

  try {
    await axios.put(`/api/v2/frs/${harvestYear}/fields/${fieldId}/data/soil`, context)

    rootCommit('dataLoading/invalidate', 'harvestYear.fullFieldData')
  } catch (error) {
    rootCommit('fieldRecordSystem/updateFieldData', {[fieldId]: {soil: oldSoil}})
    throw error
  }
}
