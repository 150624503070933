import axios from 'axios'

import {toWkt} from '@helpers/openlayers/features'
import {makeSetters} from '@helpers/vuex/mutations'

import {processProbingGeometry} from './process-probing-geometry'

const uploadDefaults = () => ({
  shapeFileFeatures: [],
  columnMapping: {
    sampleId: null
  }
})

export default {
  namespaced: true,
  state: {
    upload: uploadDefaults()
  },
  getters: {
    allSampleIdsAreValid (state) {
      return state.upload.shapeFileFeatures.every(feature => feature.get(state.upload.columnMapping.sampleId))
    },
    sampleIdPropertyExists (state) {
      return state.upload.shapeFileFeatures.some(feature => feature.getKeys().includes(state.upload.columnMapping.sampleId))
    },
    isValid (state, getters) {
      return state.upload.shapeFileFeatures.length && getters.allSampleIdsAreValid && !getters.hasMultiPointGeometries
    },
    validSampleIdColumn (state, getters) {
      return getters.shapeFileColumns.includes(state.upload.columnMapping.sampleId)
    },
    shapeFileColumns (state) {
      if (!state.upload.shapeFileFeatures.length) return []

      return Object.keys(state.upload.shapeFileFeatures[0].getProperties())
    },
    hasMultiPointGeometries (state) {
      return state.upload.shapeFileFeatures.some(feature => feature.getGeometry().getType() === 'MultiPoint')
    }
  },
  mutations: {
    ...makeSetters([
      'upload.columnMapping.sampleId',
      'upload.shapeFileFeatures'
    ]),
    reset (state) {
      state.upload = uploadDefaults()
    }
  },
  actions: {
    importOrder ({state, rootState, dispatch, rootGetters}, {features, name}) {
      const orgUnitId = rootGetters['fieldRecordSystem/orgUnitId']
      const geometries = features.map((feature, i) => ({
        geometry: toWkt(processProbingGeometry(feature)),
        sampleId: feature.get(state.upload.columnMapping.sampleId) || i + 1,
        fieldName: feature.get(state.upload.columnMapping.fieldName) || ''
      }))

      const order = {
        selectedFieldIds: rootState.fieldRecordSystem.basicFertilization.orderCreation.order.selectedFieldIds,
        geometries,
        name
      }

      return axios.post(`/api/v2/base-fertilization/orgunits/${orgUnitId}/import-order`, order)
      .then(response => response.data)
      .then(samplingOrderId => {
        return dispatch('fieldRecordSystem/basicFertilization/resultImport/startManualEntry', samplingOrderId, {root: true})
      })
    }
  }
}
