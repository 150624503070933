import {makeSetters} from '@helpers/vuex/mutations'

const defaults = () => ({
  id: null,
  orgUnitId: null,
  name: ''
})

const checkValidity = partialPerson => {
  const defaultPerson = defaults()
  for (const key in partialPerson) {
    if (!(key in defaultPerson)) {
      console.error(`[person editBuffer] set: property '${key}' does not exist in person defaults`)
    }
  }
}

export default {
  namespaced: true,
  state: defaults(),
  mutations: {
    ...makeSetters([
      'name'
    ]),
    set (state, partialPerson) {
      checkValidity(partialPerson)
      Object.assign(state, partialPerson)
    },
    reset (state, {person}) {
      if (person) {
        checkValidity(person)
        Object.assign(state, person)
      } else {
        Object.assign(state, defaults())
      }
    }
  }
}
