import * as actions from './actions'
import editBuffer from './editBuffer'

const state = {

}

export default {
  namespaced: true,
  state,
  actions,
  modules: {
    editBuffer
  }
}
