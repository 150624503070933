import * as getters from './getters'
import * as actions from './actions'
import mutations from './mutations'

export const defaults = () => ({
  orgUnitName: null,
  cultivations: [],
  fields: [],
  selected: [],
  visible: [],
  filterRules: []
})

export default {
  namespaced: true,
  state: defaults(),
  getters,
  actions,
  mutations
}
