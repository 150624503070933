const normalFactors = {
  phosphorus: 0.4364253538112,
  magnesium: 0.603136308858,
  potassium: 0.8298755186722
}

const oxideFactors = {
  phosphorus: 2.291342588755,
  magnesium: 1.6580,
  potassium: 1.2050
}

export const calculateToOxidform = (nutrient, value) => {
  return oxideFactors[nutrient] * value
}

export const calculateFromOxidform = (nutrient, value) => {
  return normalFactors[nutrient] * value
}

export const oxideNames = {
  potassium: 'K₂O',
  phosphorus: 'P₂O₅',
  magnesium: 'MgO'
}
