import {makeSetters} from '@helpers/vuex/mutations'

import mutations from './mutations'
import * as actions from './actions'

export default {
  namespaced: true,
  state: {
    bing: {
      visible: 'hybrid'
    },
    showDefaultLayers: true,
    defaultInteractionsEnabled: true,
    autoFit: false,
    focusExtent: [],
    layers: {},
    layerList: [],
    clipboard: [],
    editing: {
      active: false,
      mode: 'edit',
      flavor: 'quick', // or expert
      abortEditing: false,
      component: null,
      undoBuffer: [],
      redoBuffer: [],
      originalStyles: {},
      activeFeatures: [],
      guideFeatures: [],
      selectedIds: [],
      sharedPlugins: null,
      validateWhileChange: true,
      snapping: true,
      autoFit: false,
      roads: false,
      showImportSimplificationHint: false,
      showGeometryErrors: true
    },
    fab: { // floating action button .... it's FAB-ulous
      position: null
    },
    borderRecognition: {
      active: false
    }
  },
  getters: {
    selectedFeatures (state) {
      return state.editing.selectedIds.map(id => state.editing.activeFeatures.find(x => x.properties.id === id))
    }
  },
  mutations: {
    setBorderRecognitionStatus (state, value) {
      state.borderRecognition.active = value
    },
    ...makeSetters([
      'editing.validateWhileChange',
      'editing.snapping',
      'editing.autoFit',
      'editing.roads',
      'editing.showImportSimplificationHint',
      'editing.flavor',
      'editing.showGeometryErrors',
      'showDefaultLayers',
      'defaultInteractionsEnabled'
    ]),
    ...mutations
  },
  actions
}
