import axios from 'axios'
import {makeSetters} from '@helpers/vuex/mutations'

export default {
  namespaced: true,
  state: {
    order: null
  },
  mutations: {
    createNewOrder (state) {
      state.order = {
        name: '',
        selectedFieldIds: []
      }
    },
    ...makeSetters([
      'order.name',
      'order.selectedFieldIds'
    ]),
    setOrderName (state, name = '') {
      state.order.name = name
    }
  },
  actions: {
    startOrderCreation ({commit}) {
      commit('createNewOrder')
      commit('fieldRecordSystem/setRightView', 'samplingOrderCreation', {root: true})
    },
    saveOrder ({state, rootGetters, dispatch}, continueToNextStep) {
      const orgUnitId = rootGetters['fieldRecordSystem/orgUnitId']

      return axios.post(`/api/v2/base-fertilization/orgunits/${orgUnitId}/orders`, state.order)
      .then(response => response.data)
      .then(({id}) => {
        if (continueToNextStep) {
          return dispatch('fieldRecordSystem/basicFertilization/orderPlanning/startOrderPlanning', id, {root: true})
        } else {
          return dispatch('fieldRecordSystem/basicFertilization/startBasicFertilization', null, {root: true})
        }
      })
    }
  }
}
