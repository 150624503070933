import mutations from './mutations'
import * as actions from './actions'
import saveAction from './save-action'
import startEditingAction from './edit-action'
import * as getters from './getters'

import editBuffer from './edit-buffer'

import {actionHierarchy} from './action-hierarchy'

const state = {
  ui: {
    selection: {
      actionId: null,
    },
    planningWizard: {
      step: null,
      parameterStep: null,
      mode: null,
      partEditingIndex: null,
    },
  },
  data: {
    actions: {
      // TODO refactor, see ASK-2138
      forField: [],
      // TODO refactor, see ASK-2138
      forOrgUnit: [],
      templatesForOrgUnit: [],
    },
    editing: {
      actionHierarchy,
      // TODO refactor, see ASK-2138
      actionsByField: {},
      cultivationsByField: {},
    },
    planningWizard: {
      combinationParts: [],
    },
  },
  cultivationsForOrgUnit: {},
  navigationSource: null,
}

export default {
  namespaced: true,
  state,
  mutations,
  actions: {
    ...actions,
    saveAction,
    startEditingAction,
  },
  getters,
  modules: {
    editBuffer,
  },
}
