import axios from 'axios'

import {smarterGet} from '@helpers/vuex/data-loading'
import {can} from 'src/js/helpers/permissions'

export * from './wizard-actions'

export const reloadActionData = ({commit, dispatch}) => {
  commit('fieldRecordSystem/setDataLoading', true, {root: true})

  return Promise.all([
    dispatch('fieldRecordSystem/machines/loadMachines', null, {root: true}),
    dispatch('fieldRecordSystem/persons/loadPersons', null, {root: true}),
    dispatch('loadCultivationsForOrgUnit', null),
    dispatch('masterData/reloadCropUsages', null, {root: true}),
    dispatch('masterData/reloadCropHarvestQualities', null, {root: true}),
    dispatch('fieldRecordSystem/loadFieldGeometriesForCurrentOrgUnit', null, {
      root: true,
    }),
  ]).then(() => {
    commit('fieldRecordSystem/setDataLoading', false, {root: true})
  })
}

// TODO refactor, see ASK-2138
export const loadActionsForOrgUnit = ({rootGetters, commit, rootState}) => {
  const orgUnitId = rootGetters['fieldRecordSystem/orgUnitId']
  const harvestYear = rootGetters['fieldRecordSystem/harvestYear']

  if (can(rootState.permissions, 'Use.Frs.Actions')) {
    return smarterGet(['/api/v2/{harvestYear}/org/{orgUnitId}/actions'], {
      id: 'data.orgUnit.actions',
      expiry: 0,
      inputs: {
        orgUnitId: () => orgUnitId,
        harvestYear: () => harvestYear,
      },
      sharedCache: true,
      onResult([data]) {
        commit('setActionsForOrgUnit', data)
      },
    })
  } else {
    Promise.resolve()
  }
}

// TODO refactor, see ASK-2138
export function loadActionsForCurrentField({rootGetters, commit}) {
  const fieldId = rootGetters['fieldRecordSystem/fieldId']
  const harvestYear = rootGetters['fieldRecordSystem/harvestYear']

  return smarterGet(['/api/v2/{harvestYear}/fields/{fieldId}/actions'], {
    id: 'data.field.actions',
    expiry: 0, // when creating actions make sure the action is displayed immediately // TODO refactor saving of actions to update client-side on its own, making caching here possible
    inputs: {
      fieldId: () => fieldId,
      harvestYear: () => harvestYear,
    },
    sharedCache: true,
    onResult([data]) {
      // NOTE not to be confused with setActionsByField used below in loadActionsForCurrentField
      commit('setActionsForField', data)
    },
  })
}

// TODO refactor, see ASK-2138
export function loadActionsForFieldForPlanning({commit, rootState}, fieldId) {
  return smarterGet(['/api/v2/{harvestYear}/fields/{fieldId}/actions'], {
    id: 'data.field.actions',
    expiry: 10,
    inputs: {
      fieldId: () => fieldId,
      harvestYear: () => rootState.fieldRecordSystem.userSettings.harvestYear,
    },
    onResult([actions]) {
      // NOTE not to be confused with setActionsForField used above in loadActionsForField
      commit('setActionsByField', {fieldId, actions})
    },
  })
}

export const startEditingCombinationPart = ({commit}, {part, index}) => {
  // const clone = JSON.parse(JSON.stringify(part))
  // const modifiedAction = {
  //   ...clone,
  //   timestamps: state.editBuffer.timestamps,
  //   fieldIds: state.editBuffer.fieldIds,
  //   combinationActionWasEdited: true,
  //   indexFromAction: index
  // }
  const {id, ...rest} = part

  commit('editBuffer/set', rest)
  commit('setPartEditingIndex', index)
  commit('setWizardStep', 'type')
  // return dispatch('reloadActionData')
  // .then(() => {
  //   commit('editBuffer/reset', modifiedAction)
  //   commit('setWizardStep', 'type')
  // })
}

export async function startCreatingActionFromField({dispatch}) {
  dispatch('startEditingAction', {
    navigationSource: {
      saveTarget: 'actionDisplayField',
      cancelTarget: 'default',
    },
  })
}

export function startEditingActionGeometry({state, getters, dispatch}) {
  const fieldId = state.editBuffer.fieldIds[0]
  const feature = getters['editBuffer/finalActionFeatures'][fieldId]

  const features = feature ? [feature] : []

  return dispatch('fieldRecordSystem/map/startExpertMode', features, {
    root: true,
  })
}

export const cancelEditing = ({state, commit}) => {
  commit('editBuffer/reset')
  commit('setWizardStep', null)

  const {cancelTarget} = state.navigationSource
  commit('fieldRecordSystem/setRightView', cancelTarget, {root: true})
}

export const showActionDetails = ({commit}, actionId) => {
  commit('setActiveActionId', actionId)
  commit('fieldRecordSystem/setRightView', 'actionDetailsPage', {root: true})
}

export const saveModifiedActionStatus = (
  {state, commit, rootGetters},
  {actions, newState, forOrgUnit}
) => {
  const orgUnitId = rootGetters['fieldRecordSystem/orgUnitId']
  const harvestYear = rootGetters['fieldRecordSystem/harvestYear']
  if (forOrgUnit) {
    actions.forEach(action => {
      commit('setActionStateForOrgUnit', {action, newState})
    })
  } else {
    actions.forEach(action => {
      commit('setActionStateForField', {action, newState})
    })
  }

  const stateActions = forOrgUnit ? state.data.actions.forOrgUnit : state.data.actions.forField
  const actionsToUpdate = stateActions.filter(existing => actions.some(x => x.id === existing.id))
  return axios.post(`/api/v2/${harvestYear}/orgs/${orgUnitId}/actions`, actionsToUpdate)
}

export const removeActions = ({commit, dispatch}, actions) => {
  commit('fieldRecordSystem/setDataLoading', true, {root: true})
  const actionIds = actions.map(x => x.id)
  return axios
    .post('/api/v2/actions/delete', actionIds)
    .then(() => {
      dispatch('loadActionsForOrgUnit')
    })
    .then(() => {
      commit('fieldRecordSystem/setDataLoading', false, {root: true})
    })
}

export const loadCultivationsForOrgUnit = ({rootState, commit}) => {
  if (can(rootState.permissions, 'Use.Frs.Cultivations')) {
    return smarterGet(
      [
        '/api/v2/entities/orgunits/{orgUnitId}/cultivations/{harvestYear}',
        '/api/v2/entities/orgunits/{orgUnitId}/cultivations/fields/{harvestYear}',
      ],
      {
        id: 'orgUnit.action.cultivations',
        expiry: 10,
        inputs: {
          orgUnitId: () =>
            rootState.fieldRecordSystem.navigation.location.orgUnitId,
          harvestYear: () =>
            rootState.fieldRecordSystem.userSettings.harvestYear,
        },
        sharedCache: true,
        onResult([cultivations, fieldsUnderCultivations]) {
          cultivations.forEach(cultivation => {
            cultivation.fieldIds = fieldsUnderCultivations[cultivation.id]
              ? fieldsUnderCultivations[cultivation.id]
              : []
          })
          commit('setCultivationsForOrgUnit', cultivations)
        },
      }
    )
  } else {
    return Promise.resolve()
  }
}
