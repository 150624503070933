import Vue from 'vue'
import moment from 'moment'
import {mapValues, forIn, flatten, fromPairs} from 'lodash'

import {procedureParameterDefaults, procedures} from '../action-hierarchy'
import {makeSetters} from '@helpers/vuex/mutations'

import * as geometryGetters from './geometry-getters'
import * as selectionGetters from './selection-getters'

const defaults = () => ({
  id: null,
  timestamps: {
    withTime: false, // wether the planned timestamps is used with or without time needs to be stored in the state
    // since the summary wizard page needs to display the timestamps accordingly
    planned: {
      start: null,
      end: null
    },
    actual: {
      start: null,
      end: null
    }
  },
  fieldIds: [], // single value for final action, but planning can happen for multiple fields at once

  // cultivation/geometry data
  selectedCultivationIds: {}, // {[cultivationId]: fieldId} // TODO rename later
  remainingFieldSelected: {}, // {[fieldId]: true|false}
  customGeometries: {}, // {[fieldId]: geoJsonGeometry}

  // combination sub wizard data
  type: null,
  procedure: null,
  details: {}, // {[fieldId]: {parameters, productQuantities}}
  parameters: {},
  // productQuantities: [], // Array<object> with subschema `{productId: "", amount: 0, unit: ""}`

  personnel: [], // Array<Guid>
  machines: [], // Array<Guid>
  notes: '',
  // combinationActionWasEdited: false,
  // indexFromAction: null,
  // instanceId: null,
  harvestYear: 0
})

function resetDetails (state) {
  state.details = state.fieldIds.reduce((detailsLookup, fieldId) => {
    const defaults = procedureParameterDefaults(state.type, state.procedure)
    const currentParameters =
      state.details[fieldId]
        ? state.details[fieldId].parameters
        : state.parameters
          ? state.parameters
          : {}
    const productQuantities =
      state.details[fieldId]
        ? state.details[fieldId].productQuantities
        : state.productQuantities
          ? state.productQuantities
          : []

    const parameters = Object.assign({}, defaults, currentParameters)

    for (let key in parameters) {
      if (!defaults.hasOwnProperty(key)) {
        delete parameters[key]
      }
    }

    detailsLookup[fieldId] = {
      parameters,
      productQuantities
    }
    return detailsLookup
  }, {})
}

function setType (state, type) {
  state.type = type

  state.procedure = procedures(type)[0] || null
  resetDetails(state)
}

export default {
  namespaced: true,
  state: defaults(),
  getters: {
    ...geometryGetters,
    ...selectionGetters,
    valid (state) {
      // return executeRules(state, rules)
      return true && state
    },
    hasStarted: state => !!state.timestamps.started && !state.timestamps.completed,
    isPlanned: state => !state.timestamps.started,
    isCompleted: state => !!state.timestamps.completed,
    relevantCultivationsByFieldId (state, getters, rootState, rootGetters) {
      const cultivationsByFieldId = rootGetters['fieldRecordSystem/action/cultivationsByFieldId']
      const fieldId = rootGetters['fieldRecordSystem/fieldId']

      // TODO filter by referencing cultivation.dateTimeRange with action.dateTimeRange
      const lookup = fieldId
        ? {
          [fieldId]: cultivationsByFieldId[fieldId]
        }
        : mapValues(cultivationsByFieldId, (cultivations, fieldId) => {
          return cultivations
        })

      if (fieldId) {
        if (!lookup[fieldId]) {
          lookup[fieldId] = []
        }
      } else {
        rootGetters['fieldRecordSystem/fieldsForCurrentOrgUnit']
          .forEach(field => {
            if (!lookup[field.id]) {
              lookup[field.id] = []
            }
          })
      }

      return lookup
    },
    selectedCultivationIdsByFieldId (state) {
      const lookup = {}

      state.fieldIds.forEach(fieldId => {
        lookup[fieldId] = []
      })

      forIn(state.selectedCultivationIds, (fieldId, cultivationId) => {
        lookup[fieldId].push(cultivationId)
      })

      return lookup
    },
    cultivationDateValidityLookup (state, getters, rootState, rootGetters) {
      const cultivations = flatten(Object.values(rootGetters['fieldRecordSystem/action/cultivationsByFieldId']))

      const {start, end} = state.timestamps.planned
      const [min, max] = [
        start ? moment(start).valueOf() : -Infinity,
        end ? moment(end).valueOf() : Infinity
      ]

      return fromPairs(cultivations.map(cultivation => {
        const {start: cultivationStart, end: cultivationEnd} = cultivation.dateTimeRange
        const [a, b] = [cultivationStart, cultivationEnd].map(x => moment(x).valueOf())

        return [
          cultivation.id,
          (a >= min || b >= min) && (a <= max || b <= max)
        ]
      }))
    }
  },
  mutations: {
    ...makeSetters([
      'notes',
      'details',
      'timestamps.withTime'
    ]),
    setType,
    resetDetails,
    partialReset (state) {
      state.type = null
      state.procedure = null
      state.parameters = {}
    },
    toggleFieldId (state, {fieldId, relevantCultivationIds}) {
      if (!state.fieldIds.includes(fieldId)) {
        state.fieldIds.push(fieldId)
        Vue.set(state.remainingFieldSelected, fieldId, true)
        Vue.set(state.customGeometries, fieldId, null)
        relevantCultivationIds.forEach(cultivationId => {
          Vue.set(state.selectedCultivationIds, cultivationId, fieldId)
        })
      } else {
        state.fieldIds = state.fieldIds.filter(x => x !== fieldId)
        Object.keys(state.selectedCultivationIds)
          .filter(cultivationId => state.selectedCultivationIds[cultivationId] === fieldId)
          .forEach(cultivationId => {
            Vue.delete(state.selectedCultivationIds, cultivationId)
          })
        Vue.delete(state.remainingFieldSelected, fieldId)
        Vue.delete(state.customGeometries, fieldId)
      }
    },
    setCustomGeometry (state, {fieldId, geometry}) {
      if (!state.fieldIds.includes(fieldId)) throw new Error(`fieldId ${fieldId} is not selected, cannot set geometry`)

      state.customGeometries[fieldId] = geometry

      if (geometry) {
        Object.keys(state.selectedCultivationIds)
          .filter(cultivationId => state.selectedCultivationIds[cultivationId] === fieldId)
          .forEach(cultivationId => {
            Vue.delete(state.selectedCultivationIds, cultivationId)
          })
        state.remainingFieldSelected[fieldId] = false
      }
    },
    toggleCultivationId (state, {fieldId, cultivationId}) {
      if (!state.fieldIds.includes(fieldId)) throw new Error(`fieldId ${fieldId} is not selected, cannot toggle cultivation`)

      if (!cultivationId) {
        state.remainingFieldSelected[fieldId] = !state.remainingFieldSelected[fieldId]
        if (state.remainingFieldSelected[fieldId]) {
          state.customGeometries[fieldId] = null
        }
      } else {
        if (state.selectedCultivationIds[cultivationId] === fieldId) {
          Vue.delete(state.selectedCultivationIds, cultivationId)
        } else {
          Vue.set(state.selectedCultivationIds, cultivationId, fieldId)
        }
        if (state.selectedCultivationIds[cultivationId] === fieldId) {
          state.customGeometries[fieldId] = null
        }
      }
    },
    setPlannedStart (state, isoDate) {
      state.timestamps.planned.start = isoDate
    },
    setPlannedEnd (state, isoDate) {
      state.timestamps.planned.end = isoDate
    },
    setActualStart (state, isoDate) {
      state.timestamps.actual.start = isoDate
    },
    setActualEnd (state, isoDate) {
      state.timestamps.actual.end = isoDate
    },
    setProcedure (state, procedure) {
      state.procedure = procedure

      resetDetails(state)
    },
    setMachineIds (state, machines) {
      state.machines = machines
    },
    setPersonnelIds (state, personnel) {
      state.personnel = personnel
    },
    set (state, partialAction) {
      const defaultAction = defaults()
      for (const key in partialAction) {
        if (!(key in defaultAction)) {
          console.error(`[action editBuffer] set: property '${key}' does not exist in action defaults`)
        }
      }

      Object.assign(state, partialAction)

      if (partialAction.fieldIds) {
        resetDetails(state)
      }
    },
    reset (state, action) {
      if (action) {
        throw new Error('deprecated parameter `action` should not be used')
      }
      Object.assign(state, defaults())
    }
  },
  actions: {
    toggleCultivationId ({state, getters, rootGetters, commit, dispatch}, {fieldId, cultivationId}) {
      const isFieldLevel = rootGetters['fieldRecordSystem/fieldId']

      if (isFieldLevel) {
        commit('toggleCultivationId', {fieldId, cultivationId})
        return
      }

      if (!state.fieldIds.includes(fieldId)) {
        dispatch('toggleFieldId', fieldId)
        commit('toggleCultivationId', {fieldId, cultivationId: null})
        getters.relevantCultivationsByFieldId[fieldId]
          .map(x => x.id)
          .filter(x => x !== cultivationId)
          .forEach(cultivationId => {
            commit('toggleCultivationId', {fieldId, cultivationId})
          })
      } else {
        commit('toggleCultivationId', {fieldId, cultivationId})
      }

      const atLeastOneCultivationSelected = getters.relevantCultivationsByFieldId[fieldId].some(cultivation => state.selectedCultivationIds[cultivation.id] === fieldId)
      const remainingFieldSelected = getters.remainingFieldFeatures[fieldId] && state.remainingFieldSelected[fieldId]

      if (!(atLeastOneCultivationSelected || remainingFieldSelected)) {
        const relevantCultivationIds = getters.relevantCultivationsByFieldId[fieldId].map(x => x.id)
        commit('toggleFieldId', {fieldId, relevantCultivationIds})
      }
    },
    toggleFieldId ({state, getters, commit, dispatch, rootState}, fieldId) {
      const rootDispatch = (action, payload) => dispatch(action, payload, {root: true})

      if (rootState.fieldRecordSystem.action.ui.planningWizard.step !== 'start') throw new Error('cannot toggle fields outside of start step')

      const relevantCultivationIds = getters.relevantCultivationsByFieldId[fieldId].map(x => x.id)
      commit('toggleFieldId', {fieldId, relevantCultivationIds})

      const selected = state.fieldIds.includes(fieldId)

      if (selected) {
        return rootDispatch('fieldRecordSystem/action/loadActionsForFieldForPlanning', fieldId)
      }
    },
    toggleAll ({state, getters, rootGetters, dispatch}) {
      const fieldId = rootGetters['fieldRecordSystem/fieldId']

      if (!fieldId) throw new Error('only works at field level')

      const {everythingSelected, hasRemainingField, remainingFieldSelected} = getters.selectionSummaryByFieldId[fieldId]

      const cultivationIds = getters.relevantCultivationsByFieldId[fieldId]
        .filter(cultivation => {
          const isSelected = state.selectedCultivationIds[cultivation.id] === fieldId

          return everythingSelected === isSelected
        })
        .map(x => x.id)

      if (hasRemainingField && remainingFieldSelected === everythingSelected) {
        cultivationIds.push(null)
      }

      return Promise.all([
        cultivationIds.forEach(cultivationId => {
          dispatch('toggleCultivationId', {fieldId, cultivationId})
        })
      ])
    }
  }
}
