export const showCultivationPlan = ({commit}) => {
  commit('fieldRecordSystem/setRightView', 'cultivationPlan', {root: true})
}

export const cultivationPlanningOrgUnit = ({dispatch, commit}, {cultivationId, fieldId}) => {
  dispatch('fieldRecordSystem/cultivationPlanning/editCultivationOrgUnit', {cultivationId: cultivationId, fieldId: fieldId}, {root: true})
  if (cultivationId) {
    commit('fieldRecordSystem/setRightView', 'editCultivationPlanningOrgUnit', {root: true})
  } else {
    commit('fieldRecordSystem/setRightView', 'cultivationPlanningOrgUnit', {root: true})
  }
}

export const showActionOverview = ({commit}) => {
  commit('fieldRecordSystem/setRightView', 'actionOverview', {root: true})
}

export const showAnnualPlan = ({commit}) => {
  commit('fieldRecordSystem/setRightView', 'annualPlan', {root: true})
}

export const showAnnualPlanDiff = ({commit}) => {
  commit('fieldRecordSystem/setRightView', 'annualPlanDiff', {root: true})
}

export const showCropRotation = ({commit}) => {
  commit('fieldRecordSystem/setRightView', 'cropRotation', {root: true})
}

export const showMachinesOverview = ({commit}) => {
  commit('fieldRecordSystem/setRightView', 'machinesOverview', {root: true})
}

export const showPersonsOverview = ({commit}) => {
  commit('fieldRecordSystem/setRightView', 'personsOverview', {root: true})
}

export const showCompanyNumbers = ({commit}) => {
  commit('fieldRecordSystem/setRightView', 'companyNumbers', {root: true})
}

export const showCompanyLanePlans = ({commit, dispatch}) => {
  commit('fieldRecordSystem/setRightView', 'lanePlanSelectionOrgUnit', {root: true})
}

export const showDefaultYield = ({commit}) => {
  commit('fieldRecordSystem/setRightView', 'defaultYields', {root: true})
}
