import {makeSetters} from 'src/js/helpers/vuex/mutations'

export default {
  ...makeSetters([
    'data.crops',
    'data.soilTypes',
    'data.soilSubTypes',
    'data.usageTypes',
    'data.legalForms',
    'data.companyForms',
    'data.waterExtractionSources'
  ])
}
