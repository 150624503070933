import {can} from '@helpers/permissions'
import {smarterGet, smarterDelete} from '@helpers/vuex/data-loading'

export function loadPrerequisiteData ({dispatch, rootState}) {
  if (can(rootState.permissions, 'Use.Frs.Cultivations')) {
    return Promise.all([
      dispatch('fieldRecordSystem/machines/loadMachines', null, {root: true}),
      dispatch('fieldRecordSystem/persons/loadPersons', null, {root: true}),
      dispatch('loadCultivations')
    ])
  } else {
    return Promise.resolve()
  }
}

export function loadCultivations ({commit, rootState}) {
  return smarterGet([
    '/api/v2/{harvestYear}/org/{orgUnitId}/cultivations'
  ], {
    id: 'orgUnit.cultivations.loadCultivations',
    inputs: {
      orgUnitId: () => rootState.fieldRecordSystem.navigation.location.orgUnitId,
      harvestYear: () => rootState.fieldRecordSystem.userSettings.harvestYear
    },
    sharedCache: true,
    onResult ([data]) {
      commit('removeUnknownSelected', data.cultivations)
      commit('initializeCultivations', data.cultivations)
      commit('setFields', data.fields)
      commit('setOrgUnitName', data.orgUnitName)
    }
  })
}

export function deleteCultivation ({commit, dispatch}, cultivationId) {
  return smarterDelete('/api/v2/cultivations/{cultivationId}', {
    id: 'orgUnit.cultivationsV2.delete',
    inputs: {
      cultivationId: () => cultivationId
    }
  }).then(() => {
    commit('toggleCultivation', cultivationId)
    dispatch('loadPrerequisiteData')
  })
}

export function acceptAndToggleFilterRule ({commit}, rule) {
  let ruleAccepted = true
  if (!rule.hasOwnProperty('property')) {
    console.error('Rule property \'property\' is missing')
    ruleAccepted = false
  }
  if (!rule.hasOwnProperty('filter')) {
    console.error('Rule property \'filter\' is missing')
    ruleAccepted = false
  }
  if (!rule.hasOwnProperty('operation')) {
    console.error('Rule property \'operation\' is missing')
    ruleAccepted = false
  }

  if (ruleAccepted) {
    commit('toggleRule', rule)
  }
}

export function toggleCultivation ({state, commit, dispatch}, cultivationId) {
  commit('toggleCultivation', cultivationId)
  const features = state.cultivations.filter(feature => state.selected.includes(feature.properties.id))
  return dispatch('fieldRecordSystem/map/focus', features, {root: true})
}
