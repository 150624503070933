import axios from 'axios'
import {smarterGet} from 'src/js/helpers/vuex/data-loading'
import {can} from 'src/js/helpers/permissions'

// root
export const addCropRotation = ({state, commit}, cropRotation) => {
  const orgUnitId = state.navigation.location.orgUnitId
  return axios.post(`/api/v2/entities/orgunits/${orgUnitId}/crop-rotations`, cropRotation)
  .then((request) => request.data)
  .then((id) => {
    cropRotation.id = id
    commit('addCropRotation', cropRotation)
  })
}

export const updateCropRotation = ({state, commit}, cropRotation) => {
  const orgUnitId = state.navigation.location.orgUnitId
  return axios.post(`/api/v2/entities/orgunits/${orgUnitId}/crop-rotations`, cropRotation)
  .then((request) => request.data)
  .then(() => {
    if (state.navigation.location.orgUnitId === orgUnitId) {
      commit('updateCropRotation', cropRotation)
    }
  })
}

export const deleteCropRotation = ({state, commit}, {id}) => {
  const orgUnitId = state.navigation.location.orgUnitId
  return axios.delete(`/api/v2/entities/orgunits/${orgUnitId}/crop-rotations/${id}`)
  .then(() => {
    commit('removeCropRotation', id)
  })
}

export const reloadCropRotations = ({commit, getters, rootState}) => {
  const rootOrgUnitId = getters['navigation/rootOrgUnitId']

  if (!rootOrgUnitId) return

  if (can(rootState.permissions, 'Use.Frs.Cultivations')) {
    return smarterGet([
      '/api/v2/entities/orgunits/{rootId}/crop-rotations'
    ], {
      id: 'reload.cropRotation',
      expiry: 120,
      inputs: {
        rootId: () => rootOrgUnitId
      },
      sharedCache: true,
      onResult ([rootRotations]) {
        if (getters['navigation/rootOrgUnitId'] === rootOrgUnitId) {
          commit('setRootCropRotations', rootRotations)
        }
      }
    })
  } else {
    return Promise.resolve()
  }
}

// reloads the assigned crop rotations when user navigates to different org unit
export const reloadAssignedCropRotations = ({state, commit, rootState}) => {
  const orgUnitId = state.navigation.location.orgUnitId

  if (!orgUnitId) return
  if (can(rootState.permissions, 'Use.Frs.Cultivations')) {
    return smarterGet([
      '/api/v2/entities/orgunits/{orgUnitId}/crop-rotations/active'
    ], {
      id: 'reload.cropRotation.assigned',
      expiry: 120,
      inputs: {
        orgUnitId: () => orgUnitId
      },
      sharedCache: true,
      onResult ([assignedRotations]) {
        if (state.navigation.location.orgUnitId === orgUnitId) {
          commit('setAssignedCropRotationsForOrgUnit', assignedRotations)
        }
      }
    })
  } else {
    return Promise.resolve()
  }
}

export const saveAssignedCropRotations = ({state, commit}, cropRotations) => {
  // maybe add "hasChanges" flag somewhere later and set to false on resolve
  const orgUnitId = state.navigation.location.orgUnitId
  return axios.put(`/api/v2/entities/orgunits/${orgUnitId}/crop-rotations/active`, cropRotations.map(x => x.id))
  .then(() => {
    if (state.navigation.location.orgUnitId === orgUnitId) {
      commit('setAssignedCropRotationsForOrgUnit', cropRotations)
    }
  })
}
