export function setFields (state, fields) {
  state.data.fields = fields
}

export function setEditing (state, value) {
  state.ui.editing = value
}

export function upsertField (state, field) {
  const existing = state.data.fields.find(f => f.id === field.id)
  if (existing) {
    existing.name = field.name.trim()
  } else {
    state.data.fields.push(field)
  }
}
