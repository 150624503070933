import {pick} from 'lodash'
import {makeSetters} from '@helpers/vuex/mutations'
import {smarterGet, smarterPost} from '@helpers/vuex/data-loading'

import {makeTemplate, commonParameters, validUnits, convertApplicationValues} from './algorithm-definitions'
import moment from 'moment'

const applicationMapsDataDefaults = () => ({
  applicationMapCalculated: false,
  applicationMapCreationFailed: false,
  applicationMapId: null
})

export default {
  namespaced: true,
  state: {
    parameters: null,
    ui: {
      page: 'parameters', // 'parameters' or 'preview'
      algorithmCollection: null
    },
    // old post-creation page stuff
    data: applicationMapsDataDefaults()
  },
  mutations: {
    ...makeSetters([
      'ui.algorithmCollection',
      'parameters.name',
      'parameters.productId',
      'parameters.unit',
      'parameters.applicationValues',
      'parameters.zoneMapId',
      'parameters.cultivationId',
      // algorithm-specific mutations
      // underroot fertilization
      'parameters.temperature',
      // winter wheat
      'parameters.type',
      'parameters.dateOfSeed',
      'parameters.thousandKernelWeight',
      'parameters.germinationCapacity',
      'parameters.germination',
      'parameters.additionalZoneValues',
      // maize
      'parameters.estimatedPrecipitation',
      'parameters.minSoilMoisture',
      'parameters.maxSoilMoisture'
    ]),
    startCreation (state) {
      state.parameters = commonParameters()
      state.ui.page = 'parameters'
      state.ui.algorithmCollection = null
    },
    setAlgorithm (state, algorithmObject) {
      const units = validUnits[algorithmObject.algorithmGroup]
      const cleanedParameters = pick(state.parameters, Object.keys(commonParameters()))

      const algorithmTemplate = makeTemplate(algorithmObject.algorithmGroup)
      if (units.find(x => x === cleanedParameters.unit)) {
        delete algorithmTemplate.unit
      }

      state.parameters = Object.assign(cleanedParameters, algorithmTemplate, {algorithm: algorithmObject.name})
    },
    showPreview (state, {applicationValues, algorithmOutput, algorithmParameters}) {
      const {unit} = state.parameters
      state.parameters.applicationValues = convertApplicationValues({applicationValues, unit})

      // algorithmOutput and algorithmParameters are used for the metadata object in the database
      // when saving the application map
      state.parameters.algorithmOutput = algorithmOutput
      state.parameters.algorithmParameters = algorithmParameters
      state.ui.page = 'preview'
    },
    discardPreview (state) {
      state.parameters.applicationValues = null
      state.parameters.algorithmOutput = null
      state.parameters.algorithmParameters = null
      state.ui.page = 'parameters'
    },
    // old post-creation page stuff
    setApplicationMapCalculated (state, {ready, failed}) {
      state.data.applicationMapCalculated = ready
      state.data.applicationMapCreationFailed = failed
    },
    resetCreationStatus (state) {
      state.data.applicationMapCalculated = false
      state.data.applicationMapCreationFailed = false
    },
    setApplicationMapId (state, id) {
      state.data.applicationMapId = id
    },
    reset (state) {
      Object.assign(state.data, applicationMapsDataDefaults())
    }
  },
  actions: {
    async startCreation ({commit, dispatch}) {
      await dispatch('i18n/preloadTranslations', ['applicationMaps'], {root: true})

      commit('startCreation')
      commit('fieldRecordSystem/setRightView', 'algorithmicApplicationMapCreation', {root: true})
    },
    loadAdditionalZoneValues ({state, rootState, commit}) {
      if (!state.parameters.zoneMapId || state.parameters.additionalZoneValues === undefined) return

      const algorithm = rootState.masterData.productAlgorithms[state.parameters.algorithm]

      return smarterGet('/api/v2/zone-maps/{zoneMapId}/additional-values', {
        id: 'frs.applicationMaps.additionalZoneValues.load',
        sharedCache: true,
        expiry: 0,
        inputs: {
          zoneMapId: () => state.parameters.zoneMapId
        },
        onResult (data) {
          if (!['wimexWinterWheat', 'wimexMaize'].includes(algorithm.algorithmGroup)) return

          const values = {}

          data.zoneValues.forEach(zoneData => {
            values[zoneData.zoneId] = {}

            for (const key of ['waterAvailability', 'acreRatio', 'pwc']) {
              if (algorithm.algorithmGroup !== 'wimexWinterWheat' && key === 'waterAvailability') continue

              values[zoneData.zoneId][key] = zoneData[key]
            }
          })

          commit('setAdditionalZoneValues', values)
        }
      })
    },
    runAlgorithm ({state, commit, rootState}) {
      const zoneMap = rootState.fieldRecordSystem.mapManagement.data.zoneMap.mapLookup[state.parameters.zoneMapId]

      const dto = {
        algorithm: state.parameters.algorithm
      }

      if (state.parameters.algorithm.startsWith('SeedExpert_WinterWheat')) {
        dto.parameters = {
          dateOfSeed: state.parameters.dateOfSeed,
          type: state.parameters.type,
          thousandKernelWeight: state.parameters.thousandKernelWeight,
          germination: state.parameters.germination,
          germinationCapacity: state.parameters.germinationCapacity,
          zones: zoneMap.zones.map(zone => ({
            zoneId: zone.number,
            pwc: state.parameters.additionalZoneValues[zone.id].pwc,
            acresRatio: state.parameters.additionalZoneValues[zone.id].acreRatio, // NOTE name discrepancy on backend
            waterAvailability: state.parameters.additionalZoneValues[zone.id].waterAvailability
          }))
        }
      }
      if (state.parameters.algorithm.startsWith('SeedExpert_Corn') || state.parameters.algorithm.startsWith('SeedExpert_EnergyPlantCorn')) {
        dto.parameters = {
          estimatedPrecipitation: state.parameters.estimatedPrecipitation,
          minSoilMoisture: state.parameters.minSoilMoisture,
          maxSoilMoisture: state.parameters.maxSoilMoisture,
          zones: zoneMap.zones.map(zone => ({
            zoneId: zone.number,
            pwc: state.parameters.additionalZoneValues[zone.id].pwc,
            acresRatio: state.parameters.additionalZoneValues[zone.id].acreRatio // NOTE name discrepancy on backend
          }))
        }
      }
      if (state.parameters.algorithm.startsWith('FertAssist_UndergroundFertilization')) {
        dto.parameters = {
          currentTemperature: state.parameters.temperature,
          zones: zoneMap.zones.map(zone => ({zoneId: zone.number}))
        }
      }

      return smarterPost('/api/v2/algorithm', dto, {
        id: 'frs.applicationMaps.calculate'
      })
      .then(result => {
        const objectReducer = (byNumber) => (lookup, zone) => {
          lookup[zone.id] = byNumber[zone.number]
          return lookup
        }
        const applicationValues = zoneMap.zones.reduce(objectReducer(result.output), {})
        commit('showPreview', {
          applicationValues,
          algorithmOutput: result.output,
          algorithmParameters: result.parameters
        })
      })
    },
    async createApplicationMap ({state, rootState, rootGetters}) {
      // const zoneMap = rootState.fieldRecordSystem.mapManagement.data.zoneMap.mapLookup[state.parameters.zoneMapId]
      const {orgUnitId} = rootState.fieldRecordSystem.navigation.location
      const {harvestYear} = rootState.fieldRecordSystem.userSettings

      const url = state.parameters.algorithm.startsWith('FertAssist_UndergroundFertilization')
        ? `/api/v2/fert-assist/${orgUnitId}/${harvestYear}`
        : `/api/v2/seed-expert/${orgUnitId}/${harvestYear}`

      let {
        applicationValues: zoneAmounts,
        ...rest
      } = state.parameters

      const product = rootGetters['masterData/products'][rest.productId]

      rest.name = rest.name
        ? `${moment.utc().format('YYYY-MM-DD')}_${rest.name}_${product.name}`
        : `${moment.utc().format('YYYY-MM-DD')}_${rootGetters['fieldRecordSystem/fieldName']}_${rootGetters['fieldRecordSystem/orgUnitName']}_${product.name}`

      return smarterPost(url, {zoneAmounts, ...rest}, {
        id: 'frs.applicationMaps.save'
      })
    },
    cancelCreation ({commit}) {
      commit('fieldRecordSystem/setRightView', 'default', {root: true})
    },
    // old post-creation page stuff
    async createApplicationMapSuccess ({state, dispatch, commit, rootGetters}, applicationMapId) {
      const fieldId = rootGetters['fieldRecordSystem/fieldId']
      const previewsApplicationMapId = state.data.applicationMapId
      try {
        commit('dataLoading/invalidate', 'applicationMapInfos.forField', {root: true})
        await dispatch('fieldRecordSystem/mapManagement/loadAvailableApplicationMapsForField', fieldId, {root: true})
        await dispatch('fieldRecordSystem/mapManagement/loadApplicationMapDetails', applicationMapId, {root: true})
        commit('setApplicationMapId', applicationMapId)
        commit('setApplicationMapCalculated', {ready: true, failed: false})
      } catch (e) {
        commit('setApplicationMapId', previewsApplicationMapId)
        commit('setApplicationMapCalculated', {ready: true, failed: true})
        throw e
      }
    },
    viewResultPage ({commit}) {
      commit('fieldRecordSystem/setRightView', 'applicationMapCreationResult', {root: true})
    }
  }
}
