import {mapValues} from 'lodash'

export const commonParameters = () => ({
  // application map data
  name: '',
  productId: null,
  unit: null,
  // metadata
  zoneMapId: null,
  cultivationId: null,
  // algorithm data
  algorithm: null,
  applicationValues: null // {[zoneId]: value}
})

export const validUnits = {
  wimexMaize: ['K/m²', 'K/ha'],
  wimexWinterWheat: ['kg/ha'],
  wimexUnderRootFertilization: ['kg/ha', 'l/ha']
}

export const convertApplicationValues = ({applicationValues, unit}) => {
  if (unit === 'K/ha') return mapValues(applicationValues, x => x * 10000)

  return applicationValues
}

const parameters = {
  wimexMaize: () => ({
    unit: 'K/m²',
    estimatedPrecipitation: null,
    minSoilMoisture: null,
    maxSoilMoisture: null,
    additionalZoneValues: null // {[zoneId]: {pwc: value, acreRatio: value}}
  }),
  wimexWinterWheat: () => ({
    unit: 'kg/ha',
    type: 1,
    dateOfSeed: 1,
    thousandKernelWeight: null,
    germinationCapacity: null,
    germination: null,
    additionalZoneValues: null // {[zoneId]: {pwc: value, acreRatio: value, waterAvailability: value}}
  }),
  wimexUnderRootFertilization: () => ({
    unit: 'kg/ha',
    temperature: null
  })
}

export const makeTemplate = algorithm => parameters[algorithm]()
