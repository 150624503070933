import actions from './actions'
import mutations from './mutations'

const typeOptions = [
  {value: 'application', text: 'Dünger'},
  {value: 'production', text: 'Ernte'}
]

const state = {
  name: '',
  date: null,
  productId: null,
  cropUsageId: null,
  valueKey: null,
  timestampKey: null,
  features: null,
  type: typeOptions[0],
  typeOptions: typeOptions,
  unit: 'kg/ha'
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
