import {parse} from 'wellknown'
import {booleanEqual} from '@turf/turf'

import {smarterPost, smarterGet} from '@helpers/vuex/data-loading'

function setAndClearDependentData ({commit}, data) {
  if (data.segmentationResult) {
    data.selectedSegments = data.segmentationResult.map(() => [])
    data.segmentLaneTypes = data.segmentationResult.map((ring, i) => data.segmentationResult[i].map(() => 'line'))
    data.headlands = []
    data.generatedHeadlandLane = 'MULTILINESTRING EMPTY'
    data.candidateLanes = []
  } else if (data.headlands) {
    data.candidateLanes = []
  }
  commit('partEditBuffer/set', data)
}

export async function getSegmentation ({state, commit, rootGetters}) {
  // TODO get saved segmentation for field + partialField index
  // TODO on server: clear saved segmentations for partialField indices >= current partialField count
  // TODO delete saved segmentation for this field + partialField index + geometry

  return smarterGet('/api/LanePlanning/GetManualSegmentation/{fieldId}/{partFieldIndex}/{totalFieldPartCount}', {
    id: 'geotrax.getLanePlansForField',
    expiry: 5,
    inputs: {
      fieldId: () => rootGetters['fieldRecordSystem/fieldId'],
      partFieldIndex: () => state.laneEditBuffer.selectedPartIndex,
      totalFieldPartCount: () => state.laneEditBuffer.fieldParts.length
    },
    onResult (segmentation) {
      if (segmentation && booleanEqual(parse(segmentation.geometry), parse(state.laneEditBuffer.fieldParts[state.laneEditBuffer.selectedPartIndex].wkt))) {
        setAndClearDependentData({commit}, {segmentationResult: segmentation.result})
      } else {
        return autoSegmentation({state, commit})
      }
    }
  })
}

export async function autoSegmentation ({state, commit}) {
  const {wkt} = state.partEditBuffer
  const {turningCircle} = state.laneEditBuffer

  const data = {
    minTurnRadius: turningCircle,
    border: wkt
  }
  return smarterPost('/api/LanePlanning/Segmentation', data, {
    id: 'geotrax.segmentation'
  })
  .then(data => data.result)
  .then(segmentationResult => {
    setAndClearDependentData({commit}, {segmentationResult})
    return segmentationResult
  })
}

export async function getHeadland ({state, commit, dispatch}) {
  const {maxIntoField, turningCircle, gap, workingWidth} = state.laneEditBuffer
  const {wkt, segmentationResult, depth, selectedSegments, headlandLanes} = state.partEditBuffer

  const data = {
    border: wkt,
    segmentationResult,
    selectedSegments,
    depth,
    turnRadius: turningCircle,
    maxExtensionIntoField: maxIntoField,
    laneWidth: workingWidth,
    gap,
    headlandLanes
  }

  return smarterPost('/api/LanePlanning/Headlands', data, {
    id: 'geotrax.headland'
  })
  .then(headlandsResult => setAndClearDependentData({commit}, {subStep: null, headlands: headlandsResult.headlands, generatedHeadlandLane: headlandsResult.lanes}))
}

export async function getABLines ({state, commit}) {
  const {workingWidth, minLaneLength, maxLanes, gap, turningCircle} = state.laneEditBuffer
  const {wkt, segmentationResult, selectedABSegments, headlands, segmentLaneTypes} = state.partEditBuffer

  const data = {
    border: wkt,
    segmentationResult,
    selectedSegments: selectedABSegments,
    width: workingWidth,
    gap,
    minLength: minLaneLength,
    turnRadius: turningCircle,
    headlands,
    maximumSets: maxLanes,
    // todo: refactor and remove pointless mappings
    segmentLaneTypes: segmentLaneTypes.map(ring => ring.map(type => type === 'line' ? 'Straight' : 'Contour'))
  }

  return smarterPost('/api/LanePlanning/LaneSuggestions', data, {
    id: 'geotrax.laneSuggestions'
  })
  .then(candidateLanes => setAndClearDependentData({commit}, {candidateLanes, selectedLane: 0}))
}

export function newAbLine ({state, commit}, offset) {
  const abindex = state.partEditBuffer.selectedAbSegment
  const selectedAbIndex = state.partEditBuffer.selectedSegments[0].findIndex(x => x === abindex - 1)
  if (selectedAbIndex === -1) return

  const abLineString = state.partEditBuffer.abSegments[selectedAbIndex].properties.segmentData.lineString

  const data = {
    rawLine: abLineString,
    distance: offset
  }

  return smarterPost('/api/LanePlanning/MoveAbLane', data, {
    id: 'geotrax.moveAbLane'
  })
  .then(newAbLane => {
    const oldAbSegment = state.partEditBuffer.abSegments[selectedAbIndex]
    var newAbSegments = state.partEditBuffer.abSegments
    const abSegmentCount = newAbSegments.length
    const newSegmentIndex = Math.max(...newAbSegments.map(x => x.properties.globalSegmentIndex)) + 1
    const copyCount = newAbSegments.filter(x => x.properties.id.includes(oldAbSegment.properties.id)).length

    newAbSegments.push({
      geometry: parse(newAbLane),
      type: 'Feature',
      properties: {
        segmentData: {
          lineString: newAbLane
        },
        computed: true,
        id: `segment_${oldAbSegment.properties.ringIndex}_${oldAbSegment.properties.segmentIndex}_${copyCount}`,
        globalSegmentIndex: newSegmentIndex,
        lineType: oldAbSegment.properties.lineType,
        arrayIndex: abSegmentCount,
        name: `${oldAbSegment.properties.name}_${copyCount}`
      }
    })
    setAndClearDependentData({commit}, {abSegments: newAbSegments})
  })
}

export function moveLine ({state, commit}, offset) {
  const abindex = state.partEditBuffer.selectedAbSegment
  const selectedAbIndex = state.partEditBuffer.selectedSegments[0].findIndex(x => x === abindex - 1)
  if (selectedAbIndex === -1) return

  const abLineString = state.partEditBuffer.abSegments[selectedAbIndex].properties.segmentData.lineString

  const data = {
    rawLine: abLineString,
    distance: offset
  }

  return smarterPost('/api/LanePlanning/MoveAbLane', data, {
    id: 'geotrax.moveAbLane'
  })
  .then(newAbLane => {
    const newAbSegment = state.partEditBuffer.abSegments[selectedAbIndex]
    newAbSegment.properties.segmentData.lineString = newAbLane
    newAbSegment.geometry = parse(newAbLane)
    var newAbSegments = state.partEditBuffer.abSegments
    newAbSegments.splice(selectedAbIndex, 1, newAbSegment)
    setAndClearDependentData({commit}, {abSegments: newAbSegments})
  })
}

export function moveLines ({state, commit, dispatch}, offset) {
  const {workingWidth, minLaneLength, gap, turningCircle} = state.laneEditBuffer
  const {wkt, segmentationResult, headlands, candidateLanes, selectedLane} = state.partEditBuffer
  const laneCandidate = candidateLanes[selectedLane]

  const data = {
    border: wkt,
    segmentationResult,
    width: workingWidth,
    gap,
    offset,
    minLength: minLaneLength,
    turnRadius: turningCircle,
    headlands,
    // todo: refactor and remove pointless mappings
    ringIndex: laneCandidate.ringIndex,
    segmentIndex: laneCandidate.segmentIndex,
    laneType: laneCandidate.type
  }

  return smarterPost('/api/LanePlanning/MoveLane', data, {
    id: 'geotrax.moveLane'
  })
  .then(newCandidate => {
    const newCandidateLanes = [...candidateLanes]
    newCandidateLanes.splice(selectedLane, 1, newCandidate)
    setAndClearDependentData({commit}, {candidateLanes: newCandidateLanes})
  })
}

export function splitOrJoinSegment ({state, commit, rootGetters, dispatch}, point) {
  const {wkt, segmentationResult, selectedSegments} = state.partEditBuffer

  const data = {
    border: wkt,
    segmentationResult,
    // that is a bug - should be the selected segments?
    // selectedSegments: segmentationResult.map(() => []),
    selectedSegments,
    point
  }
  return smarterPost('/api/LanePlanning/SplitOrJoinSegments', data, {
    id: 'geotrax.editSegments'
  })
  .then(result => {
    // not sure if needed... it's a duplicate
    // segments.forEach((ring, ringIndex) => ring.forEach((segment, segmentIndex) => makeFeature({id: `segment_${ringIndex}_${segmentIndex}`, wkt: segment})))
    // setAndClearDependentData({commit}, result)
    setAndClearDependentData({commit}, {segmentationResult: result.result, selectedSegments: result.selectedSegments})
    // TODO save segmentation (result.result) for current field + partialField index + geometry
    saveSegmentation({state, rootGetters}, result.result)
  })
}

function saveSegmentation ({state, rootGetters}, result) {
  const data = {
    fieldId: rootGetters['fieldRecordSystem/fieldId'],
    partFieldIndex: state.laneEditBuffer.selectedPartIndex,
    geometry: state.laneEditBuffer.fieldParts[state.laneEditBuffer.selectedPartIndex].wkt,
    result: result
  }
  return smarterPost('/api/LanePlanning/SaveManualSegmentation', data, {
    id: 'geotrax.saveManualSegmentation'
  })
}

export function resetSegmentation ({state, rootGetters, dispatch}) {
  return dispatch('autoSegmentation')
    .then(segmentationResult => saveSegmentation(({state, rootGetters}), segmentationResult))
}
