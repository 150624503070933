import {map} from '@helpers/objects'

// columnMapping {fieldName: String, orgName: String, subOrgName: String}
export default function (geojson, columnMapping) {
  const tree = []
  const root = {children: tree}

  const newOrg = (name, parentName) => ({
    id: `${parentName}>>>>>>${name}`,
    type: 'org',
    name,
    children: []
  })

  const newField = (name, feature, index) => ({
    id: index,
    type: 'field',
    name: name || `F${index + 1}`,
    feature
  })

  const getOrCreateOrg = (name, parentName) => {
    if (!name) return null

    const parent = parentName ? getOrCreateOrg(parentName) : root
    const existing = parent.children.find(x => x.name === name && x.type === 'org')

    if (existing) return existing

    const entity = newOrg(name, parentName)
    parent.children.push(entity)

    return entity
  }

  geojson.features.forEach((feature, i) => {
    const {fieldName, orgName, subOrgName} = map(columnMapping, column => column && feature.properties
      ? feature.properties[column] || null
      : null)

    const parent = subOrgName && orgName
      ? getOrCreateOrg(subOrgName, orgName)
      : orgName || subOrgName
        ? getOrCreateOrg(orgName || subOrgName)
        : root

    parent.children.push(newField(fieldName, feature, i))
  })

  return tree
}
