import Vue from 'vue'

const mutations = {
  updateFieldData (state, dataByFieldId) {
    const newData = {}
    for (const fieldId in dataByFieldId) {
      const data = {
        ...state.data.field[fieldId],
        ...dataByFieldId[fieldId]
      }
      newData[fieldId] = data
    }
    state.data.field = {...state.data.field, ...newData}
  },
  updateOrgUnitData (state, dataByOrgUnitId) {
    const newData = {}
    for (const orgUnitId in dataByOrgUnitId) {
      const data = {
        ...state.data.orgUnits.data[orgUnitId],
        ...dataByOrgUnitId[orgUnitId]
      }
      newData[orgUnitId] = data
    }
    state.data.orgUnits.data = {...state.data.orgUnits.data, ...newData}
  },
  setIsNewEntity (state, value) {
    state.ui.isNewEntity = value
  },
  deleteMasterData (state, orgUnitId) {
    const data = {...state.data.orgUnits.data[orgUnitId]}
    data.companyNumbers = []
    data.companyData = null

    Vue.set(state.data.orgUnits.data, orgUnitId, data)
  },
  addEmptyMasterData (state, orgUnitId) {
    const data = {
      companyNumbers: [],
      companyData: {}
    }
    Vue.set(state.data.orgUnits.data, orgUnitId, Object.assign({}, state.data.orgUnits.data[orgUnitId], data))
  },
  updateCompanyNumber (state, {orgUnitId, companyNumber}) {
    const orgUnitData = state.data.orgUnits.data

    orgUnitData[orgUnitId].companyNumbers = orgUnitData[orgUnitId].companyNumbers
      .filter(x => x.id !== companyNumber.id)
      .concat(companyNumber)
  },
  removeCompanyNumber (state, {orgUnitId, companyNumberId}) {
    const orgUnitData = state.data.orgUnits.data

    orgUnitData[orgUnitId].companyNumbers = orgUnitData[orgUnitId].companyNumbers
      .filter(x => x.id !== companyNumberId)
  },
  setEntityStructureEditing (state, visibility) {
    state.ui.entityStructureEditing = visibility
  }
}

export default mutations
