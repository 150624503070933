import {makeSetters} from '@helpers/vuex/mutations'

const defaults = () => ({
  // machine settings, planning specific
  planningProfile: 'ab',
  machineId: null,
  workingWidth: 6,
  turningCircle: 15,
  gap: 0, // distance between lanes

  fieldParts: [], // field or partial fields
  selectedPartIndex: null,
  finished: false,

  // download settings
  isBorderSelected: true,
  includeBorderAsCurve: true,
  isHeadlandSelected: true,
  isHeadlandLanesSelected: true,
  includeInfieldLanes: true,

  isOneFileSelected: true
})

const checkValidity = (state, partialState) => {
  for (const key in partialState) {
    if (!(key in state)) {
      console.error(`[lane data editBuffer] set: property '${key}' does not exist in lane data defaults`)
    }
  }
}

const setMachineParameters = (state, laneData) => {
  if (!laneData.machine) return

  if (laneData.machine.workingWidth) {
    state.workingWidth = laneData.machine.workingWidth
  }
  if (laneData.machine.turningCircle) {
    state.turningCircle = laneData.machine.turningCircle
  }
}

export default {
  namespaced: true,
  state: defaults(),
  mutations: {
    ...makeSetters([
      'gap',
      'machineId',
      'workingWidth',
      'turningCircle',
      'planningProfile',
      'isBorderSelected',
      'includeBorderAsCurve',
      'isHeadlandSelected',
      'isHeadlandLanesSelected',
      'includeInfieldLanes',
      'isOneFileSelected'
    ]),
    set (state, laneData) {
      checkValidity(state, laneData)
      Object.assign(state, laneData)
      setMachineParameters(state, laneData)
    },
    reset (state, laneData) {
      if (laneData) {
        checkValidity(state, laneData)
      }
      Object.assign(state, defaults(), laneData)
      if (laneData) {
        setMachineParameters(state, laneData)
      }
    }
  },
  actions: {
    setMachineData ({commit, rootGetters}, machineId) {
      const machines = rootGetters['fieldRecordSystem/lanePlanning/availableMachines']
      commit('setWorkingWidth', machines[machineId].workingWidth)
      commit('setTurningCircle', machines[machineId].turningCircle)
    }
  }
}
