import axios from 'axios'
import {makeSetters} from '@helpers/vuex/mutations'
import moment from 'moment'
import {smarterGet} from '@helpers/vuex/data-loading'

export default {
  namespaced: true,
  state: {
    parameters: {
      name: null,
      zoneMapId: null,
      cultivationId: null,
      cropUsageId: null,
      productId: null,
      unit: null,
      zoneAmounts: {}
    },
    byHeterogenity: true,
    averageAmount: null,
    reverseZoneAmounts: false
  },
  getters: {
    zoneMap (state, getters, rootState) {
      if (!state.parameters.zoneMapId) return null

      return rootState.fieldRecordSystem.mapManagement.data.zoneMap.mapLookup[state.parameters.zoneMapId]
    },
    validUnits (state, getters, rootState, rootGetters) {
      const cultivationId = state.parameters.cultivationId

      if (!cultivationId) {
        return []
      }
      const filter = ['MassPerArea', 'VolumePerArea']

      if (state.parameters.cropUsageId) {
        const cropUsage = rootState.masterData.cropUsages[state.parameters.cropUsageId]
        const crop = rootState.masterData.crops[cropUsage.cropId]
        const hasCropTagForSimpleApplicationMaps = crop.tags.includes('Applikationskarten:Freie Anwendungsplanung:Körner/Fläche Einheiten')
        if (hasCropTagForSimpleApplicationMaps) {
          filter.push('CornCountPerArea')
        }
      }

      return rootGetters['masterData/unitArray'].filter(unit => filter.includes(unit.type)).map(unit => unit.shortcut)
    }
  },
  mutations: {
    ...makeSetters([
      'parameters.name',
      'parameters.zoneMapId',
      'parameters.cultivationId',
      'parameters.cropUsageId',
      'parameters.unit',
      'parameters.zoneAmounts',
      'averageAmount',
      'byHeterogenity',
      'reverseZoneAmounts'
    ]),
    setProductId (state, productId) {
      state.parameters.productId = productId
    },
    reset (state) {
      state.parameters.zoneMapId = null
      state.parameters.zoneAmounts = {}
      state.parameters.name = ''
      state.parameters.cultivationId = null
      state.parameters.cropUsageId = null
      state.parameters.unit = null
      state.parameters.productId = null

      state.byHeterogenity = true
      state.averageAmount = null
      state.reverseZoneAmounts = false
    }
  },
  actions: {
    startSimpleApplicationMaps ({commit}) {
      commit('reset')
      commit('fieldRecordSystem/setRightView', 'default', {root: true})
    },
    async addUnitsAndGetCropusageByCultivationId ({commit, getters}, cultivationId) {
      await smarterGet('/api/v2/org/cultivations/{cultivationId}/details', {
        id: 'simpleApplicationMap.cultivation.loadDetails',
        inputs: {
          cultivationId: () => cultivationId
        },
        onResult: details => {
          commit('setCropUsageId', details.cropUsageId)
          commit('setCultivationId', cultivationId)
        }
      })

      const unit = getters.validUnits[0]
      commit('setUnit', unit)
    },
    createApplicationMap ({state, rootState, rootGetters}) {
      const {orgUnitId} = rootState.fieldRecordSystem.navigation.location
      const {harvestYear} = rootState.fieldRecordSystem.userSettings

      const url = state.byHeterogenity
        ? `/api/v2/simple-application-maps/${orgUnitId}/${harvestYear}/heterogenity`
        : `/api/v2/simple-application-maps/${orgUnitId}/${harvestYear}/zones`

      let data = {
        averageAmount: state.averageAmount,
        reverseZoneAmounts: state.reverseZoneAmounts,
        ...state.parameters
      }
      const product = rootGetters['masterData/products'][data.productId]

      data.name = data.name
        ? `${moment.utc().format('YYYY-MM-DD')}_${data.name}_${product.name}`
        : `${moment.utc().format('YYYY-MM-DD')}_${rootGetters['fieldRecordSystem/fieldName']}_${rootGetters['fieldRecordSystem/orgUnitName']}_${product.name}`

      return axios.post(url, data)
    },
    viewResultPage ({commit}) {
      commit('fieldRecordSystem/setRightView', 'applicationMapCreationResultSimpleApplicationMaps', {root: true})
    }
  }
}
