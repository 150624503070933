import workflows from './workflows'

const mutations = {
  setLeftView (state, view) {
    state.ui.view.left = view
  },
  setRightView (state, view) {
    state.ui.view.right = view
    state.ui.workflow = workflows[view]
    // resolve workflow alias
    if (state.ui.workflow && state.ui.workflow.alias) {
      state.ui.workflow = workflows[state.ui.workflow.alias]
    }
    state.ui.view.expanded = !!state.ui.workflow && !!state.ui.workflow.wide

    // scroll to top
    const sidebarContent = document.querySelector('.right-sidebar-content')
    if (sidebarContent) {
      sidebarContent.scrollTop = 0
    }
  },
  setDataLoading (state, value) {
    state.ui.loadingData = value
  },
  setWorkflow (state, partialWorkflow) {
    const currentWorkflow = state.ui.workflow
    for (const key in partialWorkflow) {
      if (key in currentWorkflow) {
        Object.assign(state.ui.workflow, partialWorkflow)
      }
    }
  }
}

export default mutations
