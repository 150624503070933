import translate from 'src/js/i18n/translate'
import {toMixtureName} from '../cultivation-planning/helpers'

export function cultivationToColor (cultivation) {
  let color
  switch (cultivation.type) {
  case 'main':
  case 'secondary':
    color = cultivation.color
    break
  case 'eco': {
    switch (cultivation.ecoTypeId) {
    case 'Brache': {
      color = '#4a91b1'
      break
    }
    default: {
      color = '#6c9db3'
      break
    }
    }
    break
  }
  case 'catch': {
    color = '#302f69'
    break
  }
  default: {
    color = '#ff0000'
    break
  }
  }
  return color || '#3b3b3b'
}

export function cultivationToName (cultivation) {
  switch (cultivation.type) {
  case 'main':
  case 'secondary': {
    if (!cultivation.cropUsageId) {
      console.error(`cropUsageId for cultivation with id ${cultivation.id} is null`)
    }
    return translate(`masterData.crops.usage.${cultivation.cropUsageId}_name`)
  }
  case 'catch': {
    return cultivation.mixtureComponents
      ? toMixtureName(cultivation.mixtureComponents)
      : translate('Areas.FieldRecordSystem.SR_FieldRecordSystem.CatchCrop')
  }
  case 'eco':
    return translate(`Areas.FieldRecordSystem.SR_FieldRecordSystem.EcoTypes_${cultivation.ecoTypeId}`)
  default:
    throw new Error(translate('Areas.FieldRecordSystem.SR_FieldRecordSystem.InvalidCultivationType').concat(`: ${cultivation.type}`))
  }
}

export function cultivationTypeToShortcut (cultivation) {
  return translate(`Areas.FieldRecordSystem.SR_FieldRecordSystem.CultivationType_${cultivation.type}_shortcut`)
}
