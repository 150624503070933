export const waterLevels = [
  'low',
  'medium',
  'high'
]

export const soilQualities = [
  'bad',
  'medium',
  'good'
]

export const usageModifiers = {
  grain: -0.5,
  silo: 0,
  biogas: 0.5
}

export const usages = Object.keys(usageModifiers)
