// import {makeFeature} from 'src/js/helpers/openlayers/features'

export const loadLotsOfOrgUnitData = ({state, dispatch, getters}, {skipFocus} = {}) => {
  const {orgUnitId} = state.navigation.location
  // orgUnit can be null, in case user deletes root orgUnit
  if (orgUnitId) {
    const fieldIds = getters.fieldsForCurrentOrgUnit.map(x => x.id)
    const visibleOnly = getters.entityLookup[orgUnitId].visibleOnly

    return Promise.all([
      dispatch('loadBasicFieldData', fieldIds),
      Promise.all([
        visibleOnly ? null : dispatch('mapManagement/loadAvailableZoneMapInfosForOrgUnitId', orgUnitId),
        dispatch('reloadFieldCultivationInfoForOrgUnit'),
        dispatch('masterData/reloadCustomProducts', {orgUnitId}, {root: true}) // TODO load directly where used, in FrsProductPicker and various views like action list or map details
      ])
    ])
  }
}

export const selectOrgUnit = ({state, getters, commit, dispatch}, orgUnitId) => {
  if (orgUnitId && !getters['navigation/entityLookup'][orgUnitId]) {
    throw new Error(`invalid orgUnitId '${orgUnitId}', not found in current tree`)
  }

  const previousOrgUnitId = state.navigation.location.orgUnitId

  commit('navigation/selectOrgUnit', orgUnitId)

  const view = state.ui.view.right

  if (!previousOrgUnitId) {
    if (orgUnitId && view in state.ui.transitions.escape) {
      commit('setRightView', state.ui.transitions.escape[view])
    }
  } else {
    const nextView = orgUnitId && view in state.ui.transitions.orgUnit
      ? state.ui.transitions.orgUnit[view]
      : 'default'

    commit('setRightView', nextView)
  }

  return Promise.all([
    dispatch('reloadOrgUnitData'),
    dispatch('loadLotsOfOrgUnitData', {skipFocus: false})
  ])
}

export const selectField = ({state, getters, commit, dispatch}, fieldId) => {
  if (!fieldId) {
    throw new Error('selectField action called without field')
  }
  if (!getters['navigation/entityLookup'][fieldId]) {
    throw new Error(`invalid fieldId '${fieldId}', not found in current tree`)
  }

  const {fieldId: previousFieldId} = state.navigation.location
  const parentLookup = getters['navigation/parentLookup']

  commit('navigation/selectField', {fieldId, parentLookup})

  const view = state.ui.view.right

  if (previousFieldId) {
    if (fieldId && view in state.ui.transitions.escape) {
      commit('setRightView', state.ui.transitions.escape[view])
    }
  } else {
    const nextView = fieldId && view in state.ui.transitions.field
      ? state.ui.transitions.field[view]
      : 'default'

    commit('setRightView', nextView)
  }

  const feature = getters.fieldFeature

  if (feature) {
    return dispatch('map/focus', feature)
  }
}

export const selectGroup = ({commit, dispatch}, tag) => {
  commit('navigation/selectTag', tag)
  return dispatch('loadGeometriesForTag')
}
