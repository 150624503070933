import axios from 'axios'

import {simplifyHighQuality} from '@helpers/geojson/simplification'
import {geoJsonFormat, dissolveMultiGeometries} from '@helpers/openlayers/features'

function setupEditing ({commit}, features) {
  if (features.every(x => x.type === 'Feature')) {
    features = features.map(x => geoJsonFormat.readFeature(x))
  }

  features = dissolveMultiGeometries(features)

  commit('clearEditingHistory')
  commit('setEditing', true)

  commit('setActiveFeatures', features.map(feature => geoJsonFormat.writeFeatureObject(feature)))
  commit('setSelectedIds', [])
}

function setupExpertMode ({commit}) {
  commit('setFlavor', 'expert')
  commit('setShowImportSimplificationHint', false)
  commit('fieldRecordSystem/setEditingComponent', null, {root: true})
}

function selectSensibleDefaults ({commit, dispatch}, features) {
  commit('setMode', features.length ? 'edit' : 'addPolygon')
  return dispatch('focus', features)
}

export function startQuickMode ({state, commit, dispatch}, features) {
  setupEditing({state, commit}, features)

  commit('setFlavor', 'quick')

  return selectSensibleDefaults({commit, dispatch}, features)
}

export function switchToExpertMode ({state, commit}) {
  if (state.editing.flavor !== 'quick') {
    throw new Error('switching to expert mode only works from quick mode')
  }

  setupExpertMode({commit})
}

export function startExpertMode ({state, commit, dispatch}, features) {
  setupEditing({state, commit}, features)

  setupExpertMode({commit})

  return selectSensibleDefaults({commit, dispatch}, features)
}

export async function stopEditing ({state, commit}) {
  commit('setEditing', false)

  const features = state.editing.activeFeatures

  return features.map(feature => geoJsonFormat.readFeature(feature))
}

export function deleteSelection ({state, commit}) {
  commit('recordFeatures')

  const unselected = feature => !state.editing.selectedIds.includes(feature.properties.id)

  // delete all if nothing selected (quick-mode)
  const activeFeatures = state.editing.selectedIds.length > 0
    ? state.editing.activeFeatures.filter(unselected)
    : []
  const guideFeatures = state.editing.selectedIds.length > 0
    ? state.editing.guideFeatures.filter(unselected)
    : []
  commit('setActiveFeatures', activeFeatures)
  commit('setGuideFeatures', guideFeatures)

  commit('setSelectedIds', [])
}

export function simplifyGeometry ({state, getters, commit}) {
  if (!getters.selectedFeatures.length) {
    return
  }

  commit('recordFeatures')

  const otherFeatures = state.editing.activeFeatures.filter(x => !getters.selectedFeatures.includes(x))

  const simplified = [...getters.selectedFeatures]
  simplified.forEach(feature => {
    simplifyHighQuality(feature.geometry) // mutate inplace because features (from getter) are discarded afterwards anyways
  })

  const features = otherFeatures.concat(simplified)

  commit('setActiveFeatures', features)
  commit('setSelectedIds', [])
}

export function borderRecognition ({state, commit, dispatch}, {source, coordinate: [lon, lat], featureLoadingFunction}) {
  if (state.borderRecognition.active) return

  if (featureLoadingFunction) {
    const features = featureLoadingFunction([lon, lat])

    if (features.length) {
      const recognizedBorder = dissolveMultiGeometries(features[0].clone())
        .map(feature => geoJsonFormat.writeFeatureObject(feature))

      const currentFeatures = state.editing.activeFeatures

      commit('setActiveFeatures', [...currentFeatures, ...recognizedBorder])
      return dispatch('enterSelectMode')
    }
  }

  commit('setBorderRecognitionStatus', true)

  const url = source === 'ixmap'
    ? `/api/v2/entities/fields/geodata/suggestion?longitude=${lon}&latitude=${lat}`
    : `/api/v2/automatic-border/${lon}/${lat}`

  axios.get(url)
  .then(response => response.data)
  .then(geojson => {
    commit('setBorderRecognitionStatus', false)

    if (!geojson.features.length) return false

    const recognizedBorder = dissolveMultiGeometries(geoJsonFormat.readFeature(geojson.features[0]))
      .map(feature => geoJsonFormat.writeFeatureObject(feature))

    const currentFeatures = state.editing.activeFeatures

    commit('setActiveFeatures', [...currentFeatures, ...recognizedBorder])
    return dispatch('enterSelectMode')
  })
  .catch(() => {
    commit('setBorderRecognitionStatus', false)
  })
}
