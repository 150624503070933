const mutations = {
  // root
  setRootCropRotations (state, rotations) {
    state.cropRotations.root = rotations
  },
  addCropRotation (state, cropRotation) {
    state.cropRotations.root.push(cropRotation)
  },
  updateCropRotation (state, cropRotation) {
    const cropRotationToUpdate = state.cropRotations.root.find(cr => cr.id === cropRotation.id)
    state.cropRotations.root.splice(state.cropRotations.root.indexOf(cropRotationToUpdate), 1, cropRotation)
  },
  removeCropRotation (state, cropRotationId) {
    const cropRotationToRemove = state.cropRotations.root.find(cr => cr.id === cropRotationId)
    state.cropRotations.root.splice(state.cropRotations.root.indexOf(cropRotationToRemove), 1)
  },
  // org unit
  setAssignedCropRotationsForOrgUnit (state, cropRotations) {
    state.cropRotations.company = cropRotations
  },
  // field
  assignCropRotationToField (state, cropRotation) {
    state.cropRotations.field = cropRotation
  },
  unassignCropRotationFromField (state) {
    state.cropRotations.field = null
  }
}

export default mutations
