import {getCenter} from 'ol/extent'
import Vue from 'vue'
import {cloneDeep} from 'lodash'

import {polyLikeFilter, splitToPolygons, geoJsonFormat} from '@helpers/openlayers/features'
import {makeSetters} from '@helpers/vuex/mutations'

import {ensureUniqueIds} from './helpers'

const assertGeoJson = features => {
  if (features.some(x => x.type !== 'Feature')) {
    throw new Error('only geojson supported')
  }
}

const historyMove = (state, {from, to}) => {
  const current = {
    editing: cloneDeep(state.editing.activeFeatures),
    guides: cloneDeep(state.editing.guideFeatures)
  }
  to.push(current)

  const replacement = from.pop()

  state.editing.activeFeatures = replacement.editing
  state.editing.guideFeatures = replacement.guides
}

const mutations = {
  ...makeSetters([
    'layers',
    'editing.abortEditing'
  ]),
  updateFabPosition (state, feature) {
    if (!feature) {
      state.fab.position = null
      return
    }

    state.fab.position = getCenter(feature.getGeometry().getExtent())
  },

  setGlobalAutoFit (state, value) {
    state.autoFit = value
  },
  setFocusExtent (state, extent) {
    state.focusExtent = extent
  },
  addVectorLayer (state, layer) {
    if (layer.id in state.layers) {
      throw new Error(`layer ${layer.id} already exists`)
    }

    Vue.set(state.layers, layer.id, layer)
    Vue.set(layer, 'styleStack', [layer.style])
    state.layerList = state.layerList.filter(x => x !== layer.id)
    state.layerList.splice(0, 0, layer.id)
  },
  removeVectorLayer (state, {id}) {
    Vue.delete(state.layers, id)
    state.layerList = state.layerList.filter(x => x !== id)
  },
  setFeatures (state, {id, features}) {
    console.error('[fieldRecordSystem/map/setFeatures] this mutation is deprecated')
    // console.trace()

    if (features.some(x => x.type !== 'Feature')) {
      // most likely ol.Feature instances
      features = features.map(x => geoJsonFormat.writeFeatureObject(x))
    }

    if (!state.layers[id]) {
      console.error(`[fieldRecordSystem/map/setFeatures] layer ${id} does not exist`)
      return
    }
    state.layers[id].features = features
  },
  setActiveFeatures (state, features) {
    assertGeoJson(features)

    state.editing.activeFeatures = ensureUniqueIds(features)
  },
  setGuideFeatures (state, features) {
    assertGeoJson(features)

    state.editing.guideFeatures = ensureUniqueIds(features)
  },
  pushVectorStyle (state, {id, style}) {
    state.layers[id].styleStack.push(style)
    state.layers[id].style = style
  },
  popVectorStyle (state, {id}) {
    const stack = state.layers[id].styleStack
    if (stack[0].length < 2) {
      throw new Error('popVectorStyle called more often than pushVectorStyle')
    }
    stack.pop()
    state.layers[id].style = stack[stack.length - 1]
  },
  setVectorStyle (state, {id, style}) {
    state.layers[id].style = style
  },
  setSelectedIds (state, ids) {
    state.editing.selectedIds = ids
  },
  setStyle (state, {id, style}) {
    state.layers[id].style = style
  },
  clear (state, id) {
    state.layers[id].features = []
  },
  setBingVisible (state, name) {
    state.bing.visible = name
  },
  // editing
  setEditing (state, active) {
    state.editing.active = active
    // TODO make more sophisticated, currently if you enter editing from a page where default interactions are disabled, it will be incorrect after stopping editing
    state.defaultInteractionsEnabled = !active
  },
  setMode (state, mode) {
    state.editing.mode = mode
  },
  setOriginalStyles (state, stylesDict) {
    state.editing.originalStyles = stylesDict
  },
  copyToClipboard (state, features) {
    if (features.some(x => x.type !== 'Feature')) {
      throw new Error('copyToClipboard only supports GeoJSON features')
    }

    state.clipboard = cloneDeep(features)
  },
  pasteAsPolygons (state, layerId) {
    const features = splitToPolygons(state.clipboard.map(x => geoJsonFormat.readFeature(x)).filter(polyLikeFilter))
      .map(feature => geoJsonFormat.writeFeatureObject(feature))

    if (layerId === 'guides') {
      state.editing.guideFeatures.push(...features)
    } else if (layerId === 'editing') {
      state.editing.activeFeatures.push(...features)
    } else {
      throw new Error(`invalid layerId: ${layerId}`)
    }
  },
  recordFeatures (state) {
    state.editing.redoBuffer = []
    state.editing.undoBuffer.push({
      editing: cloneDeep(state.editing.activeFeatures),
      guides: cloneDeep(state.editing.guideFeatures)
    })
  },
  undo (state, noRedo) {
    if (state.editing.undoBuffer.length) {
      historyMove(state, {from: state.editing.undoBuffer, to: state.editing.redoBuffer})
    }
    if (noRedo) {
      state.editing.redoBuffer = []
    }
  },
  redo (state) {
    if (state.editing.redoBuffer.length) {
      historyMove(state, {from: state.editing.redoBuffer, to: state.editing.undoBuffer})
    }
  },
  clearEditingHistory (state) {
    state.editing.undoBuffer = []
    state.editing.redoBuffer = []
  },
  moveLayerUp (state, {id}) {
    const index = state.layerList.indexOf(id)
    if (index > 0) {
      state.layerList.splice(index - 1, 2, id, state.layerList[index - 1])
    }
  },
  moveLayerDown (state, {id}) {
    const index = state.layerList.indexOf(id)
    if (index < state.layerList.length - 1) {
      state.layerList.splice(index, 2, state.layerList[index + 1], id)
    }
  }
}

export default mutations
