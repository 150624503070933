export function setPersons (state, persons) {
  state.data.persons = persons
}

export function setEditing (state, value) {
  state.ui.editing = value
}

export function savePerson (state, person) {
  const existing = state.data.persons.find(p => p.id === person.id)
  if (existing) {
    existing.name = person.name
  } else {
    state.data.persons.push(person)
  }
}

export function removePerson (state, personId) {
  const index = state.data.persons.findIndex(m => m.id === personId)
  if (index !== -1) {
    state.data.persons.splice(index, 1)
  }
}
