import {makeSetters} from '@helpers/vuex/mutations'

const defaults = () => ({
  id: null,
  orgUnitName: null,
  shouldHaveCompanyData: true,
  companyData: {
    name: null,
    address1: null,
    address2: null,
    address3: null,
    isOperatedEcologically: false,
    isSupplementarySourceOfIncome: false,
    companyForm: null,
    legalForm: null,
    invekos: null,
    tin: null,
    email: null,
    phoneNumber: null
  }
})

const checkValidity = partialCompany => {
  const defaultCompany = defaults()
  for (const key in partialCompany) {
    if (!(key in defaultCompany)) {
      console.error(`[company editBuffer] set: property '${key}' does not exist in company-data defaults`)
    }
  }
}

export default {
  namespaced: true,
  state: defaults(),
  mutations: {
    ...makeSetters([
      'id',
      'orgUnitName',
      'shouldHaveCompanyData',
      'companyData',
      'companyData.address1',
      'companyData.address2',
      'companyData.address3',
      'companyData.isOperatedEcologically',
      'companyData.isSupplementarySourceOfIncome',
      'companyData.companyForm',
      'companyData.legalForm',
      'companyData.invekos',
      'companyData.tin',
      'companyData.name',
      'companyData.email',
      'companyData.phoneNumber'
    ]),
    set (state, partialCompany) {
      checkValidity(partialCompany)
      Object.assign(state, partialCompany)
    },
    reset (state, {company}) {
      if (company) {
        checkValidity(company)
        Object.assign(state, company)
        // TODO remove once calling code works as it should
        if (!company.companyData) {
          state.companyData = defaults().companyData
        }
      } else {
        Object.assign(state, defaults())
      }
    }
  }
}
