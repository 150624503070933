import axios from 'axios'
import moment from 'moment'

import {can} from '@helpers/permissions'
import {getAndCommit} from '@helpers/vuex/actions'
import {smarterGet} from '@helpers/vuex/data-loading'

// legacy stuff

// TODO migrate to cultivations@orgunit && refactoring
export async function saveCultivation ({state, rootState, rootGetters, commit, dispatch}) {
  const fieldIds = state.editBuffer.fieldIds.length
    ? state.editBuffer.fieldIds
    : [rootGetters['fieldRecordSystem/fieldId']]
  const harvestYear = rootState.fieldRecordSystem.userSettings.harvestYear

  const geometries = state.editBuffer.geometry
  const orgUnit = rootGetters['fieldRecordSystem/orgUnit']
  const subTreeOrgUnits = rootGetters['fieldRecordSystem/orgUnitsForSelectedOrgUnit']

  await Promise.all(fieldIds.map(async (fieldId, i) => {
    const dto = {
      ...state.editBuffer,
      geometry: geometries[i],
      fieldIds: [fieldId]
    }

    if (!state.editBuffer.entityId && !fieldId) {
      throw new Error('no way of getting an orgUnitId to save the cultivation')
    }

    // NOTE parent of the current field in the loop, cultivation planning can happen for multiple orgs at the same time if started from a parent org
    const parentOrgUnitId = state.editBuffer.entityId || subTreeOrgUnits.find(org => org.children.find(child => child.id === fieldId)).id

    const cultivation = (await axios.post(`/api/v2/entities/orgunits/${parentOrgUnitId}/cultivations`, dto)).data

    const fieldsForCultivation = (await axios.get(`/api/v2/entities/orgunits/${orgUnit.id}/cultivations/fields/${harvestYear}`)).data

    if (fieldId) {
      const idsForField = [...state.data.selection.field, cultivation.id]
      commit('setCultivationsForField', idsForField)
    }

    cultivation.fieldIds = fieldsForCultivation[cultivation.id]

    const otherCultivations = Object.values(state.data.selection.orgUnit)
      .filter(x => x.id !== cultivation.id)

    commit('setCultivationsForOrgUnit', otherCultivations.concat(cultivation))
  }))
}

export function displayCultivationOverview ({dispatch}) {
  return Promise.all([
    dispatch('reloadCultivationsForOrgUnit')
  ])
    .then(() => {
      dispatch('launchCultivationPlanning')
    })
}

export const launchCultivationPlanning = ({rootState, commit}) => {
  commit('editBuffer/reset')
  if (rootState.fieldRecordSystem.navigation.location.fieldId) {
    commit('fieldRecordSystem/setRightView', 'cultivationsDisplayField', {root: true})
  } else {
    commit('fieldRecordSystem/setRightView', 'cultivationPlan', {root: true})
  }
}

export const loadNextCropPredictionForField = ({commit, state, rootState}, fieldId) => {
  if (state.predictedCrops[fieldId]) {
    // if key exists, field is being toggled off (to unselected state)
    commit('clearPredictedCrop', fieldId)
    return
  }

  const harvestYear = rootState.fieldRecordSystem.userSettings.harvestYear
  return axios.get(`/api/v2/entities/fields/${fieldId}/data/crop-usage/next/${harvestYear}`)
    .then(response => response.data)
    .then(({cropUsageId}) => {
      commit('setPredictedCrop', {fieldId, cropUsageId})
    })
}

export function reloadCultivationsForOrgUnit ({rootState, commit}) {
  if (can(rootState.permissions, 'Use.Frs.Cultivations')) {
    return smarterGet([
      '/api/v2/entities/orgunits/{orgUnitId}/cultivations/{harvestYear}',
      '/api/v2/entities/orgunits/{orgUnitId}/cultivations/fields/{harvestYear}'
    ], {
      id: 'orgUnit.cultivations',
      expiry: 120,
      inputs: {
        orgUnitId: () => rootState.fieldRecordSystem.navigation.location.orgUnitId,
        harvestYear: () => rootState.fieldRecordSystem.userSettings.harvestYear
      },
      sharedCache: true,
      onResult ([cultivations, fieldsUnderCultivations]) {
        cultivations.forEach(cultivation => {
          cultivation.fieldIds = fieldsUnderCultivations[cultivation.id]
            ? fieldsUnderCultivations[cultivation.id]
            : []
        })
        commit('setCultivationsForOrgUnit', cultivations)
      }
    })
  } else {
    return Promise.resolve()
  }
}

// TODO refactor!
// option 1: pull permission check out and revert to previous getAndCommit usage
// option 2: don't use getAndCommit at all
// option 3: add permission check capabilities to getAndCommit, make sure all usages work
const reloadCultivationTypesInternal = getAndCommit('/api/v2/masterdata/cultivation/types', 'setCultivationTypes')
export const reloadCultivationTypes = store => {
  if (can(store.rootState.permissions, 'Use.Frs.Cultivations')) {
    return reloadCultivationTypesInternal(store)
  } else {
    return Promise.resolve()
  }
}

// TODO refactor!
// option 1: pull permission check out and revert to previous getAndCommit usage
// option 2: don't use getAndCommit at all
// option 3: add permission check capabilities to getAndCommit, make sure all usages work
const reloadEcoTypesInternal = getAndCommit('/api/v2/masterdata/cultivation/eco/types', 'setEcoTypes')
export const reloadEcoTypes = store => {
  if (can(store.rootState.permissions, 'Use.Frs.Cultivations')) {
    return reloadEcoTypesInternal(store)
  } else {
    return Promise.resolve([])
  }
}

export const saveQuickCultivation = ({rootState, commit, dispatch}, {cropUsageId, year}) => {
  const selectedFieldId = rootState.fieldRecordSystem.navigation.location.fieldId
  const additionalDefaults = {
    harvestYears: [year],
    geometry: [rootState.fieldRecordSystem.data.field[selectedFieldId].wkt],
    dateTimeRange: {
      start: moment([year]).startOf('year').toISOString(),
      end: moment([year]).endOf('year').toISOString()
    },
    cropUsageId
  }

  commit('editBuffer/reset', additionalDefaults)
  return dispatch('saveCultivation')
}

export const editCultivationOrgUnit = ({rootState, commit, dispatch, rootGetters}, {cultivationId, fieldId}) => {
  // dispatch('editCultivation', cultivationId)

  // action is called when adding a cultivation on org level

  const loadPrerequisiteData = Promise.all([
    dispatch('reloadCultivationsForOrgUnit'), // needs to be loaded for displaying existing cultivations under each field selection card
    dispatch('reloadMixtures'),
    dispatch('fieldRecordSystem/loadFieldGeometriesForCurrentOrgUnit', null, {root: true})
  ])

  const harvestYear = rootState.fieldRecordSystem.userSettings.harvestYear
  const orgUnitId = rootGetters['fieldRecordSystem/orgUnitId']

  let loadExistingCultivation = null

  // TODO figure out what this is
  cultivationId
    ? loadExistingCultivation = Promise.all([
      axios.get(`/api/v2/cultivations/${cultivationId}`)
        .then(response => response.data),
      axios.get(`/api/v2/entities/orgunits/${orgUnitId}/cultivations/fields/${harvestYear}`)
        .then(response => response.data)
    ])
      .then(([cultivation, fieldsForCultivation]) => {
        cultivation.fieldIds = fieldsForCultivation[cultivation.id] || []
        commit('updateCultivation', cultivation)
        commit('editBuffer/reset', cultivation)
      })
    : commit('editBuffer/reset')

  // TODO remove later
  const initializeNewCultivation = () => {
    const additionalDefaults = {
      fieldIds: [fieldId],
      geometry: [rootState.fieldRecordSystem.data.field[fieldId].wkt]
    }

    commit('editBuffer/reset', additionalDefaults)
  }

  commit('fieldRecordSystem/setDataLoading', true, {root: true})

  return loadPrerequisiteData
    .then(cultivationId ? loadExistingCultivation : initializeNewCultivation)
    .then(commit('resetPredictedCrops'))
    .then(() => {
      commit('fieldRecordSystem/setDataLoading', false, {root: true})
    })
    .catch(error => {
      console.error('error while starting cultivation planning')
      console.error(error)
      commit('fieldRecordSystem/setDataLoading', false, {root: true})
    })
}

export function reloadCultivationsForField ({commit, rootState}) {
  const fieldId = rootState.fieldRecordSystem.navigation.location.fieldId // TODO change update guard to orgunit
  const harvestYear = rootState.fieldRecordSystem.userSettings.harvestYear

  return axios.get(`/api/v2/entities/fields/${fieldId}/cultivations/${harvestYear}`)
    .then(response => response.data)
    .then(cultivations => {
      if (fieldId === rootState.fieldRecordSystem.navigation.location.fieldId) {
        commit('setCultivationsForField', cultivations.map(x => x.id)) // TODO instead replace cultivations on orgunit
      }
    })
}

export const editCultivation = ({rootState, commit, dispatch}, cultivationId) => {
  // action is called when adding a cultivation on field level

  const loadPrerequisiteData = Promise.all([
    dispatch('reloadMixtures'),
    dispatch('fieldRecordSystem/loadFieldGeometriesForCurrentOrgUnit', null, {root: true})
  ])

  let loadExistingCultivation = null

  cultivationId
    ? loadExistingCultivation = () => axios.get(`/api/v2/cultivations/${cultivationId}`)
      .then(response => response.data)
      .then(cultivation => {
        // commit('updateCultivation', cultivation)
        commit('editBuffer/reset', cultivation)
      })
    : commit('editBuffer/reset')

  const initializeNewCultivation = () => {
    const selectedFieldId = rootState.fieldRecordSystem.navigation.location.fieldId

    if (!selectedFieldId) {
      throw new Error('[cultivation/resetEditBuffer] cannot create cultivation without selected field')
    }
    const additionalDefaults = {
      geometry: [rootState.fieldRecordSystem.data.field[selectedFieldId].wkt]
    }

    commit('editBuffer/reset', additionalDefaults)
    return dispatch('predictNextCrop')
  }

  const editCultivationPlanning = () => {
    if (rootState.fieldRecordSystem.navigation.location.fieldId) {
      commit('fieldRecordSystem/setRightView', 'cultivationPlanning', {root: true})
    } else {
      commit('fieldRecordSystem/setRightView', 'cultivationPlanningFromOrgUnit', {root: true})
    }
  }

  commit('fieldRecordSystem/setDataLoading', true, {root: true})

  return loadPrerequisiteData
    .then(cultivationId ? loadExistingCultivation : initializeNewCultivation)
    .then(commit('resetPredictedCrops'))
    .then(editCultivationPlanning)
    .then(() => {
      commit('fieldRecordSystem/setDataLoading', false, {root: true})
    })
    .catch(error => {
      console.error('error while starting cultivation planning')
      console.error(error)
      commit('fieldRecordSystem/setDataLoading', false, {root: true})
    })
}

export const predictNextCrop = ({commit, rootState}) => {
  const fieldId = rootState.fieldRecordSystem.navigation.location.fieldId

  const harvestYear = rootState.fieldRecordSystem.userSettings.harvestYear

  return axios.get(`/api/v2/entities/fields/${fieldId}/data/crop-usage/next/${harvestYear}`)
    .then(response => response.data)
    .then(({cropUsageId}) => {
      commit('editBuffer/set', {cropUsageId})
    })
}

export const reloadMixtures = ({commit, rootState}) => {
  const {orgUnitId} = rootState.fieldRecordSystem.navigation.location
  if (can(rootState.permissions, 'Use.Frs.Cultivations')) {
    return smarterGet([
      '/api/v2/entities/orgunits/{orgUnitId}/crop-mixtures/withproductnames'
    ], {
      id: 'reload.cropMixtures',
      expiry: 120,
      inputs: {
        orgUnitId: () => orgUnitId
      },
      sharedCache: true,
      onResult ([catchCropMixtures]) {
        commit('setMixtures', catchCropMixtures)
      }
    })
  } else {
    return Promise.resolve()
  }
}
