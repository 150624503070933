export default {
  next ({state, commit}) {
    switch (state.ui.wizardStep) {
    case 'stepNitrogen':
      commit('setStep', 'stepPhosphorus')
      break
    case 'stepPhosphorus':
      commit('setStep', 'result')
      break
    }
  },
  back ({state, commit}) {
    switch (state.ui.wizardStep) {
    case 'stepPhosphorus':
      commit('setStep', 'stepNitrogen')
      break
    case 'result':
      commit('setStep', 'stepPhosphorus')
      break
    }
  },
  cancel ({commit}) {
    commit('editBuffer/reset')
    commit('fieldRecordSystem/setRightView', 'default', {root: true})
  }
}
