export function partition (array, predicate) {
  const trueItems = []
  const falseItems = []
  for (const item of array) {
    (predicate(item) ? trueItems : falseItems).push(item)
  }
  return [trueItems, falseItems]
}

export function zip (array1, array2) {
  if (array1.length !== array2.length) {
    throw new Error('cannot zip arrays of different lengths')
  }

  return array1.map((x, i) => [x, array2[i]])
}

export function range (lengthOrStart, stop, step) {
  if (stop === undefined) {
    return new Array(lengthOrStart).fill(null).map((x, i) => i)
  }

  if (step === undefined) {
    return new Array(stop - lengthOrStart + 1).fill(null).map((x, i) => lengthOrStart + i)
  }

  const count = Math.floor((stop - lengthOrStart + 1) / step)

  return new Array(count).fill(null).map((x, i) => lengthOrStart + i * step)
}
