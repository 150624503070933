import axios from 'axios'

import {smarterGet} from '@helpers/vuex/data-loading'

export function startBasicFertilization ({commit, rootGetters, dispatch}) {
  return Promise.all([
    dispatch('i18n/preloadTranslations', ['bf'], {root: true}),
    dispatch('loadStatistics'),
    dispatch('loadSimplifiedFieldGeometriesForCurrentOrgUnit'),
    // depending on cultivationPlanning is actually quite terrible, but FrsFieldSelection does so anyways
    dispatch('fieldRecordSystem/cultivationPlanning/reloadCultivationsForOrgUnit', null, {root: true})
  ]).then(() => commit('fieldRecordSystem/setRightView', 'basicFertilization', {root: true}))
}

export async function navigateToStartPage ({commit, dispatch}) {
  await dispatch('loadStatistics')
  commit('fieldRecordSystem/setRightView', 'basicFertilization', {root: true})
}

export function loadSimplifiedFieldGeometriesForCurrentOrgUnit ({state, getters, commit, rootState, rootGetters}) {
  const orgUnitId = rootGetters['fieldRecordSystem/orgUnitId']
  const harvestYear = rootGetters['fieldRecordSystem/harvestYear']

  if (!orgUnitId) return
  const visibleOnly = rootGetters['fieldRecordSystem/entityLookup'][orgUnitId].visibleOnly
  if (visibleOnly) {
    commit('setSimplifiedGeometries', null)
    return
  }

  return smarterGet('/api/v2/entities/orgunits/{orgUnitId}/fields/simplified-geodata/{harvestYear}', {
    id: 'orgUnit.basic-fertilization.simplified-geometries',
    expiry: 60,
    inputs: {
      orgUnitId: () => orgUnitId,
      harvestYear: () => harvestYear
    },
    async onResult (geometryLookup) {
      commit('setSimplifiedGeometries', geometryLookup)
    },
    subtreeInclusive: true
  })
}

export function loadStatistics ({rootGetters, commit}) {
  const orgUnitId = rootGetters['fieldRecordSystem/orgUnitId']
  const harvestYear = rootGetters['fieldRecordSystem/harvestYear']

  return smarterGet([
    '/api/v2/base-fertilization/fields/{orgUnitId}/statistics/{harvestYear}'
  ], {
    id: 'bf.field.statistics',
    expiry: 60,
    inputs: {
      orgUnitId: () => orgUnitId,
      harvestYear: () => harvestYear
    },
    onResult (result) {
      commit('setFieldStatisticsLookup', result[0])
    }
  })
}

export function createNewOrder ({commit, rootGetters, dispatch}) {
  return dispatch('fieldRecordSystem/basicFertilization/planning/reloadProbingSitesForOrgUnit', null, {root: true})
  .then(probingSite => {
    commit('initializeOrder', probingSite)
    commit('orderCreation/setFieldIds', [])
    commit('generateGeometry', {entityNameLookup: rootGetters['fieldRecordSystem/entityNameLookup']})
    commit('fieldRecordSystem/setRightView', 'samplingOrderCreation', {root: true})
  })
}

export function saveOrder ({state, dispatch, rootGetters}, order) {
  const orgUnitId = rootGetters['fieldRecordSystem/orgUnitId']

  return axios.post(`/api/v2/entities/orgunits/${orgUnitId}/base-fertilization/orders`, order || state.data.order)
  .then(response => response.data)
  .then(({id}) => {
    dispatch('exportOrders', id)
  })
}

export function exportOrders ({commit, rootGetters}, id) {
  const orgUnitId = rootGetters['fieldRecordSystem/orgUnitId']
  // get open orders from server
  return axios.get(`/api/v2/entities/orgunits/${orgUnitId}/base-fertilization/orders/open`)
  .then(res => res.data)
  .then(orders => {
    commit('orderExport/setOpenOrders', orders)

    if (id) {
      commit('orderExport/setSelectedOrder', orders.find(x => x.id === id) || null)
    } else if (orders.length) {
      commit('orderExport/setSelectedOrder', orders[0])
    }

    commit('fieldRecordSystem/setRightView', 'probingOrderExport', {root: true})
  })
}

export function startNutrientMapDisplay ({commit}) {
  commit('fieldRecordSystem/setRightView', 'nutrientMapDisplay', {root: true})
}
