import cropRotationMutations from './crop-rotation'
import navigationMutations from './navigation'
import layout from './layout'
import entity from './entity'
import masterData from './master-data'
import userData from './user-data'

import * as zonemap from './zonemap'

const determineEditingComponent = (state) => {
  if (state.ui.view.right === 'default' && state.navigation.location.fieldId) {
    return 'FieldEditingTools'
  }
  if (['editCultivationPlanningOrgUnit', 'cultivationPlanningOrgUnit'].includes(state.ui.view.right)) {
    return 'CultivationEditingTools'
  }
  if ([
    'actionPlanningFromOverview',
    'actionPlanningForSingleField',
    'actionPlanningForSingleFieldFromList'
  ].includes(state.ui.view.right)) {
    return 'ActionEditingTools'
  }
  if (state.ui.view.right === 'lanePlanning') {
    return 'LanePlanningEditingTools'
  }
}

const mutations = {
  ...navigationMutations,
  ...cropRotationMutations,
  ...layout,
  ...entity,
  ...masterData,
  ...userData,
  ...zonemap,
  setEditingComponent (state, componentName) {
    state.map.editing.component = componentName || determineEditingComponent(state)

    if (state.map.editing.active && !state.map.editing.component) {
      console.error('no valid editing tools component for current state')
    }
  },
  setMainPageLoading (state, value) {
    state.ui.mainPageLoading = value
  }
}

export default mutations
