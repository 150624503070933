import {makeSetters} from '@helpers/vuex/mutations'

const defaults = () => ({
  id: '',
  type: 'main',
  dateTimeRange: {
    start: null,
    end: null
  },
  subsidies: {
    ecoPriorityArea: false,
    other: false
  },
  fieldIds: [],
  entityId: null,
  harvestYears: [],
  geometry: [],
  cropUsageId: null,
  // main
  expectedYield: 0,
  expectedYieldUnit: 'dt/ha',
  actualYield: null,
  // catch, eco+catch
  mixtureComponents: null,
  // eco
  ecoTypeId: null
})

export default {
  namespaced: true,
  state: defaults(),
  getters: {
    isEcoPriorityArea (state) {
      return state.type === 'eco' || (state.type === 'catch' && state.subsidies.ecoPriorityArea)
    }
  },
  mutations: {
    ...makeSetters([
      'subsidies',
      'fieldIds',
      'dateTimeRange',
      'harvestYears',
      'ecoTypeId',
      'mixtureComponents',
      'cropUsageId',
      'type',
      'actualYield',
      'expectedYield',
      'expectedYieldUnit',
      'geometry'
    ]),
    set (state, partialCultivation) {
      if ('type' in partialCultivation && state.type !== partialCultivation.type) {
        const {subsidies, cropUsageId, mixtureComponents} = defaults()
        Object.assign(partialCultivation, {subsidies, cropUsageId, mixtureComponents})
      }

      Object.assign(state, partialCultivation)
    },
    reset (state, partialCultivation) {
      Object.assign(state, defaults(), partialCultivation)
      if (partialCultivation && typeof partialCultivation.geometry === 'string') {
        state.geometry = [partialCultivation.geometry]
      }
    }
  }
}
