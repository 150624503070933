import axios from 'axios'
import {cloneDeep} from 'lodash'

/**
 * Adapter function to convert current editBuffer structure to more useful format
 * @param {string[]} fieldIds
 * @param {object[]} combinationParts
 * @returns
 */
function groupByField(fieldIds, combinationParts) {
  return fieldIds.reduce((lookup, fieldId) => {
    lookup[fieldId] = combinationParts.map(
      ({
        id, // does not exist in editBuffer right now, but will need to exist for editing
        type,
        procedure,
        details: {
          [fieldId]: {
            actualArea, // does not exist in editBuffer right now, but will need to exist for saving/editing
            parameters,
            productQuantities,
          },
        },
      }) => ({
        id,
        type,
        procedure,
        parameters,
        productQuantities,
        actualArea,
      })
    )

    return lookup
  }, {})
}

export default async function({
  state,
  getters,
  rootState,
  rootGetters,
  commit,
}) {
  const orgUnitId = rootGetters['fieldRecordSystem/orgUnitId']

  const {harvestYear} = rootState.fieldRecordSystem.userSettings

  const {
    id,
    timestamps,
    personnel,
    machines,
    notes,
    fieldIds,
  } = state.editBuffer

  const sharedProperties = {
    id,
    timestamps,
    personnel,
    machines,
    notes,
  }

  if (id && fieldIds.length > 1) {
    throw new Error(
      'Editing is currently only supported for a single action/field'
    )
  }

  const actionPartsByFieldId = groupByField(
    fieldIds,
    state.data.planningWizard.combinationParts
  )

  const makeActionWithParts = fieldId =>
    cloneDeep({
      ...sharedProperties,
      fieldId,
      geometry: getters['editBuffer/finalActionFeatures'][fieldId].geometry,
      planningParts: actionPartsByFieldId[fieldId],
    })

  const actions = fieldIds.map(makeActionWithParts, [])

  await axios.post(`/api/v2/${harvestYear}/orgs/${orgUnitId}/actions`, actions)

  // TODO figure out why field actions are brute-force reloaded, but org actions were not
  // TODO find out what needs reloading and what doesn't
  // await dispatch('loadActionsForOrgUnit')
  // await dispatch('loadActionsForCurrentField')

  const {saveTarget} = state.navigationSource
  commit('fieldRecordSystem/setRightView', saveTarget, {root: true})
}
