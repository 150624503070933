import mutations from './mutations'
import * as actions from './actions'
import * as getters from './getters'
import navigation from './navigation'
import cultivationPlanning from './cultivation-planning'
import cultivationOverview from './cultivation-overview'
import action from './action'
import machines from './machines'
import persons from './persons'
import map from './map'
import lanePlanning from './lane-planning'
import nutrientRequirement from './nutrient-requirement'
import basicFertilization from './basic-fertilization'
import fieldDetails from './field-details'
import companyData from './company-data'
import nutritionStatus from './nutrition-status'
import simpleApplicationMaps from './simple-application-maps'
import maizeSowing from './maize-sowing'
import soilDetail from './soil-detail'
import entityImport from './entity-import'
import defaultYields from './default-yields'
import applicationMaps from './application-maps'
import download from './download'
import telemetry from './telemetry'
import simonModule from './simon-module'
import mapManagement from './map-management'
import migration from './migration'

import transitions from './transitions'

const state = {
  ui: {
    transitions,
    view: {
      left: 'rocket', // 'rocket', 'legacy', 'groups'
      right: 'default',
      expanded: false,
      rightCollapsed: false,
      leftCollapsed: false
    },
    filter: {
      cultivation: {}
    },
    workflow: null,
    waitscreen: false,
    loadingData: false,
    mainPageLoading: false,
    geometryEditing: {
      active: false,
      component: null
    },
    gettingZoneMap: false,
    entityStructureEditing: false
  },
  userSettings: {
    harvestYear: null,
    tags: {
      fields: {}
    },
    favorites: {
      cropUsages: [],
      products: []
    },
    ui: {}
  },
  data: {
    orgUnits: {
      // TODO pull upwards when references are fixed
      data: {}
    },
    field: {},
    crops: {},
    soilTypes: [],
    soilSubTypes: [],
    usageTypes: [],
    companyForms: [],
    legalForms: [],
    waterExtractionSources: []
  },
  permissions: [],
  cropRotations: {
    root: [],
    company: [],
    field: null
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
  modules: {
    navigation,
    cultivationPlanning,
    cultivationOverview,
    action,
    machines,
    persons,
    map,
    lanePlanning,
    nutrientRequirement,
    basicFertilization,
    fieldDetails,
    companyData,
    nutritionStatus,
    soilDetail,
    entityImport,
    defaultYields,
    simpleApplicationMaps,
    maizeSowing,
    applicationMaps,
    download,
    telemetry,
    simonModule,
    mapManagement,
    migration
  }
}
