import * as actions from './actions'
import mutations from './mutations'
import * as getters from './getters'
import laneEditBuffer from './lane-edit-buffer'
import partEditBuffer from './part-edit-buffer'

const state = {
  data: {
    lanePlansField: {}, // keyed by lane id
    planningProfiles: ['ab', 'line'],
    targetSystems: ['Shape', 'MuellerShape', 'ISOXML', 'ISOXML_v4', 'Topcon', 'Trimble', 'TrimblePIQ'],
    selectedLanePlans: [],
    lanePlansOrgUnit: {},
    totalNumberOfPlans: 0,
    selectedPlan: null,
    expandedNodes: [],
    selectedNodes: [],
    lanePlanNamesForField: []
  },
  ui: {
    isCreateAbLineActive: false,
    hideFooter: false,
    selection: {
      planId: '',
      isFromDetailsView: false,
      format: 'Shape'
    },
    planning: {
      step: null
    },
    features: {
      partialFieldFeatures: [],
      segmentFeatures: [],
      headlandFeatures: [],
      headlandLaneFeatures: [],
      laneFeatures: []
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules: {
    laneEditBuffer,
    partEditBuffer
  }
}
