import Vue from 'vue'
import {parse} from 'wellknown'
import {cloneDeep} from 'lodash'

import {simplifyLowQuality} from '@helpers/geojson/simplification'

const mutations = {
  setEcoTypes (state, types) {
    state.data.ecoTypes = types
  },
  setCultivationTypes (state, types) {
    state.data.cultivationTypes = types
  },
  updateCultivation (state, cultivation) {
    Vue.set(state.data.selection.orgUnit, cultivation.id, cultivation)
  },
  updateCultivationGeometry (state, {cultivationId, geometry: wkt}) {
    if (cultivationId in state.data.selection.orgUnit) {
      const cultivation = state.data.selection.orgUnit[cultivationId]
      const geojsonGeometry = parse(wkt)
      cultivation.geometry = wkt

      cultivation.geojsonGeometry = geojsonGeometry

      const simplifiedGeometry = cloneDeep(geojsonGeometry)
      // TODO find out why we have cultivations with empty geometries
      if (simplifiedGeometry && simplifiedGeometry.coordinates.length) {
        simplifyLowQuality(simplifiedGeometry)
      }

      cultivation.simplifiedGeometry = simplifiedGeometry
    }
  },
  setCultivationsForField (state, cultivationIds) {
    state.data.selection.field = cultivationIds
  },
  setCultivationsForOrgUnit (state, cultivations) {
    state.data.selection.orgUnit = {}
    cultivations.forEach(cultivation => {
      const geometry = parse(cultivation.geometry)

      cultivation.geojsonGeometry = geometry

      const simplifiedGeometry = cloneDeep(geometry)
      // TODO find out why we have cultivations with empty geometries
      if (simplifiedGeometry && simplifiedGeometry.coordinates.length) {
        simplifyLowQuality(simplifiedGeometry)
      }

      cultivation.simplifiedGeometry = simplifiedGeometry

      Vue.set(state.data.selection.orgUnit, cultivation.id, cultivation)
    })

    state.data.selection.field = [] // could be changed to just prune invalid ids if necessary
  },
  deleteCultivation (state, cultivationId) {
    state.data.selection.field = state.data.selection.field.filter(id => id !== cultivationId)
    Vue.delete(state.data.selection.orgUnit, cultivationId)
  },
  setPredictedCrop (state, {fieldId, cropUsageId}) {
    Vue.set(state.predictedCrops, fieldId, cropUsageId || null)
  },
  clearPredictedCrop (state, fieldId) {
    Vue.delete(state.predictedCrops, fieldId)
  },
  resetPredictedCrops (state) {
    state.predictedCrops = {}
  },
  setMixtures (state, mixtures) {
    state.data.mixtures = mixtures
  }
}

export default mutations
