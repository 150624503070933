import mutations from './mutations'
import actions from './actions'

const state = {
  data: {
    applicationMetaData: [],
    yieldMetaData: []
  },
  creation: {
    yieldData: [],
    applicationData: [],
    numberZones: 5,
    numberYears: 3,
    maxApplicationValue: null,
    mapName: null,
    productId: null
  },
  applicationDetails: {
    selectedMapId: null,
    id: null,
    minValue: null,
    maxValue: null,
    withdrawalFactor: null,
    number: null,
    name: ''
  },
  yieldDetails: {
    id: null,
    minValue: null,
    maxValue: null,
    selectedYieldMapId: null,
    actualYield: null,
    pFactor: null,
    number: null,
    name: ''
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  modules: {
    // more sub-modules
  }
}
