export const lookup = (objects, keyFunction, valueFunction = x => x) => {
  const lookup = {}
  objects.forEach(x => {
    lookup[keyFunction(x)] = valueFunction(x)
  })
  return lookup
}

export const groupBy = (objects, keyFunction, valueFunction = x => x) => {
  const grouped = {}
  objects.forEach(x => {
    const key = keyFunction(x)
    if (!(key in grouped)) {
      grouped[key] = []
    }
    grouped[key].push(valueFunction(x))
  })
  return grouped
}

export const idLookup = (objects, valueFunction = x => x) => lookup(objects, x => x.id, valueFunction)

export const parametricReducers = {
  lookup: (keyFunction, valueFunction = x => x) => (lookup, obj) => {
    lookup = lookup || {}
    lookup[obj.id] = valueFunction(obj)
    return lookup
  }
}

export const reducers = {

}
