import axios from 'axios'
import {toWkt, geoJsonFormat} from '@helpers/openlayers/features'

import moment from 'moment'

export default {
  importYieldData ({commit}, geojson) {
    const features = geoJsonFormat.readFeatures(geojson, {dataProjection: 'EPSG:4326', featureProjection: 'EPSG:3857'})
    commit('importYieldData', features)
  },
  save ({state, commit, rootGetters}) {
    const measurements = state.features.map((feature) => {
      return {
        value: parseFloat(feature.getProperties()[state.valueKey.value]),
        timestamp: state.timestampKey ? moment(Date(feature.getProperties()[state.timestampKey.value])) : feature.getProperties()[state.timestampKey],
        geometry: toWkt(feature)
      }
    })

    const {name, date: creation, cropUsageId, productId, unit} = state

    const metadata = {name, creation, cropUsageId, productId, unit}

    const orgUnitId = rootGetters['fieldRecordSystem/orgUnitId']
    const fieldId = rootGetters['fieldRecordSystem/fieldId']
    const url = `/api/v2/telemetry/orgunits/${orgUnitId}/${fieldId}/${state.type.value}`

    return axios.post(url, {measurements, metadata})
    .then(commit('reset'))
    .then(commit('fieldRecordSystem/setRightView', 'telemetry', {root: true}))
  }
}
