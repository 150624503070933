import axios from 'axios'
import {cloneDeep, uniq, uniqBy} from 'lodash'

const getFlattenedProbingSites = (state, key) => cloneDeep(Object.entries(state.probingSitesByFieldId)
  .reduce((all, [fieldId, probingSites]) => all.concat(probingSites[key].map(probingSite => ({...probingSite, fieldId}))), []))

export async function startOrderPlanning ({rootState, commit, rootGetters}, orderId) {
  const orgUnitId = rootGetters['fieldRecordSystem/orgUnitId']
  const {harvestYear} = rootState.fieldRecordSystem.userSettings

  await Promise.all([
    axios.get(`/api/v2/base-fertilization/orgunits/${orgUnitId}/orders/incomplete`).then(response => response.data),
    axios.get(`/api/v2/base-fertilization/orgunits/${orgUnitId}/probing-sites/${harvestYear}/by-field`).then(response => response.data)
  ])
  .then(([orders, probingSitesByFieldId]) => {
    commit('setIncompleteOrders', orders)

    commit('setProbingSitesByFieldId', probingSitesByFieldId)

    if (orderId) {
      commit('setSelectedOrderId', orders.find(x => x.id === orderId).id)
    }
  })

  commit('fieldRecordSystem/setRightView', 'samplingOrderPlanning', {root: true})
}

export function cancelOrderPlanning ({commit}) {
  commit('fieldRecordSystem/setRightView', 'basicFertilization', {root: true})
}

export async function saveOrder ({state, getters, commit, dispatch, rootGetters}, continueToNextStep) {
  commit('finishField')

  const orgUnitId = rootGetters['fieldRecordSystem/orgUnitId']
  const entityNameLookup = rootGetters['fieldRecordSystem/entityNameLookup']
  const selectedOrder = getters['selectedOrder']

  const deletedIds = uniq(getFlattenedProbingSites(state, 'deleted')).map(x => x.id)
  const existingProbingSites = uniqBy(getFlattenedProbingSites(state, 'existing'), 'id').filter(probingSite => selectedOrder.selectedFieldIds.includes(probingSite.fieldId))

  const added = getFlattenedProbingSites(state, 'new').filter(probingSite => !deletedIds.includes(probingSite.id))

  added.forEach(probingSite => {
    probingSite.id = null // NOTE proper id is generated server-side on save
  })

  const updated = existingProbingSites.filter(probingSite => !deletedIds.includes(probingSite.id))
  const deleted = existingProbingSites.filter(probingSite => deletedIds.includes(probingSite.id))
  const planningProbingSites = {added, updated, deleted}
  const addedIds = await axios.post(`/api/v2/base-fertilization/orgunits/${orgUnitId}/probing-sites`, planningProbingSites)
    .then(response => response.data)

  addedIds.forEach((id, i) => {
    added[i].id = id // NOTE add server-generated id to each new probingSite
  })

  const samplingOrderGeometries = added
    .concat(updated)
    .filter(x => x.isActive)
    .map(({geometry, label, fieldIds, fieldId}) => {
      const fieldNames = fieldIds && fieldIds
        .filter(id => selectedOrder.selectedFieldIds.includes(id))
        .map(fieldId => entityNameLookup[fieldId])
        .join(', ')

      const fieldName = entityNameLookup[fieldId]

      return {
        sampleId: label,
        geometry: geometry,
        fieldName: fieldNames || fieldName
      }
    })

  await axios.put(`/api/v2/base-fertilization/orders/${state.selectedOrderId}/geometries`, samplingOrderGeometries)

  if (continueToNextStep) {
    return dispatch('fieldRecordSystem/basicFertilization/exportOrders', null, {root: true})
  } else {
    return dispatch('fieldRecordSystem/basicFertilization/startBasicFertilization', null, {root: true})
  }
}
