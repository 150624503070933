import wizardSteps from './wizard-steps'
import {procedureParameterSteps} from './action-hierarchy'

const nextFromArray = (array, current) => array[array.indexOf(current) + 1]
const previousFromArray = (array, current) => array[array.indexOf(current) - 1]

export function next ({state, commit, dispatch}) {
  const {type, procedure} = state.editBuffer

  const {step: current} = state.ui.planningWizard

  if (current === 'start') {
    commit('fieldRecordSystem/setWorkflow', {'fieldSelection': false}, {root: true})
  }

  const parameterSteps = procedureParameterSteps(type, procedure)

  if (current === 'parameters') {
    const nextParameterStep = nextFromArray(parameterSteps, state.ui.planningWizard.parameterStep)

    if (nextParameterStep) {
      commit('setParameterStep', nextParameterStep)
      return
    } else {
      const {partEditingIndex} = state.ui.planningWizard

      if (partEditingIndex === null) {
        commit('addCombinationPart')
      } else {
        commit('replaceCombination', partEditingIndex)
        // commit('setCombinationActionWasEdited')
        // commit('setActionIndex', null)
      }

      commit('editBuffer/partialReset')

      // loop back to inner loop start for combination action
      commit('setWizardStep', 'combinationStart')

      return
    }
    // fall through to next wizard step
  }

  const nextStep = nextFromArray(wizardSteps.combination, current)

  if (nextStep) {
    // display action type selection without summary page when entering wizard first time
    if (nextStep === 'combinationStart' && !state.data.planningWizard.combinationParts.length) {
      commit('setWizardStep', 'type')
      return
    }
    commit('setWizardStep', nextStep)

    if (nextStep === 'type') {
      commit('setWizardStep', 'misc')
    }

    if (nextStep === 'parameters') {
      if (parameterSteps.length) {
        // instantiate default parameter object in editBuffer
        commit('setParameterStep', parameterSteps[0])

        commit('editBuffer/resetDetails')
      } else {
        // skip parameter step if this procedure has no parameter form
        return dispatch('next')
      }
    }
  }
}

export function back ({state, commit, dispatch}) {
  const {type, procedure} = state.editBuffer

  const {step: current} = state.ui.planningWizard

  const parameterSteps = procedureParameterSteps(type, procedure)

  if (current === 'type' || current === 'combinationStart') {
    commit('fieldRecordSystem/setWorkflow', {'fieldSelection': true}, {root: true})
  }

  if (current === 'parameters') {
    const previousParameterStep = previousFromArray(parameterSteps, state.ui.planningWizard.parameterStep)

    if (previousParameterStep) {
      commit('setParameterStep', previousParameterStep)

      return
    }
    // fall through to previous wizard step
  }

  const validSteps = wizardSteps.combination.filter(x => x !== 'status')

  const previousStep = previousFromArray(validSteps, current)

  if (previousStep) {
    commit('setWizardStep', previousStep)

    if (previousStep === 'parameters') {
      commit('setWizardStep', 'combinationStart')
    }
  }
}
