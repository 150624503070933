export const currentSelectedFieldId = (state, getters, rootState) => rootState.fieldRecordSystem.navigation.location.fieldId

export const cultivationsForSelectedField = (state) => {
  // state.data.selection.field contains all cultivation ids the selected field currently has
  // state.data.selection.orgUnit contains all cultivations the current orgUnit has
  return state.data.selection.field.map(id => state.data.selection.orgUnit[id])
}

/*
{
  fieldId1: [cultivation1, cultivation2...],
  fieldId2: [cultivation3, cultivation4...],
  fieldId3: [cultivation1, cultivation5...]
}
*/
export const cultivationsByFieldId = (state) => {
  const lookup = {}

  Object.keys(state.data.selection.orgUnit).forEach((cultivationId) => {
    const cultivation = state.data.selection.orgUnit[cultivationId]
    if (cultivation.fieldIds) { // workaround for timing issue when editing cultivations
      cultivation.fieldIds.forEach((fieldId) => {
        if (!lookup[fieldId]) {
          lookup[fieldId] = []
        }
        lookup[fieldId].push(cultivation)
      })
    }
  })
  return lookup
}

/*
{
  main: [...fieldIds],
  secondary: [...fieldIds],
  catch: [...fieldIds],
  eco: [...fieldIds]
}
*/
export const fieldIdsByCultivationType = (state) => {
  const lookup = {}

  const cultivations = state.data.selection.orgUnit
  Object.keys(cultivations).forEach((cultivationId) => {
    const cultivation = cultivations[cultivationId]
    if (!lookup[cultivation.type]) {
      lookup[cultivation.type] = []
    }

    if (cultivation.fieldIds) { // workaround for iming issue when editing cultivations
      cultivation.fieldIds.forEach((id) => {
        if (!lookup[cultivation.type].includes(id)) {
          lookup[cultivation.type].push(id)
        }
      })
    }
  })
  return lookup
}

export const fieldIdsByCropId = (state) => {
  const lookup = {}
  const cultivations = state.data.selection.orgUnit
  Object.keys(cultivations).forEach((cultivationId) => {
    const cultivation = cultivations[cultivationId]

    if (!cultivation.cropUsageId) return

    if (!lookup[cultivation.cropUsageId]) {
      lookup[cultivation.cropUsageId] = []
    }

    if (cultivation.fieldIds) { // workaround for timing issue when editing cultivations
      cultivation.fieldIds.forEach((fieldId) => {
        if (!lookup[cultivation.cropUsageId].includes(fieldId)) {
          lookup[cultivation.cropUsageId].push(fieldId)
        }
      })
    }
  })

  return lookup
}
