import {makeSetters} from '@helpers/vuex/mutations'
import {flattenEntityForest, filters} from '@helpers/tree'
import {lookupById} from '@helpers/reducers'
import {smarterGet} from '@helpers/vuex/data-loading'

const addHistoryEntry = (state, newLocation) => {
  if (Object.keys(newLocation).some(key => newLocation[key] !== state.location[key])) {
    state.history.shift({...state.location})
  }
}

export default {
  namespaced: true,
  state: {
    entityForest: [], // forest from server
    location: {
      // rocket & legacy tree
      fieldId: null,
      orgUnitId: null,
      // tag display, should this really be here?
      tag: null
    },
    history: [],
    search: {
      text: '',
      result: null,
      searching: false
    },
    simplifiedGeometries: {}
  },
  getters: {
    entities (state) {
      return flattenEntityForest(state.entityForest)
    },
    subTree (state, getters) {
      return state.location.orgUnitId ? getters.entityLookup[state.location.orgUnitId] : null
    },
    flattenedSubTree (state, getters) {
      return getters.subTree ? flattenEntityForest([getters.subTree]) : []
    },
    subTreeOrgUnits (state, getters) {
      return getters.flattenedSubTree.filter(filters.orgUnits)
    },
    subTreeFields (state, getters) {
      return getters.flattenedSubTree.filter(filters.fields)
    },
    entityLookup (state, getters) {
      return getters.entities.reduce(lookupById, {})
    },
    parentLookup (state, getters) {
      const lookup = {}
      getters.entities.filter(filters.orgUnits).forEach(orgUnit => {
        orgUnit.children.forEach(child => {
          lookup[child.id] = orgUnit.id
        })
      })
      return lookup
    },
    rootOrgUnitId (state, getters) {
      let entity = state.location.orgUnitId
      while (getters.parentLookup[entity]) {
        entity = getters.parentLookup[entity]
      }
      return entity
    }
  },
  mutations: {
    ...makeSetters([
      'searching',
      'fieldId'
    ]),
    setEntityForest (state, forest) {
      state.entityForest = forest

      const lookup = flattenEntityForest(forest).reduce(lookupById, {})

      if (!lookup[state.location.orgUnitId]) {
        state.location.orgUnitId = null
        state.location.fieldId = null
      }
      if (!lookup[state.location.fieldId]) {
        state.location.fieldId = null
      }
    },
    setEntitySubTree (state, {orgUnitId, subtree, entityLookup}) {
      const orgUnit = entityLookup[orgUnitId]
      orgUnit.children = subtree.children
    },
    addEntity (state, {entity, orgUnitId, entityLookup}) {
      const parent = entityLookup[orgUnitId]

      if (parent) {
        parent.children.push(entity)
      } else {
        state.entityForest.push(entity)
      }
    },
    removeEntity (state, {entityId, entityLookup, parentLookup}) {
      const parentId = parentLookup[entityId]
      const parent = entityLookup[parentId]

      if (parent) {
        parent.children = parent.children.filter(x => x.id !== entityId)
      } else {
        state.entityForest = state.entityForest.filter(x => x.id !== entityId)
      }

      if (state.location.fieldId === entityId) {
        state.location.fieldId = null
      }

      if (state.location.orgUnitId === entityId) {
        state.location.fieldId = null
        state.location.orgUnitId = parentId || null
      }
    },
    renameEntity (state, {entityId, name, entityLookup}) {
      entityLookup[entityId].name = name
    },
    selectOrgUnit (state, orgUnitId) {
      const location = {orgUnitId, fieldId: null, tag: null}
      addHistoryEntry(state, location)
      state.location = location
    },
    selectField (state, {fieldId, parentLookup}) {
      const location = {orgUnitId: parentLookup[fieldId], fieldId, tag: null}
      if (!location.orgUnitId) {
        throw new Error(`invalid field without parent: ${fieldId}`)
      }
      addHistoryEntry(state, location)
      state.location = location
    },
    selectTag (state, tag) {
      const location = {orgUnitId: null, fieldId: state.location.fieldId, tag}
      addHistoryEntry(state, location)
      state.location = location
    },
    setSearchText (state, text) {
      state.search.text = text
      if (!text) {
        state.search.result = null
      }
    },
    setSearchResult (state, result) {
      state.search.result = result
    },
    setSearching (state, flag) {
      state.search.searching = flag
    },
    setSimplifiedGeometries (state, geometries) {
      state.simplifiedGeometries = {...state.simplifiedGeometries, ...geometries}
    }
  },
  actions: {
    addEntity ({getters, commit}, {entity, orgUnitId}) {
      const {entityLookup} = getters
      commit('addEntity', {entity, orgUnitId, entityLookup})
    },
    removeEntity ({getters, commit}, entityId) {
      const {entityLookup, parentLookup} = getters
      commit('removeEntity', {entityId, entityLookup, parentLookup})
    },
    renameEntity ({getters, commit}, {entityId, name}) {
      const {entityLookup} = getters
      commit('renameEntity', {entityId, name, entityLookup})
    },
    loadSimplifiedFieldGeometriesForCurrentOrgUnit ({state, getters, commit, rootState}) {
      if (!state.location.orgUnitId) return
      const visibleOnly = getters.entityLookup[state.location.orgUnitId].visibleOnly
      if (visibleOnly) {
        commit('setSimplifiedGeometries', null)
        return
      }

      return smarterGet('/api/v2/entities/orgunits/{orgUnitId}/fields/simplified-geodata/{harvestYear}', {
        id: 'orgUnit.simplified-geometries',
        expiry: 60,
        inputs: {
          orgUnitId: () => state.location.orgUnitId,
          harvestYear: () => rootState.fieldRecordSystem.userSettings.harvestYear
        },
        async onResult (geometryLookup) {
          commit('setSimplifiedGeometries', geometryLookup)
        },
        subtreeInclusive: true
      })
    }
  }
}
