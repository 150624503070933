import axios from 'axios'
import {orderBy} from 'lodash'

import {smarterGet} from '@helpers/vuex/data-loading'

export async function getNutrientRequirement ({rootState, rootGetters, commit, dispatch}) {
  await Promise.all([
    dispatch('loadCrops'),
    dispatch('loadPreviousCrops'),
    dispatch('loadCatchCrops')
  ])

  const fieldId = rootGetters['fieldRecordSystem/fieldId']
  const {harvestYear} = rootState.fieldRecordSystem.userSettings
  const url = `/api/v2/nutrient-requirements/${fieldId}/${harvestYear}`
  const nutrientRequirements = await axios.get(url).then(response => response.data)

  if (Object.values(nutrientRequirements).filter(x => x.harvestYear === harvestYear).length > 0) {
    commit('setStep', 'overview')
  } else {
    const fieldInfo = rootState.fieldRecordSystem.data.field[fieldId]
    let soilType = ''

    if (fieldInfo) {
      soilType = fieldInfo.soil ? fieldInfo.soil.soilType : null
    }
    const partialNutrientRequirement = {
      'harvestYear': harvestYear,
      'fieldId': fieldId,
      'typeOfSoil': soilType
    }
    commit('editBuffer/set', partialNutrientRequirement)
    commit('setStep', 'stepNitrogen')
  }

  commit('setNutrientRequirementsForField', {[fieldId]: nutrientRequirements})
  commit('fieldRecordSystem/setRightView', 'nutrientRequirement', {root: true})
}

export function loadCrops ({commit}) {
  return smarterGet('/api/v2/crops/nrc', {
    id: 'nrc.crops.main',
    expiry: 3600,
    onResult (crops) {
      let transformedCrops = orderBy(crops.map(crop => {
        const {
          KULTURNAME: name,
          ERTRAGSNIVEAU: expectedYield,
          STICKSTOFFBEDARFSWERT: nitrogenRequirement,
          P2O5_ENTZUEGE: phosphorusRequirement,
          DIFFERENZ: difference,
          ZUSCHLAEGE: increase,
          ABSCHLAEGE: decrease,
          ERNTERESTE_P2O5: phosphorusContentByproduct,
          HNV: mainToByproductRatio
        } = crop

        return {
          name,
          expectedYield,
          nitrogenRequirement,
          phosphorusRequirement,
          phosphorusContentByproduct,
          difference,
          increase,
          decrease,
          mainToByproductRatio
        }
      }), [x => x.name])

      commit('setCrops', transformedCrops)
    }
  })
}

export function loadPreviousCrops ({commit}) {
  return smarterGet('/api/v2/crops/previous', {
    id: 'nrc.crops.previous',
    expiry: 3600,
    onResult (crops) {
      let transformedCrops = orderBy(crops.map(crop => {
        const {
          VORFRUCHT: name,
          MINDESTABSCHLAG_KG_N_HA: minimumReduction
        } = crop

        return {
          name,
          minimumReduction
        }
      }), [x => x.name])

      const lookup = {}

      transformedCrops.forEach(crop => {
        lookup[crop.name] = crop.minimumReduction
      })

      commit('setPreviousCrops', lookup)
    }
  })
}

export function loadCatchCrops ({commit}) {
  return smarterGet('/api/v2/crops/catch', {
    id: 'nrc.crops.catch',
    expiry: 3600,
    onResult (crops) {
      let transformedCrops = orderBy(crops.map(crop => {
        const {
          VORFRUCHT: name,
          MINDESTABSCHLAG_KG_N_HA: minimumReduction
        } = crop

        return {
          name,
          minimumReduction
        }
      }), [x => x.name])

      const lookup = {}

      transformedCrops.forEach(crop => {
        lookup[crop.name] = crop.minimumReduction
      })

      commit('setCatchCrops', lookup)
    }
  })
}

export function stopPlanning ({commit}) {
  commit('editBuffer/reset')
}

const getNutrientRequirementId = rootState => {
  const harvestYear = rootState.fieldRecordSystem.userSettings.harvestYear
  const fieldId = rootState.fieldRecordSystem.navigation.location.fieldId

  const nutrientRequirement = rootState.fieldRecordSystem.nutrientRequirement.data.field[fieldId].find(x => x.harvestYear === harvestYear)

  return nutrientRequirement ? nutrientRequirement.id : null
}

export function saveCorrection ({state, commit, rootState}) {
  const correction = {...state.editBuffer.currentCorrection}
  const nutrientRequirementId = getNutrientRequirementId(rootState)
  const fieldId = rootState.fieldRecordSystem.navigation.location.fieldId

  const {harvestYear} = rootState.fieldRecordSystem.userSettings
  const url = `/api/v2/nutrient-requirements/${nutrientRequirementId}/corrections/${harvestYear}`

  commit('fieldRecordSystem/setDataLoading', true, {root: true})
  return axios.post(url, correction)
    .then(res => res.data)
    .then((id) => {
      correction.id = id
      commit('addCorrection', {fieldId, nutrientRequirementId, correction})
    })
}

export function deleteCorrection ({commit, dispatch, rootState}, {id}) {
  const {harvestYear} = rootState.fieldRecordSystem.userSettings
  const url = `/api/v2/nutrient-requirements/${getNutrientRequirementId(rootState)}/${id}/${harvestYear}`

  commit('fieldRecordSystem/setDataLoading', true, {root: true})
  return axios.delete(url)
    .then(() => dispatch('reloadCorrections'))
}

export function reloadCorrections ({commit, rootState}) {
  const nutrientRequirementId = getNutrientRequirementId(rootState)
  const fieldId = rootState.fieldRecordSystem.navigation.location.fieldId
  const {harvestYear} = rootState.fieldRecordSystem.userSettings

  if (nutrientRequirementId) {
    const url = `/api/v2/nutrient-requirements/${getNutrientRequirementId(rootState)}/corrections/${harvestYear}`
    return axios.get(url)
      .then(res => res.data)
      .then(corrections =>
        commit('setCorrections', {fieldId, id: nutrientRequirementId, corrections}))
  }
}

export async function saveRequirements ({state, dispatch, rootGetters, rootState}) {
  const fieldId = rootGetters['fieldRecordSystem/fieldId']
  const {harvestYear} = rootState.fieldRecordSystem.userSettings

  const geometry = (await axios.get(`/api/v2/entities/fields/${fieldId}/geodata/${harvestYear}`)).data
  const url = `/api/v2/nutrient-requirements/${fieldId}/${harvestYear}`
  const buffer = state.editBuffer
  const nutrientRequirements = []

  for (let i = 0; i < buffer.phosphorusPlans.length; i++) {
    let data = {
      id: null,
      fieldId: fieldId,
      geometry: geometry,
      mainCropId: i === 0 ? buffer.mainCropId : buffer.phosphorusPlans[i].mainCropId,
      lastCropId: i === 1 ? buffer.mainCropId : buffer.lastCropId,
      catchCropId: i === 0 ? buffer.catchCropId : null,
      harvestYear: buffer.harvestYear + i,
      expectedYield: buffer.phosphorusPlans[i].expectedYield,
      averageYieldLast3Years: i === 0 ? buffer.averageYieldLast3Years : 0,
      nitrogenActual: i === 0 ? buffer.nitrogenActual : 0,
      nitrogenHumusContentIsLow: i === 0 ? buffer.nitrogenHumusContentIsLow : false,
      isCovered: i === 0 ? buffer.isCovered : false,
      additionalRequirementFromCover: i === 0 ? buffer.additionalRequirementFromCover : 0,
      nitrogenFertilizationPreviousYear: i === 0 ? buffer.nitrogenFertilizationPreviousYear : 0,
      byproductIsRemoved: buffer.phosphorusPlans[i].byproductIsRemoved,
      phosphorusContentClass: buffer.phosphorusPlans[0].phosphorusContentClass,
      nitrogenRequirement: i === 0 ? buffer.nitrogenRequirement : 0,
      phosphorusRequirement: buffer.phosphorusPlans[i].phosphorusRequirement
    }

    nutrientRequirements.push(data)
  }

  return axios.post(url, nutrientRequirements)
  .then(() => dispatch('stopPlanning'))
  .then(() => dispatch('getNutrientRequirement'))
}
