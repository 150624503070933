import wizardSteps from './wizard-steps'

export const finished = state => {
  const {step} = state.ui.planningWizard

  const validSteps = wizardSteps.combination.filter(x => x !== 'status')

  if (validSteps.indexOf(step) === validSteps.length - 1) {
    return true
  } else {
    return false
  }
}

export const cultivationsByFieldId = state => {
  const lookup = {}

  Object.keys(state.cultivationsForOrgUnit).forEach(cultivationId => {
    const cultivation = state.cultivationsForOrgUnit[cultivationId]
    if (cultivation.fieldIds) { // workaround for timing issue when editing cultivations
      cultivation.fieldIds.forEach((fieldId) => {
        if (!lookup[fieldId]) {
          lookup[fieldId] = []
        }
        lookup[fieldId].push(cultivation)
      })
    }
  })
  return lookup
}

export const activeAction = state => {
  return state.ui.selection.actionId ? state.data.actions.forField.find(x => x.id === state.ui.selection.actionId) : null
}
