export function setCompanies (state, companies) {
  state.data.companies = companies
}

export function setEditing (state, value) {
  state.ui.editing = value
}

export function upsertCompany (state, company) {
  const existing = state.data.companies.find(f => f.id === company.id)
  if (existing) {
    existing.name = company.name.trim()
  } else {
    state.data.companies.push(company)
  }
}
