import mutations from './mutations'
import * as actions from './actions'
import * as getters from './getters'
import editBuffer from './edit-buffer'

const state = {
  data: {
    selection: {
      field: [], // ids only
      orgUnit: {} // full objects keyed by id
    },
    ecoTypes: [],
    cultivationTypes: [],
    predictedCrops: {},
    mixtures: []
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
  modules: {
    editBuffer
  }
}
