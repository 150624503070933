import axios from 'axios'

import {smarterGet} from '@helpers/vuex/data-loading'
import {can} from 'src/js/helpers/permissions'

// eslint-disable-next-line import/no-webpack-loader-syntax
import ProcessFieldWktsWorker from 'workerize-loader?inline=true!./process-field-wkts.worker'

const worker = new ProcessFieldWktsWorker()

async function processFieldWkts (wktByFieldId) {
  const result = await worker.processFieldWkts(wktByFieldId)

  for (const fieldId in result) {
    result[fieldId].wkt = wktByFieldId[fieldId]
  }

  return result
}

export async function processFieldWkt (wkt) {
  const result = await worker.processFieldWkts({[null]: wkt})

  const partialFieldData = {
    wkt,
    ...result[null]
  }

  return partialFieldData
}

export function startEditingField ({dispatch, getters}) {
  if (!getters.fieldId) return

  const features = getters.fieldFeature
    ? [getters.fieldFeature]
    : []

  return dispatch('map/startQuickMode', features)
}

export function getCultivationUpdateCandidates ({state, getters, commit, rootState}) {
  const fieldId = getters.fieldId
  const harvestYear = state.userSettings.harvestYear

  const url = `/api/v2/entities/fields/${fieldId}/${harvestYear}/cultivations/geo-update-candidates`
  if (can(rootState.permissions, 'Use.Frs.Cultivations')) {
    return axios.get(url).then(response => response.data)
    .then(cultivations => {
      commit('updateFieldData', {[fieldId]: {futureCultivations: cultivations}})

      return cultivations.length > 0
    })
  } else {
    return Promise.resolve()
  }
}

export async function saveFieldGeometry ({state, commit, dispatch, rootState}, {wkt, cultivationIds}) {
  const {fieldId} = state.navigation.location
  const {harvestYear} = state.userSettings

  const geometry = wkt
  const cultivationUpdates = cultivationIds.map(cultivationId => axios.post(`/api/v2/cultivations/${cultivationId}/geometry`, {geometry}))
  const serverContext = state.data.field[fieldId].context

  const context = {
    ...serverContext,
    fieldId,
    payload: {geometry, id: fieldId}
  }

  dispatch('reloadWeatherData')

  await Promise.all([
    ...cultivationUpdates,
    axios.post(`/api/v2/entities/fields/geodata/${harvestYear}`, context)
  ])

  // module reloading geometries needs to invalidate the cache
  commit('dataLoading/invalidate', 'orgUnit.simplified-geometries', {root: true})
  commit('dataLoading/invalidate', 'orgUnit.geometries', {root: true})
  commit('dataLoading/invalidate', 'harvestYear.fullFieldData', {root: true})

  // NOTE no await on purpose, no real need to wait for reload of the purely visual geometries
  dispatch('navigation/loadSimplifiedFieldGeometriesForCurrentOrgUnit')

  const wktsByFieldId = {[fieldId]: geometry}
  const fieldDataLookup = await processFieldWkts(wktsByFieldId)
  commit('updateFieldData', fieldDataLookup)

  for (const cultivationId of cultivationIds) {
    commit('cultivation/updateCultivationGeometry', {cultivationId, geometry})
  }

  // NOTE probably for recalculated area?
  // NOTE workaround for history context as well
  await dispatch('loadFullFieldData', fieldId)
}

const transformToFieldData = wktByFieldId => Object.keys(wktByFieldId)
  .reduce((fieldData, fieldId) => Object.assign(fieldData, {[fieldId]: {wkt: wktByFieldId[fieldId]}}), {})

export const loadGeometriesForTag = ({state, commit}) => {
  const {tag} = state.navigation.location
  const {harvestYear} = state.userSettings

  return axios.get(`/api/v2/settings/tags/${tag}/fields/geodata/${harvestYear}`)
  .then(response => response.data)
  .then(wktByFieldId => {
    commit('updateFieldData', transformToFieldData(wktByFieldId))
  })
}

export function loadFieldGeometriesForCurrentOrgUnit ({state, getters, commit, rootState}) {
  if (!state.navigation.location.orgUnitId) return
  const visibleOnly = getters.entityLookup[state.navigation.location.orgUnitId].visibleOnly
  if (visibleOnly) return

  return smarterGet('/api/v2/entities/orgunits/{orgUnitId}/fields/geodata/{harvestYear}', {
    id: 'orgUnit.geometries',
    expiry: 60,
    inputs: {
      orgUnitId: () => state.navigation.location.orgUnitId,
      harvestYear: () => rootState.fieldRecordSystem.userSettings.harvestYear
    },
    async onResult (wktByFieldId) {
      const fieldDataLookup = await processFieldWkts(wktByFieldId)
      commit('updateFieldData', fieldDataLookup)
    },
    subtreeInclusive: true
  })
}

export async function updateFieldGeometry ({commit}, {fieldId, wkt}) {
  const wktByFieldId = {[fieldId]: wkt}
  const fieldDataLookup = await processFieldWkts(wktByFieldId)

  commit('updateFieldData', fieldDataLookup)
}
