import axios from 'axios'

import {mapToColors} from '@helpers/map-color-schemes'
import {toHexAbgr} from '@helpers/color'

export async function getKmlColors (maps, mapType, applicationMapPalette, zoneMapPalette) {
  const colorPaletteByMap = {}

  const requests = await maps.map(async map => {
    let palette, zoneDetailsUrl

    if (mapType === 'zoneMap') {
      palette = map.category === 'BiomassMap'
        ? 'pastel'
        : zoneMapPalette
      zoneDetailsUrl = `/api/v2/zone-maps/${map.id}/geometry`
    } else if (mapType === 'applicationMap') {
      palette = applicationMapPalette
      zoneDetailsUrl = `/api/v2/application-map/${map.id}`
    }

    if (!map.zones) {
      const {data: {zones}} = await axios.get(zoneDetailsUrl)
      map.zones = zones
    }

    let colors = mapToColors(map, palette)
    colors = colors.map(color => toHexAbgr(color))
    colorPaletteByMap[map.id] = colors
  })
  return Promise.all(requests).then(() => colorPaletteByMap)
}
