import axios from 'axios'
import {getAndCommit} from '@helpers/vuex/actions'
import {smarterGet} from '@helpers/vuex/data-loading'

export const reloadEntities = ({state, getters, commit}) => {
  return smarterGet('/api/v2/entities/{harvestYear}', {
    id: 'frs.fullTree',
    inputs: {
      harvestYear: () => state.userSettings.harvestYear
    },
    onResult (forest) {
      commit('navigation/setEntityForest', forest)

      // TODO move directly into setTree
      const newFields = getters.allFields.filter(x => !state.data.field[x.id])

      if (newFields.length) {
        const defaultFieldData = newFields.reduce((fieldData, field) => Object.assign(fieldData, {[field.id]: {}}), {})
        commit('updateFieldData', defaultFieldData)
      }
    }
  })
}

export const reloadSubTreeEntities = ({state, commit, getters, rootState}) => {
  const {orgUnitId} = rootState.fieldRecordSystem.navigation.location
  if (!orgUnitId) return
  return smarterGet('/api/v2/entities/{orgUnitId}/{harvestYear}', {
    id: 'frs.subTree',
    inputs: {
      harvestYear: () => state.userSettings.harvestYear,
      orgUnitId: () => rootState.fieldRecordSystem.navigation.location.orgUnitId
    },
    onResult (subtree) {
      const {entityLookup} = getters
      commit('navigation/setEntitySubTree', {orgUnitId, subtree, entityLookup})
    }
  })
}

export const reloadIrrigationSources = getAndCommit('/api/v2/masterdata/waterExtraction/sources', 'setWaterExtractionSources')
export const reloadSoilTypes = getAndCommit('/api/v2/masterdata/soil/types', 'setSoilTypes')
export const reloadSoilSubTypes = getAndCommit('/api/v2/masterdata/soil/subtypes', 'setSoilSubTypes')
export const reloadUsageTypes = getAndCommit('/api/v2/masterdata/field/usages', 'setUsageTypes')
export const reloadLegalForms = getAndCommit('/api/v2/masterdata/company/types', 'setLegalForms')
export const reloadCompanyForms = getAndCommit('/api/v2/masterdata/company/categories', 'setCompanyForms')
export const reloadMaizeProductData = ({commit}) => {
  axios.get('/api/v2/master-data/maize-data')
  .then(response => response.data.productSeedRates)
  .then(rates => {
    commit('setMaizeProductData', rates)
  })
}

export function preloadStaticData ({dispatch}) {
  const rootDispatch = (name, payload) => dispatch(name, payload, {root: true})

  return Promise.all([
    dispatch('reloadIrrigationSources'),
    rootDispatch('masterData/reloadCrops'),
    rootDispatch('masterData/reloadCropUsages'),
    rootDispatch('masterData/reloadProductAlgorithms'),
    dispatch('reloadSoilTypes'),
    dispatch('reloadSoilSubTypes'),
    dispatch('reloadUsageTypes'),
    dispatch('reloadLegalForms'),
    dispatch('reloadCompanyForms'),
    rootDispatch('masterData/reloadUnits'),
    rootDispatch('masterData/reloadGlobalProducts'),
    rootDispatch('masterData/loadPortalSettings'),
    dispatch('cultivationPlanning/reloadCultivationTypes'),
    dispatch('cultivationPlanning/reloadEcoTypes')
  ])
}
