// ui control flow
import {toObject} from '@helpers/reducers'

export const wizardActive = state => state.partEditBuffer.wkt !== null

export const done = state => state.laneEditBuffer.fieldParts.every(x => x.selectedLane)

export const globalSegmentIndicesForCandidateLanes = state => {
  let index = 1
  return state.partEditBuffer.segmentationResult.map((ring) => ring.map(() => index++))
}

export const canSave = (state, getters) => {
  const abLinesAvailable = !!state.partEditBuffer.abSegments.length
  const linesAvailable = !!state.partEditBuffer.candidateLanes.length

  return getters.isPlanningProfileAB ? abLinesAvailable : linesAvailable
}

export function tree (state, getters, rootState, rootGetters) {
  const orgUnit = rootGetters['fieldRecordSystem/orgUnit']

  if (!orgUnit) return null

  const lanePlans = state.data.lanePlansOrgUnit

  const cloneNode = (node) => {
    const {id, name, type, children} = node

    let lanePlansForNode = lanePlans[id]
    if (lanePlansForNode) {
      lanePlansForNode = lanePlansForNode.map(plan => {
        return {
          id: plan.id,
          content: {
            ...plan,
            type: 'plan'
          },
          selected: state.data.selectedNodes.includes(plan.id),
          expanded: null
        }
      }).sort((a, b) => a.content.name.localeCompare(b.content.name))
    }

    const clone = {
      id,
      content: {
        id,
        name,
        type
      },
      children,
      selected: state.data.selectedNodes.includes(id),
      expanded: state.data.expandedNodes.includes(id)
    }

    if (lanePlansForNode) {
      clone.children = lanePlansForNode
    } else if (children) {
      clone.children = children.map(cloneNode).sort((a, b) => a.content.name.localeCompare(b.content.name))
    }

    return clone
  }

  return cloneNode(orgUnit)
}

export function availableMachines (rootState) {
  const machines = rootState.fieldRecordSystem.machines.data.machines
  if (!machines.length) return {}

  return machines.map(machine => [machine.id, {
    id: machine.id,
    name: machine.name,
    workingWidth: machine.workingWidth,
    turningCircle: machine.turningCircle
  }]).reduce(toObject, {})
}

export function isPlanningProfileAB (state) {
  return state.laneEditBuffer.planningProfile && state.laneEditBuffer.planningProfile === 'ab'
}

export function moreFieldPartsExist (state) {
  return state.laneEditBuffer.fieldParts.length > 1
}
