export function setMachines (state, machines) {
  state.data.machines = machines
}

export function setEditing (state, value) {
  state.ui.editing = value
}

export function setMachineGroups (state, machineGroups) {
  state.data.machineGroups = machineGroups
}

export function saveMachine (state, machine) {
  const existing = state.data.machines.find(m => m.id === machine.id)
  if (existing) {
    Object.assign(existing, machine)
  } else {
    state.data.machines.push(machine)
  }
}

export function deleteMachine (state, machineId) {
  const index = state.data.machines.findIndex(m => m.id === machineId)
  if (index !== -1) {
    state.data.machines.splice(index, 1)
  }
}
