import {fromPairs, mapValues} from 'lodash'

const safeDivideBy = factor => x => factor ? x / factor * 100 : 0

export function nutrientType (state) {
  const nutrientType = state.ui.applicationMap.nutrient
  return nutrientType.toLowerCase()
}

export function mapType (state, getters) {
  const nutrientType = getters.nutrientType

  return nutrientType === 'ph' ? 'lime' : nutrientType
}

export function selectedProduct (state, getters, rootState, rootGetters) {
  if (!state.ui.productId) return null

  const products = rootGetters['masterData/products']
  const product = products[state.ui.productId]

  return product
}

export function productFactor (state, getters) {
  // NOTE nutrient contents in db are percent in range [0, 100]
  const {selectedProduct, mapType} = getters

  // ph is actually not a nutrient. if a ph-application map is selected in the ui, you have to distribute lime to the field in order to correct the ph-value.
  // nutrient === 'ph' ? 'lime' : nutrient

  if (!(selectedProduct && mapType)) return null
  const factor = selectedProduct[mapType]
  if (isNaN(factor)) {
    throw new Error(`invalid factor for ${mapType}: ${selectedProduct[mapType]}`)
  }

  return factor
}

export function originalRawValues (state) {
  return fromPairs(state.ui.applicationMap.zones.map(zone => [zone.id, zone.value]))
}

export function originalProductValues (state, getters) {
  const divideByProductFactor = safeDivideBy(getters.productFactor)
  return mapValues(getters.originalRawValues, divideByProductFactor)
}

export function adjustedRawValues (state, getters) {
  return mapValues(getters.originalRawValues, value => {
    const averageAdjustment = state.ui.previousFertilization ? state.ui.previousFertilization : 0

    // TODO change static values with values from original preview map, after merge branches
    const {min, max} = state.ui.applicationMap.metaData.orderParameters.minMaxValuesByNutrient[getters.nutrientType]

    return Math.max(Math.min(value - averageAdjustment, max || Infinity), min || 0)
  })
}

export function adjustedProductValues (state, getters) {
  const divideByProductFactor = safeDivideBy(getters.productFactor)
  return mapValues(state.ui.manuallyAdjustedZoneValues, divideByProductFactor)
}

export function manuallyAdjustedValues (state, getters) {
  return state.ui.isApplicationValue
    ? getters.adjustedProductValues
    : state.ui.manuallyAdjustedZoneValues
}
