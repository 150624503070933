import axios from 'axios'
import {makeSetters} from '@helpers/vuex/mutations'

import convertToLegacyPayload from './convert-to-legacy-payload'
import {getKmlColors} from './get-kml-colors'
import {convertersByMapType, validShapeConvertersForMultiDownload, converterWithIncludeFieldBorderOption} from './valid-converters'

/**
 * This module is part of the standard download flow.
 * It handles multiple parts:
 * - selecting which data to download
 * - showing the format selection modal
 * - server communication during the download flow
 *
 * for more details about the download flow see iXmap.Solutions.WebApp.Areas.DataTransfer.Controllers.DownloadController
 */
export default {
  namespaced: true,
  state: {
    mapType: 'applicationMap',
    selectedMapIds: [],
    selectedConverter: null,

    fileName: null,
    description: '',
    ui: {
      showConverterModal: false
    },
    // props only for app map download
    includeFieldBorder: false,
    selectedUnit: null,
    srcUnit: null,
    validUnits: [],
    multipleFiles: true,
    multipleMapTypesWithMaizeSowing: false
  },
  getters: {
    validConverters (state, getters) {
      if (!getters.selectedMaps) return

      const everySelectedMapIsSensorData = getters.selectedMaps.every((element, index) => {
        return (getters.selectedMaps[index].category === 'InterpolatedSensorData')
      })

      if (everySelectedMapIsSensorData) {
        return convertersByMapType['sensorData']
      }

      return convertersByMapType[state.mapType]
    },
    selectedMaps (state, getters, rootState) {
      // map management uses a weird mish-mash of "biomass maps are an explicitely separate map type" and "biomass maps are just zone maps with a different category"
      // there is no separate map lookup for biomass, hence this workaround
      const workaroundMapType = state.mapType === 'biomassMap' ? 'zoneMap' : state.mapType

      const lookup = rootState.fieldRecordSystem.mapManagement.data[workaroundMapType].mapLookup

      return state.selectedMapIds.map(id => lookup[id])
    },
    validShapeConvertersForMultiDownload () {
      return validShapeConvertersForMultiDownload
    },
    converterWithIncludeFieldBorderOption () {
      return converterWithIncludeFieldBorderOption
    }
  },
  mutations: {
    ...makeSetters([
      'fileName',
      'description',
      'converterList',
      'selectedConverter',
      'selectedUnit',
      'srcUnit',
      'ui.showConverterModal',
      'includeFieldBorder',
      'validUnits',
      'multipleFiles',
      'multipleMapTypesWithMaizeSowing'
    ]),
    startDownload (state, {type, ids}) {
      state.description = ''
      state.mapType = type
      state.selectedMapIds = ids
      state.ui.showConverterModal = true
    },
    resetAppMapProps (state) {
      state.validUnits = []
      state.selectedUnit = null
      state.srcUnit = null
      state.multipleMapTypesWithMaizeSowing = false
      state.includeFieldBorder = false
      state.multipleFiles = true
    }
  },
  actions: {
    startDownload ({state, getters, commit, rootState}, {type, ids}) {
      if (type === 'applicationMap') {
        commit('resetAppMapProps')
        const units = rootState.masterData.units

        const mapsInfos = ids.map(id => {
          const map = rootState.fieldRecordSystem.mapManagement.data.applicationMap.mapLookup[id]
          const unit = map.unit
          const unitType = units[unit].type
          const unitShortcut = units[unit].shortcut
          const origin = map.origin

          return {
            unit,
            unitType,
            unitShortcut,
            origin
          }
        })

        const hasSameUnitType = mapsInfos.every((val, i, arr) => val.unitType === arr[0].unitType)
        const hasSameMapType = mapsInfos.every((val, i, arr) => val.origin === arr[0].origin)

        if (hasSameUnitType && hasSameMapType) {
          const unitShortcuts = Object.values(units).filter(unit => unit.type === mapsInfos[0].unitType).map(unit => unit.shortcut)
          // check if all maps have the same unit shortcut
          const hasSameUnitShortcut = mapsInfos.every((val, i, arr) => val.shortcut === arr[0].shortcut)

          commit('setValidUnits', unitShortcuts)
          commit('setSelectedUnit', hasSameUnitShortcut ? mapsInfos[0].unit : null)
          commit('setSrcUnit', hasSameUnitShortcut ? mapsInfos[0].unit : null)
        } else {
          // It´s a AGRAVIS feature WAA-1258
          // check if one of the maps is a maize sowing map
          const maizeSowingInfos = mapsInfos.filter(map => map.origin === 'MaizeSowing')
          if (maizeSowingInfos.length > 0) {
            // check if all maize sowing maps have the same unit shortcut
            const hasSameUnitShortcut = mapsInfos.every((val, i, arr) => val.shortcut === arr[0].shortcut)
            const unitShortcuts = Object.values(units).filter(unit => unit.type === maizeSowingInfos[0].unitType).map(unit => unit.shortcut)
            const selectedUnit = hasSameUnitShortcut ? maizeSowingInfos[0].unit : null
            const srcUnit = hasSameUnitShortcut ? maizeSowingInfos[0].unit : null

            commit('setValidUnits', unitShortcuts)
            commit('setSelectedUnit', selectedUnit)
            commit('setSrcUnit', srcUnit)
            // set server option
            commit('setMultipleMapTypesWithMaizeSowing', true)
          }
        }
      }

      commit('setMultipleFiles', true)
      commit('startDownload', {type, ids})

      if (!getters.validConverters.includes(state.selectedConverter)) {
        commit('setSelectedConverter', getters.validConverters[0])
      }
      // }
    },
    async download ({state, rootState, getters, rootGetters}) {
      const {fileName, description, selectedConverter, selectedUnit, includeFieldBorder, multipleFiles, mapType, multipleMapTypesWithMaizeSowing} = state
      const maps = getters.selectedMaps

      const urls = {
        applicationMap: '/api/v2/DataTransfer/Download/Convert/ApplicationMaps',
        zoneMap: '/api/v2/DataTransfer/Download/Convert/ZoneMaps',
        biomassMap: '/api/v2/DataTransfer/Download/Convert/BiomassMaps',
        nutrientMap: '/api/v2/DataTransfer/Download/Convert/NutrientMaps',
        fieldBorder: '/api/v2/DataTransfer/Download/Convert/Borders',
        soilMap: '/api/v2/DataTransfer/Download/Convert/SoilMaps',
        sensorRaster: '/api/v2/DataTransfer/Download/interpolation-maps'
      }

      if (mapType === 'soilMap') { // just for phbb sensor data
        const categories = maps.map(map => map.category)
        const hasSameCategory = categories.every((val, i, arr) => val === arr[0])

        if (hasSameCategory && categories[0] === 'InterpolatedSensorData') {
          const url = urls.sensorRaster

          const data = {
            fileName,
            mapIds: maps.map(map => map.id)
          }

          return axios.post(url, data).then(response => response.data)
        } else {
          if (categories.includes('InterpolatedSensorData')) {
            throw new Error('invalid selection: cannot select maps of type InterpolatedSensorData and other (soil) maps.')
          }
        }
      }

      const url = urls[mapType]
      const fieldIdsByMapId = rootGetters['fieldRecordSystem/mapManagement/fieldIdsByMapId']
      const useFieldBorder = mapType !== 'applicationMap' ? true : includeFieldBorder

      const data = convertToLegacyPayload(mapType, {
        selectedConverter,
        fileName,
        description,
        maps,
        harvestYear: rootState.fieldRecordSystem.userSettings.harvestYear,
        includeFieldBorder: useFieldBorder,
        selectedUnit,
        multipleFiles,
        multipleMapTypesWithMaizeSowing
      }, fieldIdsByMapId)

      if (['KmlConverter', 'KmzConverter'].includes(selectedConverter)) {
        const applicationMapPalette = rootGetters['fieldRecordSystem/mapManagement/applicationMapPalette']
        const zoneMapPalette = rootGetters['fieldRecordSystem/mapManagement/zoneMapPalette']

        data.colorPaletteByMap = await getKmlColors(maps, mapType, applicationMapPalette, zoneMapPalette)
      }

      return axios.post(url, data).then(response => response.data)

    }
  }
}
