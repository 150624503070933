import {filters} from '@helpers/tree'
import {geoJsonFormat} from '@helpers/openlayers/features'
import {fromFields} from '@frs/helpers/geojson/create-features'

import workflows from './mutations/workflows'

export const root = (state, getters) => getters['navigation/entityLookup'][getters['navigation/rootOrgUnitId']]

export function harvestYear (state) {
  return state.userSettings.harvestYear
}

export function fieldId (state) {
  return state.navigation.location.fieldId
}

export function field (state, getters) {
  return getters['navigation/entityLookup'][getters.fieldId]
}

export function fieldName (state, getters) {
  const fieldId = state.navigation.location.fieldId
  return getters.entityNameLookup[fieldId]
}

export function fieldWkt (state, getters) {
  return getters.fieldId ? state.data.field[getters.fieldId].wkt : null
}

export function fieldFeature (state, getters) {
  if (!getters.fieldId) return null

  const field = getters.entityLookup[getters.fieldId]

  const feature = fromFields([field], state.data.field)[0]

  if (!feature || !feature.geometry) return null

  return geoJsonFormat.readFeature(feature)
}

export function fieldWktsFromOrgUnit (state, getters) {
  let fields = getters['navigation/subTreeFields']

  fields = fields.map(x => ({
    id: x.id,
    name: x.name
  }))
  const fieldWkts = []
  for (let field of fields) {
    let details = state.data.field[field.id]
    fieldWkts.push({id: field.id, name: field.name, wkt: details.wkt})
  }
  return fieldWkts.filter(x => x.wkt)
}

export function orgUnitId (state) {
  return state.navigation.location.orgUnitId
}

export function orgUnit (state, getters) {
  return getters.orgUnitId ? getters['navigation/entityLookup'][getters.orgUnitId] : null
}

export function orgUnitName (state, getters) {
  return getters.orgUnit ? getters.orgUnit.name : null
}

export function rootOrgUnitId (state, getters) {
  return getters['navigation/rootOrgUnitId']
}

// TODO remove after usages are migrated to navigation module
export const allEntities = (state, getters) => getters['navigation/entities']
// TODO remove after usages are migrated to navigation module
export const entityLookup = (state, getters) => getters['navigation/entityLookup']
// TODO remove after usages are migrated to navigation module
export const allFields = (state, getters) => getters.allEntities.filter(filters.fields)

export const entityNameLookup = (state, getters) => {
  const lookup = {}
  getters.allEntities.forEach(entity => {
    lookup[entity.id] = entity.name
  })
  return lookup
}

export const fieldsForCurrentOrgUnit = (state, getters) => getters['navigation/subTreeFields']

export const entitiesForSelectedOrgUnit = (state, getters) => getters['navigation/flattenedSubTree']

export const fieldsForSelectedOrgUnit = (state, getters) => getters['navigation/subTreeFields']
export const orgUnitsForSelectedOrgUnit = (state, getters) => getters['navigation/subTreeOrgUnits']

const invertLookup = lookup => {
  const inverted = {}
  for (const key in lookup) {
    for (const value of lookup[key]) {
      if (!inverted[value]) {
        inverted[value] = []
      }
      inverted[value].push(key)
    }
  }
  return inverted
}

export const allFieldIdsByTag = state => {
  return invertLookup(state.userSettings.tags.fields)
}

export const fieldIdsForSelectedOrgUnitByTag = (state, getters) => {
  const filteredLookup = {}
  getters.fieldsForSelectedOrgUnit.map(field => {
    var tags = state.userSettings.tags.fields[field.id]
    if (tags) {
      filteredLookup[field.id] = tags
    }
  })
  return invertLookup(filteredLookup)
}

export const fieldIdsForCurrentOrgUnitByTag = (state, getters) => {
  const filteredLookup = {}
  getters.fieldsForCurrentOrgUnit.map(field => {
    var tags = state.userSettings.tags.fields[field.id]
    if (tags) {
      filteredLookup[field.id] = tags
    }
  })
  return invertLookup(filteredLookup)
}

export const existingTags = (state, getters) => [...new Set(Object.keys(getters.allFieldIdsByTag))]

export const resolvedView = state => {
  const view = state.ui.view.right
  if (workflows[view] && workflows[view].alias) {
    return workflows[view].alias
  }
  return view
}

export const resolvedBack = state => {
  const view = state.ui.transitions.back[state.ui.view.right]
  if (workflows[view] && workflows[view].alias) {
    return workflows[view].alias
  }
  return view || 'default'
}

// TODO use cultivation filter
export function fieldFeaturesForCurrentOrgUnit (state, getters) {
  return fromFields(getters.fieldsForCurrentOrgUnit, state.data.field)
}
