import {makeSetters} from '@helpers/vuex/mutations'

const defaults = () => ({
  id: null,
  fieldId: null,
  geometry: null,
  mainCropId: null,
  mainCropNitrogenRequirement: 0,
  mainCropIncrease: 0,
  mainCropDecrease: 0,
  mainCropDifference: 0,
  previousCropId: null,
  catchCropId: null,
  harvestYear: 0,
  expectedYield: 0,
  averageYieldLast3Years: 0,
  yieldDifference: 0,
  nitrogenActual: 0,
  nitrogenHumusContentIsLow: true,
  isCovered: false,
  additionalRequirementFromCover: 0,
  nitrogenFertilizationPreviousYear: 0,
  nitrogenFertilizationForCalculation: 0,
  nitrogenRequirement: 0,
  phosphorusPlans: [pDefaults()],
  corrections: [],
  currentCorrection: null,
  supplementsAndDiscountsForYieldDifference: 0,
  previousCrop: null,
  catchCrop: null,
  valueFromPreviousOrCatchCrop: 0,
  typeOfSoil: '',
  cropPhosphorusRequirement: 0,
  mainToByproductRatio: 0,
  phosphorusContentByproduct: 0,
  totalPRevocation: 0
})

const pDefaults = () => ({
  byproductIsRemoved: false,
  phosphorusContentClass: 'A',
  expectedYield: 0,
  mainCropId: null,
  phosphorusRequirement: 0,
  cropPhosphorusRequirement: 0,
  harvestCrop: 0,
  cropResidues: 0,
  mainToByproductRatio: 0,
  phosphorusContentByproduct: 0
})

const defaultCorrection = () => ({
  id: null,
  amount: 0,
  note: ''
})

function recalculatePhosphorusValues (state, index) {
  state.yieldDifference = state.averageYieldLast3Years - state.phosphorusPlans[0].expectedYield

  const supplementsAndDiscountsCalculation = state.mainCropIncrease / state.mainCropDifference * state.yieldDifference
  state.supplementsAndDiscountsForYieldDifference = state.yieldDifference > 0
    ? (supplementsAndDiscountsCalculation > 40 ? 40 : supplementsAndDiscountsCalculation)
    : state.mainCropDecrease / state.mainCropDifference * state.yieldDifference

  // Calculation n requirement
  const humusNAmount = state.nitrogenHumusContentIsLow ? 0 : 20

  const nRequirementTemp = state.mainCropNitrogenRequirement -
      state.nitrogenActual +
      state.supplementsAndDiscountsForYieldDifference -
      humusNAmount -
      state.nitrogenFertilizationForCalculation -
      state.valueFromPreviousOrCatchCrop

  state.nitrogenRequirement = nRequirementTemp > 0 ? nRequirementTemp : 0

  const harvestCropTemp = (index === 0 ? state.averageYieldLast3Years : state.phosphorusPlans[index].expectedYield) * state.phosphorusPlans[index].cropPhosphorusRequirement
  state.phosphorusPlans[index].harvestCrop = harvestCropTemp > 0 ? harvestCropTemp : 0

  const cropResiduesTemp = (index === 0 ? state.averageYieldLast3Years : state.phosphorusPlans[index].expectedYield) * state.phosphorusPlans[index].mainToByproductRatio * state.phosphorusPlans[index].phosphorusContentByproduct
  state.phosphorusPlans[index].cropResidues = cropResiduesTemp > 0 ? cropResiduesTemp : 0

  const pRequirementTemp = state.phosphorusPlans[index].byproductIsRemoved ? state.phosphorusPlans[index].harvestCrop + state.phosphorusPlans[index].cropResidues : state.phosphorusPlans[index].harvestCrop
  state.phosphorusPlans[index].phosphorusRequirement = pRequirementTemp > 0 ? pRequirementTemp : 0

  state.totalPRevocation = state.phosphorusPlans.reduce((acc, val) => acc + val.phosphorusRequirement, 0)
}

const mutations = {
  ...makeSetters([
    'isCovered',
    'currentCorrection'
  ]),
  setSupplementsAndDiscountsForYieldDifference (state, value) {
    state.supplementsAndDiscountsForYieldDifference = value
    recalculatePhosphorusValues(state, 0)
  },
  setAverageYieldLast3Years (state, value) {
    state.averageYieldLast3Years = value
    recalculatePhosphorusValues(state, 0)
  },
  setNitrogenFertilizationPreviousYear (state, value) {
    state.nitrogenFertilizationPreviousYear = value
    state.nitrogenFertilizationForCalculation = value * 0.1
    recalculatePhosphorusValues(state, 0)
  },
  setNHumusContentIsLow (state, value) {
    state.nitrogenHumusContentIsLow = value
    recalculatePhosphorusValues(state, 0)
  },
  setNitrogenActual (state, value) {
    state.nitrogenActual = value
    recalculatePhosphorusValues(state, 0)
  },
  setAdditionalRequirementFromCover (state, value) {
    state.additionalRequirementFromCover = value
    recalculatePhosphorusValues(state, 0)
  },
  setMainCropDetails (state, mainCrop) {
    const name = mainCrop ? mainCrop.name : null
    const expectedYield = mainCrop ? mainCrop.expectedYield : 0
    const cropPhosphorusRequirement = mainCrop ? mainCrop.phosphorusRequirement : 0
    const mainToByproductRatio = mainCrop ? mainCrop.mainToByproductRatio : 0
    const phosphorusContentByproduct = mainCrop ? mainCrop.phosphorusContentByproduct : 0
    const mainCropNitrogenRequirement = mainCrop ? mainCrop.nitrogenRequirement : 0
    const mainCropIncrease = mainCrop ? mainCrop.increase : 0
    const mainCropDecrease = mainCrop ? mainCrop.decrease : 0
    const mainCropDifference = mainCrop ? mainCrop.difference : 0

    state.mainCropId = name
    state.phosphorusPlans[0].mainCropId = name
    // In the future, this should be the expected yield from the previous 3 years
    state.averageYieldLast3Years = expectedYield

    state.expectedYield = expectedYield
    state.phosphorusPlans[0].expectedYield = expectedYield

    state.cropPhosphorusRequirement = cropPhosphorusRequirement
    state.phosphorusPlans[0].cropPhosphorusRequirement = cropPhosphorusRequirement
    state.phosphorusPlans[0].harvestCrop = expectedYield * cropPhosphorusRequirement

    state.mainToByproductRatio = mainToByproductRatio
    state.phosphorusPlans[0].mainToByproductRatio = mainToByproductRatio

    state.phosphorusContentByproduct = phosphorusContentByproduct
    state.phosphorusPlans[0].phosphorusContentByproduct = phosphorusContentByproduct

    state.mainCropNitrogenRequirement = mainCropNitrogenRequirement

    state.mainCropIncrease = mainCropIncrease

    state.mainCropDecrease = mainCropDecrease

    state.mainCropDifference = mainCropDifference

    recalculatePhosphorusValues(state, 0)
  },
  setPreviousCrop (state, previousCrop) {
    state.previousCrop = previousCrop
    state.previousCropId = previousCrop ? previousCrop.value : null
    state.valueFromPreviousOrCatchCrop = previousCrop ? previousCrop.amount : 0

    recalculatePhosphorusValues(state, 0)
  },
  setCatchCrop (state, catchCrop) {
    state.catchCrop = catchCrop
    state.catchCropId = catchCrop ? catchCrop.value : null
    state.valueFromPreviousOrCatchCrop = catchCrop ? catchCrop.amount : 0

    recalculatePhosphorusValues(state, 0)
  },
  set (state, partialNutrientRequirement) {
    const defaultNutrientRequirement = defaults()
    for (const key in partialNutrientRequirement) {
      if (!(key in defaultNutrientRequirement)) {
        console.error(`[nutrient requirement editBuffer] set: property '${key}' does not exist in nutrient requirement defaults`)
      }
    }
    Object.assign(state, partialNutrientRequirement)

    if (partialNutrientRequirement.mainCropId) {
      state.phosphorusPlans[0].mainCropId = state.mainCropId
    }
    if (partialNutrientRequirement.nitrogenFertilizationPreviousYear) {
      state.nitrogenFertilizationForCalculation = partialNutrientRequirement.nitrogenFertilizationPreviousYear * 0.1
    }
    if (partialNutrientRequirement.expectedYield) {
      state.phosphorusPlans[0].expectedYield = state.expectedYield
    }
    if (partialNutrientRequirement.cropPhosphorusRequirement) {
      state.phosphorusPlans[0].cropPhosphorusRequirement = state.cropPhosphorusRequirement
      state.phosphorusPlans[0].harvestCrop = state.expectedYield * state.cropPhosphorusRequirement
    }
    if (partialNutrientRequirement.mainToByproductRatio) {
      state.phosphorusPlans[0].mainToByproductRatio = state.mainToByproductRatio
    }
    if (partialNutrientRequirement.phosphorusContentByproduct) {
      state.phosphorusPlans[0].phosphorusContentByproduct = state.phosphorusContentByproduct
    }

    recalculatePhosphorusValues(state, 0)
  },

  setPartialPPlan (state, {i, partialPhosphorusPlan}) {
    const defaultAction = pDefaults()
    for (const key in partialPhosphorusPlan) {
      if (!(key in defaultAction)) {
        console.error(`[nutrient requirement editBuffer] set: property '${key}' does not exist in nutrient requirement pDefaults`)
      }
    }
    Object.assign(state.phosphorusPlans[i], partialPhosphorusPlan)
    recalculatePhosphorusValues(state, i)
  },
  reset (state, nutrientRequirement) {
    Object.assign(state, defaults(), nutrientRequirement)
  },
  addYear (state) {
    if (state.phosphorusPlans.length === 3) return

    state.phosphorusPlans.push(pDefaults())

    for (let i = 0; i < state.phosphorusPlans.length; i++) {
      if (i !== 0) {
        state.phosphorusPlans[i].phosphorusContentClass = state.phosphorusPlans[0].phosphorusContentClass
      }
      recalculatePhosphorusValues(state, i)
    }
  },
  removeYear (state, i) {
    if (i < 1 || i > 2) return

    state.phosphorusPlans = state.phosphorusPlans.slice(0, i)

    for (let i = 0; i < state.phosphorusPlans.length; i++) {
      recalculatePhosphorusValues(state, i)
    }
  },
  updateYear (state, {i, plan}) {
    state.phosphorusPlans.splice(i, 1, plan)

    if (i === 0) {
      state.expectedYield = plan.expectedYield
      state.mainCropId = plan.mainCropId
    }
    recalculatePhosphorusValues(state, i)
  },
  addCorrection (state) {
    state.currentCorrection = defaultCorrection()
  },
  updatePhosphorusContent (state, {plan}) {
    let tempPlan = null

    for (let i = 0; i < state.phosphorusPlans.length; i++) {
      tempPlan = state.phosphorusPlans[i]
      tempPlan.phosphorusContentClass = plan.phosphorusContentClass
      state.phosphorusPlans.splice(i, 1, tempPlan)
      recalculatePhosphorusValues(state, i)
    }
  }
}

export default {
  namespaced: true,
  state: defaults(),
  mutations
}
