import {range} from '@helpers/arrays'
import {getZoneCount} from '@helpers/zone-map'

export const zoneMapHeterogenitySpread = {
  null: 0, // TODO maybe remove if server always returns enum member
  'None': 0,
  'Homogeneous': 0.1,
  'SlightlyHeterogeneous': 0.15,
  'Heterogenous': 0.2,
  'IncreasinglyHeterogenous': 0.25,
  'StronglyHeterogenous': 0.3
}

const precision = 2

export const spreadByHeterogenity = (averageValue, zoneMap) => {
  if (!zoneMap || !zoneMap.zones || averageValue === null) return null

  const {heterogenity} = zoneMap

  const zoneCount = getZoneCount(zoneMap)

  const heterogenityStart = 1 + zoneMapHeterogenitySpread[heterogenity]
  const heterogenityStep = zoneCount > 1
    ? zoneMapHeterogenitySpread[heterogenity] * 2 / (zoneCount - 1)
    : 0

  const zoneFactors = range(zoneCount).map(i => heterogenityStart - i * heterogenityStep)

  const round = x => Math.round(Math.pow(10, precision) * x) / Math.pow(10, precision)

  const zoneAmounts = {}

  for (const zone of zoneMap.zones) {
    zoneAmounts[zone.id] = round(zoneFactors[zone.number - 1] * averageValue)
  }

  return zoneAmounts
}
