import {smarterPost} from '@helpers/vuex/data-loading'
import moment from 'moment'

import {makeSetters} from '@helpers/vuex/mutations'
import {spreadByHeterogenity} from '@helpers/map-creation'
import {waterLevels, soilQualities, usageModifiers} from 'src/js/data/maize-sowing'

const automaticSupported = productData => productData && productData.maizeMatrix &&
                           productData.maizeMatrix.length === 9 && productData.maizeMatrix !== [0, 0, 0, 0, 0, 0, 0, 0, 0]

export default {
  namespaced: true,
  state: {
    parameters: {
      name: '',
      zoneMapId: null,
      cultivationId: null,
      productId: null,
      unit: 'K/m²',
      waterLevel: null, // for looking up base sowingDensity in matrix
      soilQuality: null, // for looking up base sowingDensity in matrix
      usage: null, // results in adjustment of sowingDensity
      // result
      zoneAmounts: {}
    },
    automatic: false,
    ignoreLimits: false,
    customSowingDensity: null
  },
  getters: {
    zoneMap (state, getters, rootState) {
      const {zoneMapId} = state.parameters
      const {mapLookup} = rootState.fieldRecordSystem.mapManagement.data.zoneMap

      return zoneMapId
        ? mapLookup[zoneMapId]
        : null
    },
    product (state, getters, rootState, rootGetters) {
      return rootGetters['masterData/products'][state.parameters.productId]
    },
    productData (state, getters) {
      return getters.product
    },
    automaticSupported (state, getters) {
      return automaticSupported(getters.productData)
    },
    limits (state, getters) {
      if (getters.productData) {
        const {unit} = state.parameters
        const {minSeedRate, maxSeedRate} = getters.productData

        return [minSeedRate, maxSeedRate].map(x => unit === 'K/ha' ? x * 10000 : x)
      }
    },
    sowingDensity (state, getters) {
      if (state.automatic) {
        const {unit, waterLevel, soilQuality, usage} = state.parameters

        if (!waterLevel || !soilQuality || !usage) return null

        const i = waterLevels.indexOf(waterLevel)
        const j = soilQualities.indexOf(soilQuality) * 3

        const averageValue = getters.productData.maizeMatrix[i + j]

        const factor = unit === 'K/ha' ? 10000 : 1

        return factor * (averageValue + usageModifiers[usage])
      } else {
        return state.customSowingDensity
      }
    },
    calculatedZoneAmounts (state, getters) {
      const amounts = spreadByHeterogenity(getters.sowingDensity, getters.zoneMap)

      if (!state.ignoreLimits) {
        const clamp = x => Math.max(getters.limits[0], Math.min(getters.limits[1], x))

        for (const key in amounts) {
          amounts[key] = clamp(amounts[key])
        }
      }

      return amounts
    }
  },
  mutations: {
    ...makeSetters([
      'parameters.name',
      'parameters.cultivationId',
      'parameters.zoneMapId',
      'parameters.unit',
      'parameters.waterLevel',
      'parameters.soilQuality',
      'parameters.usage',
      'parameters.zoneAmounts',
      'parameters.productId',
      'automatic',
      'ignoreLimits',
      'customSowingDensity'
    ]),
    reset (state) {
      state.parameters.zoneMapId = null
      state.parameters.zoneAmounts = {}
      state.parameters.name = ''
      state.parameters.cultivationId = null
      state.parameters.productId = null
      state.parameters.unit = 'K/m²'
      state.parameters.waterLevel = null
      state.parameters.soilQuality = null
      state.parameters.usage = null
      state.automatic = false
      state.ignoreLimits = false
      state.customSowingDensity = null
    }
  },
  actions: {
    startMaizeSowing ({commit}) {
      commit('fieldRecordSystem/setRightView', 'maizeSowing', {root: true})
      commit('reset')
    },
    async createApplicationMap ({state, getters, rootState, rootGetters}) {
      const {orgUnitId} = rootState.fieldRecordSystem.navigation.location
      const {harvestYear} = rootState.fieldRecordSystem.userSettings

      // in order to satisfy the model binder on the server we need a soilType which is hardcoded
      // since we use the soilQuality with the corresponding matrix
      // the soilType is no longer used however gets stored in the metadata json with the application map

      let data = {
        ...state.parameters,
        automatic: state.automatic,
        ignoreLimits: state.ignoreLimits,
        customSowingDensity: state.customSowingDensity,
        heterogenity: getters.zoneMap.heterogenity,
        soilType: 'medium'
      }
      const product = rootGetters['masterData/products'][data.productId]

      data.name = data.name
        ? `${moment.utc().format('YYYY-MM-DD')}_${data.name}_${product.name}`
        : `${moment.utc().format('YYYY-MM-DD')}_${rootGetters['fieldRecordSystem/fieldName']}_${rootGetters['fieldRecordSystem/orgUnitName']}_${product.name}`

      return smarterPost(`/api/v2/maize-sowing-application-maps/${orgUnitId}/${harvestYear}`, data, {
        id: 'save-maize-sowing-map'
      })
    },
    viewResultPage ({commit}) {
      commit('fieldRecordSystem/setRightView', 'applicationMapCreationResultMaizeSowing', {root: true})
    }
  }
}
