import axios from 'axios'
import {sortBy} from 'lodash'
import {can} from 'src/js/helpers/permissions'

import {smarterGet} from '@helpers/vuex/data-loading'

export const loadMachineGroups = ({commit, rootState}) => {
  if (can(rootState.permissions, 'Use.Frs.Actions')) {
    return axios.get('/api/v2/masterdata/machine/types')
      .then(machineGroups => {
        commit('setMachineGroups', sortBy(machineGroups.data, ['rank']))
      })
  } else {
    return Promise.resolve()
  }
}

export const loadMachines = ({rootGetters, commit, rootState}) => {
  const orgUnitId = rootGetters['fieldRecordSystem/orgUnitId']
  const harvestYear = rootGetters['fieldRecordSystem/harvestYear']

  if (can(rootState.permissions, 'Use.Frs.Actions')) {
    return smarterGet('/api/v2/entities/{orgUnitId}/machines/{harvestYear}', {
      id: 'data.machines',
      expiry: 120,
      inputs: {
        orgUnitId: () => orgUnitId,
        harvestYear: () => harvestYear
      },
      sharedCache: true,
      onResult (data) {
        commit('setMachines', data)
      }
    })
  } else {
    return Promise.resolve()
  }
}

export const saveMachine = ({state, rootGetters, commit}) => {
  const orgUnitId = rootGetters['fieldRecordSystem/orgUnitId']
  const machine = state.editBuffer
  machine.orgUnitId = orgUnitId
  return axios.post(`/api/v2/entities/${orgUnitId}/machines`, machine)
  .then(({data: machine}) => {
    commit('saveMachine', machine)
  })
}

export const removeMachine = ({rootGetters, commit}, machine) => {
  const orgUnitId = rootGetters['fieldRecordSystem/orgUnitId']

  commit('fieldRecordSystem/setDataLoading', true, {root: true})
  return axios.delete(`/api/v2/entities/${orgUnitId}/machines/${machine.id}`)
  .then(() => {
    commit('deleteMachine', machine.id)
    commit('fieldRecordSystem/setDataLoading', false, {root: true})
  })
}

export const shareMachineToChilds = ({rootGetters}) => {
  const orgUnitId = rootGetters['fieldRecordSystem/orgUnitId']
  return axios.post(`/api/v2/entities/${orgUnitId}/machines/share/all`)
  .then(() => {
  })
}

export const startEditing = ({commit, dispatch}, machine) => {
  return dispatch('loadMachineGroups')
    .then(() => {
      commit('setEditing', true)
      commit('editBuffer/reset', Object.assign({group: null}, machine))
    })
}

export const stopEditing = ({commit}) => {
  commit('setEditing', false)
}
