import {extend, createEmpty} from 'ol/extent'

import {geoJsonFormat, wktFormat} from '@helpers/openlayers/features'

export default ({commit}, obj) => {
  if (!obj) return

  // wkt
  if (typeof obj === 'string') {
    obj = wktFormat.readGeometry(obj, {dataProjection: 'EPSG:4326', featureProjection: 'EPSG:3857'})
  }

  // extent
  if (Array.isArray(obj) && obj.every(x => typeof x === 'number')) {
    commit('setFocusExtent', obj)
    return
  }
  // geojson feature
  if (obj.type === 'Feature' && obj.geometry) {
    obj = geoJsonFormat.readFeature(obj)
  }
  // geojson geometry
  if (obj.type && obj.coordinates) {
    obj = geoJsonFormat.readGeometry(obj)
  }
  // feature
  if (obj.getGeometry instanceof Function) {
    commit('setFocusExtent', obj.getGeometry().getExtent())
    return
  }
  // geojson feature collection
  if (obj.type === 'FeatureCollection' && obj.features) {
    obj = geoJsonFormat.readFeatures(obj)
  }
  // array of geojson features
  if (Array.isArray(obj) && obj.every(x => x.type === 'Feature')) {
    obj = obj.map(feature => geoJsonFormat.readFeature(feature))
  }
  // array of geojson geometries
  if (Array.isArray(obj) && obj.every(x => x.coordinates)) {
    obj = obj.map(geometry => geoJsonFormat.readGeometry(geometry))
  }
  // array of features
  if (Array.isArray(obj) && obj.every(x => x.getGeometry instanceof Function)) {
    const ext = obj.reduce((combined, x) => extend(combined, x.getGeometry().getExtent()), createEmpty())
    commit('setFocusExtent', ext)
    return
  }
  // layer or geometry
  if (obj.getExtent instanceof Function) {
    commit('setFocusExtent', obj.getExtent())
    return
  }
  // array of layer or geometry
  if (Array.isArray(obj) && obj.every(x => x.getExtent instanceof Function)) {
    const ext = obj.reduce((combined, x) => extend(combined, x.getExtent()), createEmpty())
    commit('setFocusExtent', ext)
    return
  }

  console.error('map focus can only be set for extents, layers, features or geometries, was given:')
  console.error(obj)
}
