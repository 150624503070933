// import axios from 'axios'

const withLoadingIndicator = action => (store, payload) => {
  store.commit('setDataLoading', true)
  return action(store, payload).then(() => {
    store.commit('setDataLoading', false)
  })
}

export const reloadDefaultFieldData = withLoadingIndicator(({state, dispatch, getters}) => {
  const {fieldId} = state.navigation.location
  const field = getters['navigation/entityLookup'][fieldId]
  if (!field) return Promise.resolve()

  return Promise.all([
    dispatch('loadFullFieldData', fieldId),
    dispatch('reloadAssignedCropRotations'),
    dispatch('fieldRecordSystem/mapManagement/loadGeometryForField', null, {root: true}) // needed for geometry downlaod
  ])
})

export const reloadWeatherData = ({state, dispatch}) => {
  const {fieldId} = state.navigation.location

  if (!fieldId) {
    console.error('weather data loaded without fieldId')
    return
  }

  return dispatch('loadWeatherData', fieldId)
}

// TODO should probably trigger on selection, not on navigation
export const reloadOrgUnitData = ({state, dispatch}) => {
  const {orgUnitId} = state.navigation.location
  if (orgUnitId) {
    return Promise.all([
      dispatch('loadMasterData', orgUnitId),
      dispatch('reloadCropRotations')
    ])
  }
}
