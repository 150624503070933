import Vue from 'vue'

export function setNutrientRequirementsForField (state, nutrientRequirements) {
  for (const fieldId in nutrientRequirements) {
    Vue.set(state.data.field, fieldId, nutrientRequirements[fieldId])
  }
}

export function setCorrections (state, {fieldId, id, corrections}) {
  state.data.field[fieldId].find(x => x.id === id).corrections = corrections
}

export function addCorrection (state, {fieldId, nutrientRequirementId, correction}) {
  const correctionsTemp = state.data.field[fieldId].find(x => x.id === nutrientRequirementId).corrections
  const correctionTemp = correctionsTemp.find(x => x.id === correction.id)

  let index = -1

  if (correctionTemp) index = correctionsTemp.map(x => x.id).indexOf(correctionTemp.id)

  if (index >= 0) state.data.field[fieldId].find(x => x.id === nutrientRequirementId).corrections.splice(index, 1, correction)
  else state.data.field[fieldId].find(x => x.id === nutrientRequirementId).corrections.push(correction)
}

export function setCrops (state, crops) {
  state.data.editing.crops = crops
}

export function setCatchCrops (state, crops) {
  state.data.editing.catchCrops = crops
}

export function setPreviousCrops (state, crops) {
  state.data.editing.previousCrops = crops
}

export function addPRequirement (state, {index, value}) {
  let tempPlan = state.editBuffer.phosphorusPlans[index]
  tempPlan.phosphorusRequirement = value
  state.editBuffer.phosphorusPlans.splice(index, 1, tempPlan)
}

export function editCorrection (state, correction) {
  state.editBuffer.currentCorrection = correction
}

export function setStep (state, step) {
  state.ui.wizardStep = step
}
