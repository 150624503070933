import {cloneDeep} from 'lodash'

export const startEditing = ({rootState, commit, rootGetters}, orgUnitId) => {
  const orgUnit = rootGetters['fieldRecordSystem/navigation/entityLookup'][orgUnitId]

  const orgUnitData = rootState.fieldRecordSystem.data.orgUnits.data
  const hasCompanyData = (orgUnitId !== null && orgUnitData[orgUnitId].companyData !== '' && orgUnitData[orgUnitId].companyData !== null)

  // NOTE why build a weird object from orgUnit and other things? why not hand them over separately?
  const company = {
    id: orgUnitId,
    orgUnitName: orgUnit.name,
    shouldHaveCompanyData: hasCompanyData,
    companyData: orgUnitData[orgUnitId].companyData
  }

  commit('setEditing', true)
  commit('editBuffer/reset', {company})
  commit('fieldRecordSystem/setRightView', 'companyData', {root: true})
}

export const stopEditing = ({state, commit}) => {
  commit('setEditing', false)
  commit('editBuffer/reset', {company: null})
  commit('fieldRecordSystem/setRightView', 'default', {root: true})
  return state.editBuffer
}

export async function saveCompany ({state, rootState, rootGetters, dispatch}) {
  const rootDispatch = (name, payload) => dispatch(name, payload, {root: true})

  const {orgUnitId: currentOrgUnitId} = rootState.fieldRecordSystem.navigation.location
  const clonedEditBuffer = cloneDeep(state.editBuffer)

  const orgUnitData = rootState.fieldRecordSystem.data.orgUnits.data
  // TODO one of the id checks seems redundant, verify and remove
  const hasCompanyData = (clonedEditBuffer.id !== null && currentOrgUnitId !== null && orgUnitData[currentOrgUnitId].companyData)

  const orgUnitId = clonedEditBuffer.id === null
    ? await rootDispatch('fieldRecordSystem/createNewOrgUnit', {
      name: clonedEditBuffer.orgUnitName
    })
    : currentOrgUnitId

  const promises = []

  if (clonedEditBuffer.shouldHaveCompanyData) {
    const {companyData} = clonedEditBuffer

    promises.push(rootDispatch('fieldRecordSystem/saveCompanyData', {
      orgUnitId,
      companyData
    }))
  } else if (hasCompanyData) {
    // delete company data
    promises.push(rootDispatch('fieldRecordSystem/deleteMasterData'))
  }

  if (clonedEditBuffer.id) {
    // update orgUnit name
    const {name} = rootGetters['fieldRecordSystem/entityLookup'][orgUnitId]
    if (name !== clonedEditBuffer.orgUnitName) {
      promises.push(rootDispatch('fieldRecordSystem/updateOrgUnitName', {
        orgUnitId,
        name: clonedEditBuffer.orgUnitName.trim()
      }))
    }
  }

  await Promise.all(promises)
  await dispatch('stopEditing')

  return orgUnitId
}
