import axios from 'axios'
import {uniq, mapValues} from 'lodash'

import {toObject} from '@helpers/reducers'
import {makeSetters} from '@helpers/vuex/mutations'
import {smarterGet} from '@helpers/vuex/data-loading'
import {parse} from 'src/js/i18n/conversion'
import {computeClassificationPKMg, computeClassificationPh, isCalculationPossible} from '@frs/components/basic-fertilization/classification-calculation'
import {calculateFromOxidform} from '@frs/components/basic-fertilization/oxidform-calculation'

import labPresets from './lab-presets'

const stringToUpperCase = (value) => {
  return typeof value === 'string' ? value.toUpperCase() : value
}

export default {
  namespaced: true,
  state: { // manual entry or from file(s)
    openOrders: [],
    order: null,
    // order:
    // {
    //   id,
    //   name,
    //   // created,
    //   geometries: [] // {sampleId, label, geometry, isActive},
    //   isCompleted: false
    // }
    manual: {
      // page: settings
      selectedFieldId: null,
      nutrients: [],
      timestamp: null,
      probingMethod: null,
      // page: values
      selectedSamplingOrderGeometryId: null
    },
    upload: { // TODO
      // file upload
      fileContents: '',
      semicolonSeparated: false,
      // analysis
      error: false,
      // column mapping
      useLabPreset: true,
      labPresets,
      lab: null,
      nutrients: [],
      columnMapping: {
        sampleId: null,
        fieldName: null,
        phosphorusValue: null,
        phosphorusClassification: null,
        phosphorusIsOxide: false,
        magnesiumValue: null,
        magnesiumClassification: null,
        magnesiumIsOxide: false,
        potassiumValue: null,
        potassiumClassification: null,
        potassiumIsOxide: false,
        phValue: null,
        phClassification: null,
        limeRecommendation: null,
        SoilSubType: null
      }
    },
    results: {}, // {geometry, nutrient, measurement, contentClass, timestamp}
    lastCalculationSoilSubTypeLookup: {},
    ignoreClassification: false
  },
  getters: {
    newResults (state, getters) {
      // const {timestamp} = state.manual
      if (!state.order) return []

      return Object.keys(state.results).reduce((newResults, samplingOrderGeometryId) => {
        const results = {
          samplingOrderGeometryId,
          // timestamp
          // NOTE for testing only
          timestamp: state.manual.timestamp
        }

        let hasNewData

        for (const key in state.results[samplingOrderGeometryId]) {
          results[key] = state.results[samplingOrderGeometryId][key]

          if (getters.savedResultsLookup[samplingOrderGeometryId][key]) {
            results[key] = null
          } else if (results[key]) {
            hasNewData = true
          }
        }

        if (!hasNewData) return newResults

        newResults.push(results)

        return newResults
      }, [])
    },
    newResultsValidity (state, getters) {
      const nutrients = ['phosphorus', 'magnesium', 'potassium', 'ph', 'lime']

      const {timestamp} = state.manual

      const nutrientValues = (result, nutrient) => [result[`${nutrient}Measurement`], result[`${nutrient}ContentClass`]]
      const nutrientValid = (result, savedResult, nutrient) => {
        if (nutrient === 'lime') return result.limeRecommendation ? true : null // NOTE lime is standalone, so it is always valid

        const values = nutrientValues(result, nutrient)
        const soilSubType = result.soilSubType || savedResult.soilSubType
        return values.every(x => x === null) ? null : !!(soilSubType && timestamp && values.every(x => x !== null))
      }

      return getters.newResults.reduce((lookup, result) => {
        const savedResult = getters.savedResultsLookup[result.samplingOrderGeometryId]

        const validityByNutrient = nutrients.reduce((lookup, nutrient) => Object.assign(lookup, {[nutrient]: nutrientValid(result, savedResult, nutrient)}), {})

        return Object.assign(lookup, {[result.samplingOrderGeometryId]: validityByNutrient})
      }, {})
    },
    newResultsCombinedValidity (state, getters) {
      return mapValues(getters.newResultsValidity, validityByNutrient => !Object.values(validityByNutrient).some(x => x === false))
    },
    validNewResults (state, getters) {
      return getters.newResults.filter(({samplingOrderGeometryId}) => getters.newResultsCombinedValidity[samplingOrderGeometryId])
    },
    csvSeparator (state) {
      return state.upload.semicolonSeparated ? ';' : ','
    },
    csvLinesRaw (state) {
      return state.upload.fileContents
        ? state.upload.fileContents.split(/[\r\n]+/).filter(x => x)
        : []
    },
    csvLines (state, getters) {
      const processLine = line => line.split(getters.csvSeparator).map(x => x.trim())

      return getters.csvLinesRaw.map(processLine)
    },
    csvHeaders (state, getters) {
      return getters.csvLines[0]
    },
    csvData (state, getters) {
      return getters.csvLines.slice(1)
    },
    parsedCsvData (state, getters) {
      const transformRow = row => row
        .map((x, i) => [stringToUpperCase(getters.csvHeaders[i]), x])
        .reduce(toObject, {})

      return getters.csvData.map(transformRow)
    },
    validRows (state, getters) {
      const sampleIdColumn = state.upload.columnMapping.sampleId

      return getters.parsedCsvData.filter(row => sampleIdColumn ? row[sampleIdColumn] : true)
    },
    sampleIdsAreUnique (state, getters) {
      const sampleIdColumn = state.upload.columnMapping.sampleId

      if (!sampleIdColumn) return false

      const ids = {}
      for (const row of getters.validRows) {
        const sampleId = row[sampleIdColumn]

        if (sampleId in ids) return false

        ids[sampleId] = true
      }

      return true
    },
    mappingComplete (state, getters) {
      const columnMapping = state.upload.columnMapping

      const nutrientIsMapped = nutrient => nutrient !== 'lime'
        ? columnMapping[`${nutrient}Value`] && columnMapping[`${nutrient}Classification`]
        : !!columnMapping.limeRecommendation

      return columnMapping.sampleId && (getters.sampleIdsAreUnique || columnMapping.fieldName) &&
        state.manual.nutrients.every(nutrientIsMapped)
    },
    savedResultsLookup (state) {
      const lookup = {}

      if (!state.order) return lookup
      state.order.geometries.forEach(geometry => {
        lookup[geometry.id] = {...geometry.results}
      })

      return lookup
    },
    canCalculateContentClasses (state, getters) {
      if (!Object.keys(state.results).length) return null
      const results = state.results[state.manual.selectedSamplingOrderGeometryId]
      const savedResults = getters.savedResultsLookup[state.manual.selectedSamplingOrderGeometryId]
      if (results === undefined) return false
      const nutrients = ['phosphorus', 'potassium', 'magnesium', 'ph']
      return nutrients.some(x => results[`${x}Measurement`] && !savedResults[`${x}ContentClass`]) && isCalculationPossible(results.soilSubType)
    }
  },
  mutations: {
    ...makeSetters([
      'order',
      'openOrders',
      'manual.timestamp',
      'manual.nutrients',
      'manual.selectedSamplingOrderGeometryId',
      'manual.probingMethod',
      'upload.fileContents',
      'upload.semicolonSeparated'
    ]),
    resetManualSettings (state) {
      state.manual.timestamp = null
      state.manual.probingMethod = null
    },
    generateResultsForOrder (state) {
      const resultLookup = {}
      state.order.geometries.forEach(geometry => {
        resultLookup[geometry.id] = {...geometry.results}
      })

      state.manual.timestamp = null
      state.results = resultLookup
      state.manual.probingMethod = state.order.probingMethod
    },
    assignResults (state, results) {
      Object.assign(state.results, results)
    },
    analyzeFile () {
      // tries to autogenerate column mapping and nutrients, and checks for errors
    },
    updateResult (state, partialResult) {
      Object.assign(state.results[state.manual.selectedSamplingOrderGeometryId], partialResult)

      // NOTE limeRecommendation doesn't matter for content class calculation
      if (Object.keys(partialResult).length === 1 && partialResult.limeRecommendation) return

      state.lastCalculationSoilSubTypeLookup = {
        ...state.lastCalculationSoilSubTypeLookup,
        [state.manual.selectedSamplingOrderGeometryId]: null
      }
    },
    updateColumnMapping (state, {id, column}) {
      if (!(id in state.upload.columnMapping)) {
        throw new Error('invalid column for column mapping')
      }
      state.upload.columnMapping[id] = column
    },
    setUseLabPreset (state, value) {
      state.upload.useLabPreset = value
      if (value && state.upload.lab) {
        Object.assign(state.upload.columnMapping, state.upload.lab.columnMapping)
      }
    },
    toggleSelectedFieldId (state, id) {
      state.manual.selectedFieldId = state.manual.selectedFieldId === id
        ? null
        : id
    },
    setLab (state, lab) {
      state.upload.lab = lab
      let columnMapping = null
      if (lab) {
        columnMapping = mapValues(lab.columnMapping, stringToUpperCase)
        state.upload.lab.columnMapping = columnMapping
      }
      Object.assign(state.upload.columnMapping, columnMapping)
    },
    setIgnoreClassification (state, value) {
      state.ignoreClassification = value
    },
    calculateClassifications (state, {savedResultsLookup, allResults}) {
      const {selectedSamplingOrderGeometryId} = state.manual
      const results = state.results
      const fieldInfo = state.order.fieldInfoByGeometry

      const nutrients = ['phosphorus', 'potassium', 'magnesium', 'ph']

      const calculateContentClasses = geometryId => {
        const result = results[geometryId]
        const soilSubTypeId = result.soilSubType
        if (!soilSubTypeId) return

        nutrients.forEach(nutrient => {
          if (savedResultsLookup[geometryId][`${nutrient}ContentClass`]) return

          const measurement = result[`${nutrient}Measurement`]

          result[`${nutrient}ContentClass`] = nutrient === 'ph'
            ? computeClassificationPh({measurement, soilSubTypeId})
            : fieldInfo[geometryId].length
              ? computeClassificationPKMg({
                nutrient,
                usage: fieldInfo[geometryId][0].usage,
                measurement,
                soilSubTypeId
              })
              : null
        })
      }

      const geometryIds = allResults
        ? state.order.geometries.map(x => x.id)
        : [selectedSamplingOrderGeometryId]

      const updatedSoilSubTypes = {}

      for (const geometryId of geometryIds) {
        calculateContentClasses(geometryId)

        updatedSoilSubTypes[geometryId] = results[geometryId].soilSubType
      }

      state.lastCalculationSoilSubTypeLookup = {
        ...state.lastCalculationSoilSubTypeLookup,
        ...updatedSoilSubTypes
      }
    },
    setIsOxide (state, {nutrient, value}) {
      state.upload.columnMapping[`${nutrient}IsOxide`] = value
    }
  },
  actions: {
    calculateClassifications ({getters, commit}, allResults = false) {
      commit('calculateClassifications', {savedResultsLookup: getters.savedResultsLookup, allResults})
    },
    getOpenOrders ({commit, rootGetters}, orderId = null) {
      const orgUnitId = rootGetters['fieldRecordSystem/orgUnitId']
      return smarterGet('/api/v2/entities/orgunits/{orgUnitId}/base-fertilization/orders/open', {
        id: 'bf.probingResult.orders',
        expiry: 60,
        inputs: {
          orgUnitId: () => orgUnitId
        },
        onResult (orders) {
          commit('setOpenOrders', orders)
          commit('setOrder', orderId ? orders.find(order => order.id === orderId) : null)
        }
      })
    },
    startManualEntry ({commit, dispatch}, orderId = null) {
      commit('fieldRecordSystem/setRightView', 'probingResultManualSettings', {root: true})

      return dispatch('getOpenOrders', orderId)
    },
    startImportWizard ({commit}, fileContents) {
      commit('setFileContents', fileContents)
      commit('fieldRecordSystem/setRightView', 'probingResultUpload', {root: true})
    },
    finalizeImportSettings ({rootState, state, dispatch, getters, commit}) {
      commit('generateResultsForOrder')

      const {columnMapping} = state.upload

      const parseMeasurement = (nutrient, measurement) => {
        if (!measurement) return null

        const value = state.upload.semicolonSeparated
          ? parseFloat(measurement.replace(/,/g, '.'))
          : parseFloat(measurement)

        const isOxide = columnMapping[`${nutrient}IsOxide`]
        return isOxide ? calculateFromOxidform(nutrient, value) : value
      }

      // NOTE ids not in order cannot be matched anyways and are irrelevant
      const csvIdsContainedInOrder = getters.validRows
        .filter(row => state.order.geometries.find(geometry => geometry.sampleId === row[columnMapping.sampleId]))
        .map(row => row[columnMapping.sampleId])

      const uniqueIdsInCsv = uniq(csvIdsContainedInOrder)

      const findPoint = row => {
        const sampleId = row[columnMapping.sampleId]
        const fieldName = row[columnMapping.fieldName]
        const sampleIdIsEnough = uniqueIdsInCsv.includes(sampleId)

        return state.order.geometries.find(geometry => {
          return geometry.sampleId === sampleId && (sampleIdIsEnough || geometry.fieldName === fieldName)
        })
      }

      const soilSubTypes = rootState.fieldRecordSystem.data.soilSubTypes

      const csvResults = getters.validRows.reduce((lookup, row) => {
        const geometry = findPoint(row)

        if (!geometry) return lookup

        const soilSubType = soilSubTypes.find(x => x.id === row[columnMapping.SoilSubType] || x.aliases.includes(row[columnMapping.SoilSubType]) || x.soilGroup === parse(row[columnMapping.SoilSubType], 'number'))

        return Object.assign(lookup, {
          [geometry.id]: {
            soilSubType: soilSubType ? soilSubType.id : null,
            fieldName: row[columnMapping.fieldName],
            limeRecommendation: state.manual.nutrients.includes('lime') ? parseMeasurement('ph', row[columnMapping['limeRecommendation']]) : null,
            phosphorusContentClass: state.manual.nutrients.includes('phosphorus') ? row[columnMapping.phosphorusClassification] || null : null,
            potassiumContentClass: state.manual.nutrients.includes('potassium') ? row[columnMapping.potassiumClassification] || null : null,
            magnesiumContentClass: state.manual.nutrients.includes('magnesium') ? row[columnMapping.magnesiumClassification] || null : null,
            phContentClass: state.manual.nutrients.includes('ph') ? row[columnMapping.phClassification] || null : null,
            phosphorusMeasurement: state.manual.nutrients.includes('phosphorus') ? parseMeasurement('phosphorus', row[columnMapping.phosphorusValue]) : null,
            potassiumMeasurement: state.manual.nutrients.includes('potassium') ? parseMeasurement('potassium', row[columnMapping.potassiumValue]) : null,
            magnesiumMeasurement: state.manual.nutrients.includes('magnesium') ? parseMeasurement('magnesium', row[columnMapping.magnesiumValue]) : null,
            phMeasurement: state.manual.nutrients.includes('ph') ? parseMeasurement('ph', row[columnMapping.phValue]) : null
          }
        })
      }, {})
      commit('assignResults', csvResults)

      if (state.ignoreClassification) {
        dispatch('calculateClassifications', true)
      }

      commit('fieldRecordSystem/setRightView', 'probingResultManualValues', {root: true})
    },
    finalizeManualSettings ({commit}) {
      commit('resetManualSettings')
      commit('generateResultsForOrder')
      commit('fieldRecordSystem/setRightView', 'probingResultManualValues', {root: true})
    },
    saveResults ({state, getters, commit}, forceComplete) {
      if (!getters.newResults.length) {
        console.error('[BF/resultImport] saving was attempted without results')
        return
      }

      const {id: orderId} = state.order

      const url = `/api/v2/base-fertilization/orders/${orderId}/results`
      const completeUrl = `/api/v2/base-fertilization/orders/${orderId}/is-completed`
      const data = {
        probingMethod: state.manual.probingMethod,
        results: getters.validNewResults
      }

      return axios.post(url, data)
      .then(response => response.data)
      .then(metrics => {
        commit('fieldRecordSystem/setRightView', 'basicFertilization', {root: true})
        if (forceComplete) {
          return axios.put(completeUrl).then(() => metrics)
        }
        return metrics
      })
    },
    createNutritionMap ({rootState, dispatch}, {fieldId, nutrients}) {
      const harvestYear = rootState.fieldRecordSystem.userSettings.harvestYear
      return axios.post(`/api/v2/base-fertilization/fields/${fieldId}/nutrient-maps/${harvestYear}`, nutrients)
      .then(() => dispatch('fieldRecordSystem/basicFertilization/loadStatistics', null, {root: true}))
    },
    createYoutrackIssue (store, formData) {
      return axios.post('/api/v2/base-fertilization/create-youtrack-issue', formData
        , {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
    }
  }
}
