import {makeSetters} from '@helpers/vuex/mutations'

const defaults = () => ({
  id: null,
  soil: {
    soilQuality: 0,
    soilType: null,
    soilSubType: null,
    usableFieldCapacity: 0
  }
})

const checkValidity = partialSoilDetail => {
  const defaultSoilDetail = defaults()
  for (const key in partialSoilDetail) {
    if (!(key in defaultSoilDetail)) {
      console.error(`[SoilDetail editBuffer] set: property '${key}' does not exist in SoilDetail defaults`)
    }
  }
}

export default {
  namespaced: true,
  state: defaults(),
  mutations: {
    ...makeSetters([
      'id',
      'soil'
    ]),
    setSoilQuality (state, value) {
      state.soil.soilQuality = value
    },
    setSoilType (state, value) {
      state.soil.soilType = value
    },
    setSoilSubType (state, value) {
      state.soil.soilSubType = value
    },
    setUsableFieldCapacity (state, value) {
      state.soil.usableFieldCapacity = value
    },
    set (state, partialSoilDetail) {
      checkValidity(partialSoilDetail)
      Object.assign(state, partialSoilDetail)
    },
    reset (state, {soilDetail}) {
      if (soilDetail) {
        checkValidity(soilDetail)
        Object.assign(state, soilDetail)
      } else {
        Object.assign(state, defaults())
      }
    }
  }
}
