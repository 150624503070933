import {fromPairs, orderBy} from 'lodash'

const plannedYieldDefaults = () => ({
  cropUsageId: null,
  cropHarvestQualityId: null,
  averageYield: null,
  unit: 'dt/ha',
  useByProduct: false,
  zoneYields: [],
  zoneWithdrawals: {}
})

export function initializeYieldPlanningData (state) {
  state.years = 1

  if (state.creationParameters.plannedYields.length > 0) return
  state.creationParameters.plannedYields = [plannedYieldDefaults()]
}

export function removePlannedYields (state) {
  const length = state.creationParameters.plannedYields.length
  for (let i = length; i > state.years; i--) {
    state.creationParameters.plannedYields.pop()
  }
}

export function addPlannedYields (state) {
  const length = state.creationParameters.plannedYields.length
  for (let i = length; i < state.years; i++) {
    state.creationParameters.plannedYields.push(plannedYieldDefaults())
  }
}

export function setPlannedYieldData (state, {year, plannedYield}) {
  state.creationParameters.plannedYields.splice(year, 1, plannedYield)
}

export function resetYieldPlanning (state) {
  state.years = null
  state.creationParameters.plannedYields = [plannedYieldDefaults()]
}

export function calculateYieldData (state, {zoneMap, cropHarvestQualities}) {
  const firstLetterBig = s => s[0].toUpperCase() + s.substring(1)

  if (!zoneMap) {
    zoneMap = {zones: [{id: null, number: 1}]}
  }

  const {heterogenityFactors} = state.creationParameters

  const completedPlannedYields = state.creationParameters.plannedYields.map(plannedYields => {
    const quality = cropHarvestQualities[plannedYields.cropHarvestQualityId]
    const nutrients = Object.keys(state.creationParameters.nutrients)
      .filter(key => state.creationParameters.nutrients[key].selected && key !== 'ph')

    const calculateAdjustedYield = zone => (heterogenityFactors[zone.id] || 1) * plannedYields.averageYield

    plannedYields.zoneYields = orderBy(zoneMap.zones, x => x.number).map(calculateAdjustedYield)

    plannedYields.zoneWithdrawals = fromPairs(nutrients.map(nutrient => {
      const mainProductFactor = quality[`mainproduct${firstLetterBig(nutrient)}Removal`]
      const byProductFactor = plannedYields.useByProduct
        ? quality[`byproduct${firstLetterBig(nutrient)}Removal`]
        : 0

      const withdrawalFactor = mainProductFactor + byProductFactor

      return [nutrient, plannedYields.zoneYields.map(x => x * withdrawalFactor)]
    }))
    return plannedYields
  })

  state.creationParameters.plannedYields = completedPlannedYields
}
