import {makeSetters} from '@helpers/vuex/mutations'

export default {
  ...makeSetters([
    'data.selectedMetaData',
    'data.metaDataAll',
    'data.measurements'
  ]),
  setMetaDataAll (state, data) {
    /*
      production: harvest...
      application: fertilization, seeding...
    */
    const applications = data.applicationMetaData.map(x => ({...x, type: 'application'}))
    const productions = data.productionMetaData.map(x => ({...x, type: 'production'}))
    const applicationMapData = data.applicationMapData.map(x => ({...x, type: 'applicationMap'}))
    state.data.metaDataAll = applications.concat(productions).concat(applicationMapData)
  },
  deleteTelemetry (state) {
    const index = state.data.metaDataAll.findIndex(x => x.id === state.data.selectedMetaData.id)
    state.data.metaDataAll.splice(index, 1)
    state.data.selectedMetaData = null
  }
}
