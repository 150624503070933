import moment from 'moment'
import Vue from 'vue'

export function setNutrientMaps (state, {fieldId, nutrientMaps}) {
  Vue.set(state.data.nutrientMaps, fieldId, nutrientMaps)
}

export function initializeOrder (state, probingSites) {
  state.data.order.name = ''
  state.data.order.created = moment().utc().toISOString()
  state.data.order.probingSites = probingSites
}

export function generateGeometry (state, {entityNameLookup}) {
  const probingSites = state.data.order.probingSites

  // const compare = ({coordinates: [lon1, lat1]}, {coordinates: [lon2, lat2]}) => {
  //   // return (0.75 * (lat2 - lat1) + 0.25 * (lon1 - lon2)) // weighted NS -> WE
  //   return (lat2 - lat1) || (lon1 - lon2) // NS -> WE
  // }
  const compare = ({geometry: wkt1}, {geometry: wkt2}) => wkt1.localeCompare(wkt2)
  const sortedProbingSites = probingSites.filter(x => x.isActive && state.orderCreation.fieldIds.includes(x.fieldId)).sort(compare)

  state.data.order.geometries = sortedProbingSites
    .map(({geometry, label, fieldId}, i) => ({
      fieldId,
      sampleId: state.ui.useCustomIds ? label : (i + 1).toString(),
      geometry: geometry,
      fieldName: entityNameLookup[fieldId],
      active: true
    }))
}

export function updateOrderName (state, name) {
  state.data.order.name = name
}

export function updateZoneMaps (state, {fieldId, zoneMaps}) {
  Vue.set(state.data.zoneMapIdsByFieldId, fieldId, zoneMaps.map(x => x.id))

  for (const zoneMap of zoneMaps) {
    if (!state.data.zoneMaps[zoneMap.id]) {
      Vue.set(state.data.zoneMaps, zoneMap.id, zoneMap)
    }
  }
}

export function updateZoneMapDetails (state, {zoneMapId, details}) {
  state.data.zoneMaps[zoneMapId] = {...state.data.zoneMaps[zoneMapId], ...details}
}

export function updateDefaultYields (state, {fieldId, defaultYields}) {
  Vue.set(state.applicationMaps.creation.defaultYieldsByFieldId, fieldId, defaultYields)
}
