import {mapValues, groupBy} from 'lodash'

import * as actions from './actions'
import * as mutations from './mutations'

function getUndeletedProbingSitesAsArray (probingSitesObject) {
  const filterDeleted = x => !probingSitesObject.deleted.map(x => x.id).includes(x.id)

  return probingSitesObject.existing.filter(filterDeleted).concat(probingSitesObject.new.filter(filterDeleted))
}

export default {
  namespaced: true,
  state: {
    // selecting which order to plan
    incompleteOrders: [],
    selectedOrderId: null,
    // overall order planning
    probingSitesByFieldId: {}, // {[fieldId]: probingSites}
    selectedFieldId: null,
    // current field
    showNumbers: false,
    selectedZoneMapId: null,
    probingSiteMode: 'probingPoints',
    probingSites: {
      existing: [],
      new: [],
      deleted: []
    }
  },
  getters: {
    selectedOrder (state) {
      return state.incompleteOrders.find(x => x.id === state.selectedOrderId) || null
    },
    safeProbingSitesByFieldId (state, getters) {
      const lookup = {}

      const fieldIds = getters.selectedOrder ? getters.selectedOrder.selectedFieldIds : []

      for (const fieldId of fieldIds) {
        const storedProbingSites = state.probingSitesByFieldId[fieldId] || {
          existing: [],
          new: [],
          deleted: []
        }

        // NOTE use edit buffer instead of potentially outdated swap storage for selected field
        lookup[fieldId] = getUndeletedProbingSitesAsArray(fieldId === state.selectedFieldId ? state.probingSites : storedProbingSites)
      }
      return lookup
    },
    activeProbingSitesByFieldId (state, getters) {
      return mapValues(getters.safeProbingSitesByFieldId, probingSites => probingSites.filter(x => x.isActive))
    },
    activeCountByFieldId (state, getters) {
      return mapValues(getters.activeProbingSitesByFieldId, probingSites => probingSites.length)
    },
    duplicateLabelsByFieldId (state, getters) {
      return mapValues(getters.activeProbingSitesByFieldId, probingSites => {
        const grouped = groupBy(probingSites, probingSite => probingSite.label)
        return Object.keys(grouped).filter(label => grouped[label].length > 1)
      })
    },
    allFieldsPlanned (state, getters) {
      return Object.values(getters.activeCountByFieldId).every(x => x > 0)
    },
    duplicateLabelsFound (state, getters) {
      return Object.values(getters.duplicateLabelsByFieldId).some(duplicates => duplicates.length)
    }
  },
  actions,
  mutations
}
