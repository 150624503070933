import {smarterGet, smarterPost} from '@helpers/vuex/data-loading'

export async function startApplicationMapPreview ({commit, rootGetters, dispatch}) {
  commit('fieldRecordSystem/setRightView', 'applicationMapPreview', {root: true})
  commit('resetUi')
  commit('resetFieldId')

  await dispatch('loadApplicationMapsPreview')
}

export async function loadApplicationMapsPreview ({commit, rootGetters}) {
  return smarterGet('/api/v2/base-fertilization/orgunits/{orgUnitId}/application-map-preview-infos/{harvestYear}', {
    id: 'orgUnit.bf.applicationMaps.preview',
    expiry: 60,
    inputs: {
      orgUnitId: () => rootGetters['fieldRecordSystem/orgUnitId'],
      harvestYear: () => rootGetters['fieldRecordSystem/harvestYear']
    },
    async onResult (maps) {
      commit('setApplicationMapsForPreview', maps)
    }
  })
}

export function adjustZoneValueManually ({state, getters, commit}, {value, zoneId}) {
  const rawValue = state.ui.isApplicationValue ? value * getters.productFactor / 100 : value
  commit('adjustZoneValueManually', {value: rawValue, zoneId})
}

export function loadApplicationMap ({state, commit, getters}, applicationMapId) {
  if (!applicationMapId) return
  const applicationMap = state.data.applicationMaps.find(map => map.id === applicationMapId)

  if (!applicationMap) return

  // geographicArea is required for FrsApplicationMapTotalSum
  // TODO refactor this
  applicationMap.zones.forEach(zone => {
    zone.geographicArea = zone.area
  })

  commit('setApplicationMap', applicationMap)
  commit('setManuallyAdjustedZoneValues', JSON.parse(JSON.stringify(getters.adjustedRawValues)))
}

export function setPreviousFertilization ({commit, getters}, value) {
  commit('setPreviousFertilization', value)
  commit('setManuallyAdjustedZoneValues', JSON.parse(JSON.stringify(getters.adjustedRawValues)))
}

export function deleteSelectedMap ({state, commit, dispatch}) {
  const applicationMap = state.ui.applicationMap
  if (!applicationMap) {
    throw Error('No map selected')
  }

  return smarterPost('/api/v2/base-fertilization/application-map-preview/{applicationMapId}/delete', null, {
    id: 'bf.applicationMap.preview.delete',
    inputs: {
      applicationMapId: () => applicationMap.id
    },
    onResult () {
      dispatch('removeSelectedMap')
    }
  })
}

export function saveApplicationMap ({state, dispatch, getters}) {
  const applicationMap = state.ui.applicationMap
  if (!applicationMap) {
    throw Error('No map selected')
  }

  const dto = {
    productId: state.ui.productId,
    previousFertilization: state.ui.previousFertilization ? state.ui.previousFertilization : 0,
    zones: applicationMap.zones.map(zone => ({
      ...zone,
      value: getters.adjustedProductValues[zone.id]
    }))
  }

  return smarterPost('/api/v2/base-fertilization/application-map-preview/{applicationMapId}/save', dto, {
    id: 'bf.applicationMap.preview.save',
    inputs: {
      applicationMapId: () => applicationMap.id
    },
    onResult () {
      dispatch('removeSelectedMap')
    }
  })
}

export function removeSelectedMap ({commit}) {
  commit('removeMapFromArray')
  commit('removeMapFromLookup')
  commit('resetUi')
}
