import parse from 'wellknown'
import {seperateMultiLineFeatureToLineFeatures} from 'src/js/helpers/openlayers/features'
import {makeFeature} from '@helpers/openlayers/features'

export const createPlanName = (names, tempName) => {
  let i = 0
  while (names.find(planName => i === 0 ? planName === tempName : planName === `${tempName}_${i}`)) {
    i++
  }
  return i === 0 ? tempName : `${tempName}_${i}`
}

export const extractFeatures = (source, mappingFunc) =>
  source.map(x => mappingFunc(x))
  .filter(x => x !== null)
  .reduce((acc, cur) => acc.concat(cur), [])
  .map(wkt => makeFeature({wkt}))

export const makePartialFieldFeatures = (selectedPartIndex, fieldParts) => fieldParts
  .map(({wkt, skip, candidateLanes}, i) => skip ? null : ({
    geometry: parse(wkt),
    type: 'Feature',
    properties: {
      id: i,
      i,
      selected: selectedPartIndex === i,
      finished: candidateLanes.length && i !== selectedPartIndex
    }
  })).filter(x => x)

export const makeSegmentFeatures = (segmentationResults, segmentLaneTypes) => segmentationResults
  .map((ring, i) => ring
    .map((segment, j) => ({
      geometry: parse(segment.lineString),
      type: 'Feature',
      properties: {
        id: `segment_${i}_${j}`,
        ringIndex: i,
        segmentIndex: j,
        globalSegmentIndex: 1 + i + j,
        lineType: segmentLaneTypes[i][j]
      }
    }))).reduce((accumulator, current) => accumulator.concat(current), [])

export const makeParts = (partEditBuffer, selectedPartIndex, fieldParts) => (partEditBuffer.wkt
  ? fieldParts.filter((part, i) => i !== selectedPartIndex).concat([partEditBuffer])
  : fieldParts).filter(x => !x.skip)

export const makeHeadlandFeatures = (selectedPartIndex, parts) => parts
  .map((part, i) => part.headlands
    .map((wkt, index) => ({
      geometry: parse(wkt),
      type: 'Feature',
      properties: {
        id: `headland_${index}`,
        selected: i === selectedPartIndex
      }
    }))).reduce((accumulator, current) => accumulator.concat(current), [])

export const makeHeadlandLaneFeatures = (selectedPartIndex, parts) => parts
  .map((part, i) => seperateMultiLineFeatureToLineFeatures({
    geometry: parse(part.generatedHeadlandLane),
    type: 'Feature',
    properties: {
      id: i,
      selected: i === selectedPartIndex
    }
  })).reduce((accumulator, current) => accumulator.concat(current), [])

export const makeLaneFeatures = (selectedPartIndex, parts) => parts
  .filter(part => part.candidateLanes.length)
  .reduce((features, part, i) => features.concat(seperateMultiLineFeatureToLineFeatures({
    geometry: parse(part.candidateLanes[part.selectedLane].lines),
    type: 'Feature',
    properties: {
      id: i,
      selected: i === selectedPartIndex,
      isGapLine: false /*! !(lineIndex % (gap + 1)) */
    }
  })), [])

export const makeSelectedCandidateLane = partEditBuffer => {
  if (partEditBuffer.candidateLanes.length) {
    const selectedCandidate = partEditBuffer.candidateLanes[partEditBuffer.selectedLane]
    const wkt = partEditBuffer.segmentationResult[selectedCandidate.ringIndex][selectedCandidate.segmentIndex].lineString

    return {
      geometry: parse(wkt),
      type: 'Feature',
      properties: {
        id: 'selectedCandidateLane',
        isSegment: true
      }
    }
  } else {
    return null
  }
}
