export default (type, compatPayload, fieldIdsByMapId) => {
  const {
    selectedConverter,
    selectedUnit,
    fileName,
    description,
    maps,
    includeFieldBorder,
    multipleFiles,
    multipleMapTypesWithMaizeSowing,
    harvestYear
  } = compatPayload

  // clone the lookup to avoid refs; add empty uid only for download
  const fieldIdsByMapIdClone = JSON.parse(JSON.stringify(fieldIdsByMapId))
  const geodata = maps.reduce((lookup, map) => {
    // maps without fields are downloaded with an empty uid.
    if (!(map.id in fieldIdsByMapIdClone)) {
      fieldIdsByMapIdClone[map.id] = ['00000000-0000-0000-0000-000000000000']
    }
    for (const fieldId of fieldIdsByMapIdClone[map.id]) {
      if (Object.values(lookup).find(x => Object.keys(x).includes(map.id))) continue
      if (!lookup[fieldId]) {
        lookup[fieldId] = {}
      }
      lookup[fieldId][map.id] = map.name
    }
    return lookup
  }, {})

  return {
    converter: selectedConverter,
    selectedUnitShortcut: selectedUnit,
    multipleMapTypesWithMaizeSowing,
    geodata,
    fileName,
    description,
    includeFieldBorder,
    multipleFiles,
    harvestYear
  }
}
