export function startApplicationMapCreation ({commit, dispatch}) {
  dispatch('masterData/reloadCropHarvestQualities', false, {root: true})

  commit('fieldRecordSystem/setRightView', 'applicationMapCreation', {root: true})
  dispatch('navigateToStart')
}

export function navigateToStart ({commit}) {
  commit('setStep', 'basicSettings')
  commit('startCreation')
}

export function startYieldPlanning ({commit}) {
  commit('initializeYieldPlanningData')
  commit('setStep', 'yieldPlanning')
}

export function adjustHeterogenity ({commit}) {
  commit('setStep', 'heterogenityAdjustment')
}
