import axios from 'axios'

import {makeSetters} from '@helpers/vuex/mutations'
import {simplifyHighQuality} from '@helpers/geojson/simplification'
import {filters, flattenEntityForest} from '@helpers/tree'
import {stringify} from 'wellknown'
import epsgCodes from 'src/js/data/epsg-codes'

import convertToTree from './convert-to-tree'

export default {
  namespaced: true,
  state: {
    geojson: null,
    columnMapping: {
      fieldName: null,
      orgName: null,
      subOrgName: null,
      colorProperty: null
    },
    selectedIds: [],
    showImportSimplificationHint: false
  },
  getters: {
    columns (state) {
      if (!state.geojson) return []

      const keys = new Set()

      state.geojson.features.filter(x => x.properties).forEach(feature => {
        Object.keys(feature.properties).forEach(key => {
          keys.add(key)
        })
      })

      return [...keys]
    },
    tree (state) {
      if (!state.geojson) return []

      return convertToTree(state.geojson, state.columnMapping)
    },
    selectedTree (state, getters) {
      const prune = nodes => nodes.filter(node => state.selectedIds.includes(node.id)).map(({children, ...node}) => {
        const clone = {
          ...node
        }
        if (children) {
          clone.children = prune(children)
        }
        return clone
      })

      return prune(getters.tree)
    },
    entityImportTree (state, getters) {
      const convertNode = ({type, name, feature, children}) => {
        const node = {
          content: {
            template: type === 'field' ? 'Parcel' : 'OrgUnit',
            name: name,
            wkt: null,
            children: null
          }
        }

        if (feature) {
          node.content.wkt = stringify(feature)
        }
        if (children) {
          node.children = children.map(convertNode)
        }
        return node
      }

      return getters.selectedTree.map(convertNode)
    },
    nodes (state, getters) {
      return flattenEntityForest(getters.tree)
    },
    fields (state, getters) {
      return getters.nodes.filter(filters.fields)
    },
    parents (state, getters) {
      const lookup = {}
      for (const node of getters.nodes) {
        if (node.children) {
          for (const child of node.children) {
            lookup[child.id] = node
          }
        }
      }
      return lookup
    },
    epsgCodes (state) {
      return epsgCodes.map(x => x.code).concat(state.customEpsgCodes)
    }
  },
  mutations: {
    ...makeSetters([
      'geojson',
      'colorProperty',
      'showImportSimplificationHint'
    ]),
    reset (state) {
      Object.keys(state.columnMapping).forEach(key => {
        state.columnMapping[key] = null
      })
      state.selectedIds = []
    },
    simplifyAndSetGeojson (state, geojson) {
      geojson.features.forEach(feature => {
        simplifyHighQuality(feature.geometry)
      })

      state.geojson = geojson
    },
    addCustomEpsgCode (state, code) {
      if (!state.customEpsgCodes.includes(code)) {
        state.customEpsgCodes.push(code)
      }
    },
    updateColumnMapping (state, {id, column}) {
      if (!(id in state.columnMapping)) {
        throw new Error('invalid column for column mapping')
      }
      state.columnMapping[id] = column
    },
    toggleNode (state, {node, parents}) {
      const selected = !state.selectedIds.includes(node.id)

      const selectedIds = new Set(state.selectedIds)

      const toggleSingleNode = node => {
        if (selected) {
          selectedIds.add(node.id)
        } else {
          selectedIds.delete(node.id)
        }
      }

      const toggle = node => {
        toggleSingleNode(node)
        if (node.children) {
          node.children.forEach(toggle)
        }
        // trace selection upwards
        if (selected) {
          let parent = parents[node.id]
          while (parent) {
            toggleSingleNode(parent)
            parent = parents[parent.id]
          }
        }
      }
      toggle(node)

      state.selectedIds = [...selectedIds]
    }
  },
  actions: {
    toggleNode ({commit, getters}, node) {
      commit('toggleNode', {node, parents: getters.parents})
    },
    startImportPreview ({commit}, geojson) {
      commit('reset')
      commit('simplifyAndSetGeojson', geojson)
      commit('setShowImportSimplificationHint', true)
      commit('fieldRecordSystem/setRightView', 'entityImportPreview', {root: true})
    },
    cancel ({commit}) {
      commit('fieldRecordSystem/setRightView', 'default', {root: true})
    },
    startImport ({rootState, getters, commit, dispatch, rootGetters}) {
      const orgUnitId = rootGetters['fieldRecordSystem/orgUnitId']
      const harvestYear = rootState.fieldRecordSystem.userSettings.harvestYear

      return axios.post(`/api/v2/entities/orgunits/${orgUnitId}/children/${harvestYear}`, getters.entityImportTree)
      .then(() => {
        commit('dataLoading/invalidate', 'orgUnit.geometries', {root: true})
        commit('dataLoading/invalidate', 'orgUnit.simplified-geometries', {root: true})
      })
      .then(() => dispatch('fieldRecordSystem/reloadEntities', null, {root: true}))
      .then(() => dispatch('fieldRecordSystem/loadFieldGeometriesForCurrentOrgUnit', null, {root: true}))
      .then(() => {
        commit('fieldRecordSystem/setRightView', 'default', {root: true})
      })
    }
  }
}
