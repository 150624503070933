import Vue from 'vue'
import {groupBy} from 'lodash'

import {makeSetters} from '@helpers/vuex/mutations'

const mutations = {
  ...makeSetters([
    'ui.selection.isFromDetailsView',
    'ui.selection.format',
    'ui.planning.step',
    'ui.features.partialFieldFeatures',
    'ui.features.segmentFeatures',
    'ui.features.headlandFeatures',
    'ui.features.headlandLaneFeatures',
    'ui.features.laneFeatures',
    'data.lanePlanNamesForField'
  ]),
  setIsCreateAbLineActive (state, active) {
    state.ui.isCreateAbLineActive = active
    state.ui.hideFooter = active
  },
  setLanePlansForField (state, lanePlans) {
    state.data.lanePlansField = {}
    lanePlans.forEach(lanePlan => {
      Vue.set(state.data.lanePlansField, lanePlan.id, lanePlan)
    })
  },
  setSelectedPlanId (state, id) {
    state.ui.selection.planId = id
  },
  setLanePlans (state, {fieldId, lanePlans}) {
    Vue.set(state.data.lanePlansOrgUnit, fieldId, lanePlans.length ? lanePlans : null)
  },
  setLanePlanId (state, planId) {
    let data = JSON.parse(JSON.stringify(state.data.selectedLanePlans))
    if (!data.includes(planId)) {
      data.push(planId)
    } else {
      data = data.filter(id => id !== planId)
    }
    state.data.selectedLanePlans = data
  },
  setSelectedLanePlan (state, plan) {
    state.data.selectedPlan = plan
  },
  setLanePlansOrgUnit (state, lanePlans) {
    state.data.lanePlansOrgUnit = groupBy(lanePlans, 'entityId')
    state.data.totalNumberOfPlans = lanePlans.length
  },
  addLanePlansOrgUnit (state, lanePlans) {
    const plans = groupBy(lanePlans, 'entityId')
    const lookup = {
      ...state.data.lanePlansOrgUnit
    }

    Object.keys(plans).forEach(key => {
      if (!lookup[key]) {
        lookup[key] = []
      }
      lookup[key].push(...plans[key])
    })

    state.data.lanePlansOrgUnit = lookup
    state.data.totalNumberOfPlans += lanePlans.length
  },
  resetLanePlansSelection (state) {
    state.data.selectedLanePlans = []
    state.data.selectedPlan = null
    state.data.lanePlansOrgUnit = {}
    state.data.totalNumberOfPlans = 0
    state.data.expandedNodes = []
    state.data.selectedNodes = []
  },
  toggleExpanded (state, id) {
    if (state.data.expandedNodes.includes(id)) {
      state.data.expandedNodes = state.data.expandedNodes.filter(x => x !== id)
    } else {
      const data = state.data.expandedNodes
      data.push(id)
      state.data.expandedNodes = data
    }
  },
  toggleSelected (state, id) {
    if (state.data.selectedNodes.includes(id)) {
      state.data.selectedNodes = state.data.selectedNodes.filter(x => x !== id)
    } else {
      const data = state.data.selectedNodes
      data.push(id)
      state.data.selectedNodes = data
    }
  },
  updateLanePlanName (state, {entityId, planId, name}) {
    state.data.lanePlansOrgUnit[entityId].find(plan => plan.id === planId).name = name
  }
}

export default mutations
