import axios from 'axios'

export const getAll = urls => Promise.all(urls.map(url => axios.get(url).then(response => response.data)))

export const getAndCommit = (url, mutation) => store => {
  return axios.get(url)
  .then(response => {
    store.commit(mutation, response.data)
    return store
  })
  .catch(error => {
    console.error(`error while trying to load data from [${url}] to put into mutation [${mutation}]`)
    console.error(error)
    return store
  })
}
