import axios from 'axios'
import {cloneDeep} from 'lodash'

function transformPlanningPartsToCombinationParts(fieldId, planningParts) {
  return cloneDeep(
    planningParts.map(
      ({id, type, procedure, parameters, productQuantities, actualArea}) => ({
        id,
        type,
        procedure,
        details: {
          [fieldId]: {
            actualArea,
            parameters,
            productQuantities,
          },
        },
      })
    )
  )
}

export default async function startEditingAction(
  {state, commit, dispatch, rootState},
  {actionId, navigationSource: {cancelTarget, saveTarget}}
) {
  commit('setNavigationSource', {cancelTarget, saveTarget})

  await dispatch('reloadActionData')

  commit('editBuffer/reset')
  commit('reset')
  commit('setWizardStep', 'start')

  const {location} = rootState.fieldRecordSystem.navigation

  if (actionId) {
    const {data: action} = await axios.get(`/api/v2/actions/${actionId}`)

    const {
      fieldId,
      geometry,
      planningParts,
      orgUnitId, // not used for editing
      auditInfo, // not used for editing
      mainCultivation, // not used for editing
      usesExperimentalFinanceIntegration, // not used for editing
      area, // not used for editing
      ...restOfAction
    } = action

    if (usesExperimentalFinanceIntegration) {
      throw new Error('cannot edit actions using lots right now')
    }

    // TODO find out why fieldId is even necessary
    await dispatch('editBuffer/toggleFieldId', fieldId)

    commit('editBuffer/setCustomGeometry', {fieldId, geometry})
    commit('editBuffer/set', {...restOfAction})

    const combinationParts = transformPlanningPartsToCombinationParts(
      fieldId,
      planningParts
    )
    commit('setCombinationParts', combinationParts)
    commit('setWizardStep', 'combinationStart')
  } else if (location.fieldId) {
    await dispatch('editBuffer/toggleFieldId', location.fieldId)
  }

  const editingViewMapping = {
    actionDetailsPage: 'actionPlanningForSingleFieldFromList',
    actionOverview: 'actionPlanningFromOverview',
  }

  const editingView =
    editingViewMapping[rootState.fieldRecordSystem.ui.view.right] ||
    (location.fieldId
      ? 'actionPlanningForSingleField'
      : 'actionPlanningFromDashboard')

  commit('fieldRecordSystem/setRightView', editingView, {root: true})
}
