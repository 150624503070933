export * from './view-data-loading'
export * from './master-data-loading'
export * from './navigation'
export * from './navigation-actions'
export * from './crop-rotations'
export * from './entity'
export * from './field-geometries'
export * from './user-data'
export * from './weather'

export const displayCultivationsForField = ({commit}) => {
  commit('setRightView', 'cultivationsDisplayField')
}

export const displayActionsForField = ({commit}) => {
  commit('setRightView', 'actionDisplayField')
}
