import axios from 'axios'
import {smarterGet} from '@helpers/vuex/data-loading'

export default {
  showTelemetryOverview ({commit, rootGetters, rootState}) {
    const orgUnitId = rootGetters['fieldRecordSystem/orgUnitId']
    const fieldId = rootGetters['fieldRecordSystem/fieldId']
    const {harvestYear} = rootState.fieldRecordSystem.userSettings

    return smarterGet('/api/v2/telemetry/orgunits/{orgUnitId}/{fieldId}/metadata/all/{harvestYear}', {
      id: 'telemetry.metadata',
      expiry: 0,
      inputs: {
        orgUnitId: () => orgUnitId,
        fieldId: () => fieldId,
        harvestYear: () => harvestYear
      },
      onResult (metaData) {
        commit('setMetaDataAll', metaData)
        commit('fieldRecordSystem/setRightView', 'telemetry', {root: true})
      }
    })
  },
  deleteTelemetry ({state, commit, rootGetters}) {
    const orgUnitId = rootGetters['fieldRecordSystem/orgUnitId']
    const id = state.data.selectedMetaData.id
    axios.delete(`/api/v2/telemetry/orgunits/${orgUnitId}/${state.data.selectedMetaData.type}/${id}`)
    .then(() => {
      commit('deleteTelemetry')
    })
  },
  getMeasurements ({state, commit, rootGetters}) {
    const isApplication = state.data.selectedMetaData.type === 'application'
    const id = state.data.selectedMetaData.id
    const orgUnitId = rootGetters['fieldRecordSystem/orgUnitId']

    const url = isApplication ? '/api/v2/telemetry/orgunits/{orgUnitId}/application/{id}/measurements' : '/api/v2/telemetry/orgunits/{orgUnitId}/production/{id}/measurements'
    return smarterGet(url, {
      id: 'telemetry.measurements',
      expiry: 0,
      inputs: {
        orgUnitId: () => orgUnitId,
        id: () => id
      },
      onResult (measurements) {
        commit('setMeasurements', measurements)
      }
    })
  }
}
