import {makeSetters} from '@helpers/vuex/mutations'

import * as actions from './actions'
import * as mutations from './mutations'
import editBuffer from './editBuffer'

const state = {
  ui: {
    selection: {
      field: null
    },
    editing: false,
    saving: false,
    selectedClimateCategory: 'temperature'
  },
  data: {
    fields: [], // usually for current orgunit
    climateCategories: {
      evapotranspiration: {
        unit: 'mm/m²'
      },
      climaticWaterBalance: {
        unit: 'mm/m²'
      },
      heatSummation: {
        unit: '°C'
      },
      temperature: {
        unit: '°C'
      },
      precipitation: {
        unit: 'mm/m²'
      },
      wind: {
        unit: 'm/s'
      },
      cloudCover: {
        unit: '%'
      }
    }
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations: {
    ...mutations,
    ...makeSetters([
      'ui.selectedClimateCategory',
      'ui.saving'
    ])
  },
  modules: {
    editBuffer
  }
}
