import {smarterPost} from '@helpers/vuex/data-loading'
import {makeSetters} from '@helpers/vuex/mutations'

import * as getters from './getters'

export default {
  namespaced: true,
  state: {
    openOrders: [],
    selectedOrder: null,
    optionalSampleIdKey: null,
    optionalFieldNameKey: null
  },
  mutations: {
    ...makeSetters([
      'openOrders',
      'selectedOrder',
      'optionalSampleIdKey',
      'optionalFieldNameKey'
    ])
  },
  actions: {
    downloadAsShape ({state}) {
      return smarterPost(`/api/v2/base-fertilization/export-order/${state.selectedOrder.id}`, null, {
        id: 'bf.order.export'
      })
    }
  },
  getters
}
