export default [
  {
    id: 'lufa-nord-west',
    name: 'LUFA Nord-West',
    columnMapping: {
      sampleId: 'Schlag-Nr.',
      fieldName: 'Schlagbezeichnung',
      phosphorusValue: 'phosphor (P), mg/100g',
      phosphorusClassification: 'Gehaltsklasse phosphor',
      phosphorusIsOxide: false,
      magnesiumValue: 'Magnesium (Mg), mg/100g',
      magnesiumClassification: 'Gehaltsklasse Magnesium',
      magnesiumIsOxide: false,
      potassiumValue: 'Kalium (K), mg/100g',
      potassiumClassification: 'Gehaltsklasse Kalium',
      potassiumIsOxide: false,
      phValue: 'ph-Wert',
      phClassification: 'Gehaltsklasse Kalk/ph',
      limeRecommendation: 'Kalkbedarf (CaO)',
      SoilSubType: 'Bodenart: K�rnung'
      // SoilSubType: 'Bodenart:  Körnung'
    }
  },
  {
    id: 'lufa-nrw-1',
    name: 'LUFA NRW 1',
    columnMapping: {
      sampleId: 'T¸ten-Nr:',
      fieldName: 'Schlagbezeichnung',
      phosphorusValue: 'P2O5',
      phosphorusClassification: 'Versorgung. P2O5',
      phosphorusIsOxide: true,
      magnesiumValue: 'Mg',
      magnesiumClassification: 'Versorgung. Mg',
      magnesiumIsOxide: false,
      potassiumValue: 'K2O',
      potassiumClassification: 'Versorgung. K2O',
      potassiumIsOxide: true,
      phValue: 'ph',
      phClassification: 'Versorgung. ph',
      limeRecommendation: null,
      SoilSubType: 'Bodenart'
    }
  },
  {
    id: 'lufa-nrw-2',
    name: 'LUFA NRW 2',
    columnMapping: {
      sampleId: 'T¸ten-Nr:',
      fieldName: 'Schlagbezeichnung',
      phosphorusValue: 'phosphor (P2O5) in CAL (mg/100g)',
      phosphorusClassification: null,
      phosphorusIsOxide: true,
      magnesiumValue: 'Magnesium (Mg) in CaCl2 (mg/100g)',
      magnesiumClassification: null,
      magnesiumIsOxide: false,
      potassiumValue: 'Kalium (K2O) in CAL (mg/100g)',
      potassiumClassification: null,
      potassiumIsOxide: true,
      phValue: 'ph-Wert in CaCl2',
      phClassification: null,
      limeRecommendation: null,
      SoilSubType: 'Bodenart'
    }
  },
  {
    id: 'bolap',
    name: 'Bolap',
    columnMapping: {
      sampleId: 'BP_NR',
      fieldName: 'SCHLAG',
      phosphorusValue: 'P',
      phosphorusClassification: 'PSTUFE',
      phosphorusIsOxide: true,
      magnesiumValue: 'MG',
      magnesiumClassification: 'MGSTUFE',
      magnesiumIsOxide: false,
      potassiumValue: 'K',
      potassiumClassification: 'KSTUFE',
      potassiumIsOxide: true,
      phValue: 'ph',
      phClassification: 'phSTUFE',
      limeRecommendation: null,
      SoilSubType: 'BODENART'
    }
  },
  {
    id: 'agrolab',
    name: 'Agrolab',
    columnMapping: {
      sampleId: 'BP_NR',
      fieldName: 'SCHLAGBEZ_',
      phosphorusValue: 'P_MG_100G',
      phosphorusClassification: 'P_VS',
      phosphorusIsOxide: false,
      magnesiumValue: 'MG_MG_100G',
      magnesiumClassification: 'MG_VS',
      magnesiumIsOxide: false,
      potassiumValue: 'K_MG_100G',
      potassiumClassification: 'K_VS',
      potassiumIsOxide: false,
      phValue: 'ph',
      phClassification: 'ph_VS',
      limeRecommendation: null,
      SoilSubType: 'Bodenart'
    }
  }
]
