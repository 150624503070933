import {mapValues, groupBy, flatten, pickBy} from 'lodash'
import {union, difference, intersect, buffer, helpers} from '@turf/turf'
import {computeSize} from '@helpers/openlayers/computation'

// grouped by fieldId
export function fieldCultivationFeaturePairs (state, getters, rootState) {
  const fieldData = rootState.fieldRecordSystem.data.field

  return mapValues(getters.relevantCultivationsByFieldId, (cultivations, fieldId) => {
    if (!fieldData[fieldId].geometry) {
      return []
    }

    const fieldFeature = helpers.feature(fieldData[fieldId].geometry, {fieldId})

    return cultivations
      .map(cultivation => ({
        fieldFeature,
        cultivationFeature: helpers.feature(cultivation.geojsonGeometry, {
          cultivation
        })
      }))
  })
}
// grouped by fieldId
export function clippedCultivationFeatures (state, getters) {
  return mapValues(getters.fieldCultivationFeaturePairs, (featurePairs, fieldId) => {
    return featurePairs
      .reduce((features, {fieldFeature, cultivationFeature}) => {
        try {
          const feature = intersect(cultivationFeature, fieldFeature)
          if (feature) {
            feature.properties = {
              ...cultivationFeature.properties,
              fieldId
            }
            features.push(feature)
          }
        } catch {
          console.error({cultivationFeature, fieldFeature})
        }

        return features
      }, [])
  })
}
// grouped by cultivationId
export function cutOffCultivationFeatures (state, getters, rootState) {
  const flatFeaturePairs = flatten(Object.values(getters.fieldCultivationFeaturePairs))

  const groupedPairs = groupBy(flatFeaturePairs, pair => pair.cultivationFeature.properties.cultivation.id)

  const lookup = mapValues(groupedPairs, (features, cultivationId) => {
    const cultivationFeature = groupedPairs[cultivationId][0].cultivationFeature
    const fieldFeatures = groupedPairs[cultivationId].map(pair => pair.fieldFeature)

    return fieldFeatures.reduce((feature, fieldFeature) => feature ? difference(feature, fieldFeature) : null, cultivationFeature)
  })

  return pickBy(lookup, feature => feature)
}
// grouped by fieldId
export function combinedCultivationFeatures (state, getters, rootState, rootGetters) {
  return mapValues(getters.clippedCultivationFeatures, features => {
    if (!features.length) return null
    if (features.length === 1) return features[0]

    return features.reduce((merged, feature) => union(merged, feature))
  })
}
// grouped by fieldId
export function remainingFieldFeatures (state, getters, rootState) {
  const fieldData = rootState.fieldRecordSystem.data.field
  return mapValues(getters.combinedCultivationFeatures, (combinedCultivationGeometry, fieldId) => {
    const fieldGeometry = fieldData[fieldId].geometry

    if (!fieldGeometry) return null

    if (!combinedCultivationGeometry) return helpers.feature(fieldGeometry)

    const feature = difference(helpers.feature(fieldGeometry), combinedCultivationGeometry)

    if (!feature) return null

    const bufferedFeature = buffer(feature, -0.00005) // NOTE hacky fix around degeneracies with shared edges
    return bufferedFeature === undefined ? null : bufferedFeature
  })
}
// grouped by fieldId
export function finalActionFeatures (state, getters, rootState, rootGetters) {
  const fieldData = rootState.fieldRecordSystem.data.field

  return mapValues(state.customGeometries, (customGeometry, fieldId) => {
    if (customGeometry) return helpers.feature(customGeometry)

    const availableCultivations = getters.relevantCultivationsByFieldId[fieldId]
    const selectedCultivations = getters.selectedCultivationIdsByFieldId[fieldId]

    const allCultivationsSelected = availableCultivations.length === selectedCultivations.length
    const remainingFieldSelected = state.remainingFieldSelected[fieldId]

    // just use field geometry if everything is selected, to save effort and prevent artifacts when merging all geometries again
    if (allCultivationsSelected && remainingFieldSelected) {
      return helpers.feature(fieldData[fieldId].geometry)
    }

    const features = []

    const cultivationFeatures = getters.clippedCultivationFeatures[fieldId].filter(x => {
      return state.selectedCultivationIds[x.properties.cultivation.id] === fieldId
    })
    features.push(...cultivationFeatures)

    if (remainingFieldSelected) {
      features.push(getters.remainingFieldFeatures[fieldId])
    }

    if (!features.length) return null
    if (features.length === 1) return features[0]

    const filteredFeatures = features.filter(feature => feature)

    return filteredFeatures.reduce((merged, feature) => union(merged, feature))
  })
}

export function finalActionTotalArea (state, getters) {
  if (!Object.values(getters.finalActionFeatures).every(x => x)) return 0
  const areaFeatures = Object.values(getters.finalActionFeatures)

  return areaFeatures.reduce((sum, next) => sum + computeSize(next.geometry), 0)
}
