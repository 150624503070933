import * as actions from './actions'
import * as mutations from './mutations'
import editBuffer from './editBuffer'

const state = {
  ui: {
    selection: {
      person: null
    },
    editing: false
  },
  data: {
    persons: [] // usually for current orgunit
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  modules: {
    editBuffer
  }
}
