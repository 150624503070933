import store from '@store'
import {orderBy} from 'lodash'

import {format} from 'src/js/i18n/conversion'

export const toCultivationName = (cultivation) => {
  if (!cultivation) {
    return ''
  }

  const frsTranslations = store.state.i18n.translations['Areas.FieldRecordSystem.SR_FieldRecordSystem']

  switch (cultivation.type) {
  case 'main':
  case 'secondary': {
    const name = store.state.i18n.translations['masterData.crops.usage'][`${cultivation.cropUsageId}_name`]

    return frsTranslations[`CultivationType_${cultivation.type}`]
      ? typeof name === 'string'
        ? `${frsTranslations[`CultivationType_${cultivation.type}`]} - ${name}`
        : frsTranslations[`CultivationType_${cultivation.type}`].toString()
      : null
  }
  case 'catch': {
    const frsTranslations = store.state.i18n.translations['Areas.FieldRecordSystem.SR_FieldRecordSystem']
    return cultivation.mixtureComponents ? toMixtureName(cultivation.mixtureComponents) : frsTranslations.CatchCrop
  }
  case 'eco':
    return frsTranslations.EcologicalPriorityAreas
  case 'dual':
    return frsTranslations.TwoCropUsage ? frsTranslations.TwoCropUsage.concat(` - ${'a'} + ${'b'}`) : null
  default:
    throw new Error(frsTranslations.InvalidCultivationType.concat(`: ${cultivation.type}`))
  }
}

export const toMixtureName = (components) => {
  const sorted = orderBy(components, ['percentage', 'cropUsageId'], ['desc', 'asc'])
  const componentsShort = sorted.map((component) => {
    const name = store.state.i18n.translations['masterData.crops.usage'][`${component.cropUsageId}_name`]
    return `${name} (${format(component.percentage, 'percent')})`
  })
  return componentsShort.join(', ')
}

export const isEcoCompatible = (components) => {
  return Math.max(...components.map(x => x.percentage)) < 0.6
}
