import {makeSetters} from '@helpers/vuex/mutations'

export default {
  namespaced: true,
  state: {
    selectedFieldId: null,
    selectedNutrient: null,
    showClassifications: false,
    showZentroid: false
  },
  mutations: {
    ...makeSetters([
      'selectedFieldId',
      'selectedNutrient',
      'showClassifications',
      'showZentroid'
    ])
  },
  actions: {
    startNutrientMapDisplay ({commit}) {
      commit('setSelectedFieldId', null)
      commit('fieldRecordSystem/setRightView', 'nutrientMapDisplay', {root: true})
    },
    loadNutrientMaps ({commit, dispatch, rootState}, fieldId) {
      return dispatch('fieldRecordSystem/mapManagement/loadNutrientMaps', fieldId, {root: true})
      .then(() => {
        const nutrientMapIds = rootState.fieldRecordSystem.mapManagement.data.nutrientMap.mapIdsByFieldId[fieldId]
        nutrientMapIds.forEach(mapId => {
          dispatch('fieldRecordSystem/mapManagement/loadNutrientMapDetails', mapId, {root: true})
        })
      })
    }
  }
}
