// import {makeSetters} from '@helpers/vuex/mutations'

import {makeSetters} from '@helpers/vuex/mutations'
import newGuid from '@helpers/new-guid'

const fertilizationDefaults = () => ({
  id: null,
  minValue: null,
  maxValue: null,
  selectedYieldMapId: null,
  actualYield: null,
  number: null,
  name: ''
})

const defaults = () => ({
  data: {
    applicationMetaData: [],
    yieldMetaData: []
  },
  creation: {
    yieldData: [],
    applicationData: [],
    numberZones: 5,
    numberYears: 3,
    maxApplicationValue: null,
    mapName: null,
    productId: null
  },
  applicationDetails: {
    selectedMapId: null,
    id: null,
    minValue: null,
    maxValue: null,
    withdrawalFactor: null,
    number: null,
    name: ''
  },
  yieldDetails: {
    id: null,
    minValue: null,
    maxValue: null,
    selectedYieldMapId: null,
    actualYield: null,
    pFactor: null,
    number: null,
    name: ''
  }
})

export default {
  ...makeSetters([
    'creation.numberZones',
    'creation.numberYears',
    'creation.maxValue',
    'creation.maxApplicationValue',
    'creation.mapName',
    'creation.productId',
    'applicationDetails.id',
    'applicationDetails.withdrawalFactor',
    'applicationDetails.selectedMapId',
    'yieldDetails.minValue',
    'yieldDetails.maxValue',
    'yieldDetails.selectedYieldMapId',
    'yieldDetails.actualYield',
    'yieldDetails.pFactor',
    'yieldDetails.number'
  ]),
  setApplicationMinValue (state, value) {
    state.applicationDetails.minValue = value
  },
  setApplicationMaxValue (state, value) {
    state.applicationDetails.maxValue = value
  },
  setApplicationDetails (state) {
    let applicationDetails = {

      minValue: state.applicationDetails.minValue,
      maxValue: state.applicationDetails.maxValue,
      withdrawalFactor: state.applicationDetails.withdrawalFactor,
      name: state.applicationDetails.name,
      number: state.applicationDetails.number,
      selectedMapId: state.applicationDetails.selectedMapId
    }
    if (state.applicationDetails.id) {
      applicationDetails.id = state.applicationDetails.id

      const index = state.creation.applicationData.indexOf(x => x.id === state.applicationDetails.id)
      state.creation.applicationData.splice(index, 1, {...applicationDetails})
    } else {
      applicationDetails.id = newGuid()
      state.creation.applicationData.push(applicationDetails)
    }
  },
  setApplicationName (state, value) {
    state.applicationDetails.name = value
  },
  setApplicationNumber (state, value) {
    state.applicationDetails.number = value
  },
  reset (state) {
    Object.assign(state, defaults())
  },
  resetApplicationData (state) {
    state.applicationDetails.selectedMapId = null
    state.applicationDetails.id = null
    state.applicationDetails.minValue = null
    state.applicationDetails.maxValue = null
    state.applicationDetails.withdrawalFactor = null
    state.applicationDetails.number = null
    state.applicationDetails.name = null
  },
  setApplicationData (state, data) {
    state.applicationDetails.id = data.id
    state.applicationDetails.minValue = data.minValue
    state.applicationDetails.maxValue = data.maxValue
    state.applicationDetails.withdrawalFactor = data.withdrawalFactor
    state.applicationDetails.number = data.number
    state.applicationDetails.name = data.name
    state.applicationDetails.selectedMapId = data.selectedMapId
  },
  setApplicationMetaData (state, data) {
    const applications = data.applicationMetaData.map(x => ({...x, type: 'application'}))
    const productions = data.productionMetaData.map(x => ({...x, type: 'production'}))
    const applicationMapData = data.applicationMapData.map(x => ({...x, type: 'applicationMap'}))
    state.data.applicationMetaData = applications.concat(applicationMapData)
    state.data.yieldMetaData = productions
  },
  setYieldName (state, value) {
    state.yieldDetails.name = value
  },
  resetYieldDetails (state, yieldData = fertilizationDefaults()) {
    Object.assign(state.yieldDetails, yieldData)
  },
  updateYieldDataList (state) {
    if (!state.yieldDetails.id) {
      state.yieldDetails.id = newGuid()
      state.creation.yieldData.push({...state.yieldDetails})
    } else {
      const index = state.creation.yieldData.indexOf(x => x.id === state.yieldDetails.id)
      state.creation.yieldData.splice(index, 1, {...state.yieldDetails})
    }
  },
  removeYieldCard (state, fertilizationDataId) {
    state.creation.yieldData = state.creation.yieldData.filter(x => x.id !== fertilizationDataId)
  },
  removeProductionCard (state, applicationDataId) {
    state.creation.applicationData = state.creation.applicationData.filter(x => x.id !== applicationDataId)
  }
}
